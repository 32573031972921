import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "the-database"
    }}>{`The Database`}</h1>
    <p>{`At the core of Mirage's data layer is a simple in-memory database. This database stores all of Mirage's initial state, and then your route handlers access and modify that state as you use your application.`}</p>
    <p>{`The database is what allows Mirage to mimic a production server, giving you the ability to write complete dynamic features in your app.`}</p>
    <p>{`Most of your Mirage code will not access the database directly, but will interact with it through Mirage's ORM instead. We'll cover the ORM in the next section of these guides.`}</p>
    <p>{`For now, let's learn the basics of the database so you feel confident interacting with it directly, even if most of your code ends up using the ORM.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`Mirage's database is really just a JavaScript object with some conventions around it. It's accessible off your `}<inlineCode parentName="p">{`server`}</inlineCode>{` instance`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let server = createServer()

server.db // {} the db is empty
`}</code></pre>
    <p>{`You can call `}<inlineCode parentName="p">{`loadData`}</inlineCode>{` on it to seed it with some data:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.db.loadData({
  movies: [
    { title: "Interstellar" },
    { title: "Inception" },
    { title: "Dunkirk" },
  ],
})
`}</code></pre>
    <p><inlineCode parentName="p">{`loadData`}</inlineCode>{` takes a object whose keys correspond to database tables, and whose values represent an array of database records.`}</p>
    <p>{`The database automatically assigns IDs to new records (you can also provide your own). We can access our data using a MongoDB-inspired API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Get all movies
server.db.movies // [ { id: '1', title: 'Interstellar' }, { id: '2', title: 'Inception' }, { id: '3', title: 'Dunkirk' } ]

// Get the first movie
server.db.movies[0] // { id: '1', title: 'Interstellar' }

// Insert a new movie
server.db.movies.insert({ title: "The Dark Knight" })
`}</code></pre>
    <p>{`Mirage has a `}<inlineCode parentName="p">{`seeds`}</inlineCode>{` method which is a conventional place to put some initial data into your server during development. You can call `}<inlineCode parentName="p">{`loadData`}</inlineCode>{` on the database there:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  seeds(server) {
    server.db.loadData({
      movies: [
        { title: "Interstellar" },
        { title: "Inception" },
        { title: "Dunkirk" },
      ],
    })
  },
})
`}</code></pre>
    <p>{`The real power comes from accessing the database in your route handlers. You can get it using the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", (schema, request) => {
  return schema.db.movies
})
`}</code></pre>
    <p>{`This route handler now responds with all the data in Mirage's database at the time of the request. That means if you start out with this data;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  { "id": "1", "title": "Interstellar" },
  { "id": "2", "title": "Inception" },
  { "id": "3", "title": "Dunkirk" }
]
`}</code></pre>
    <p>{`but then write a new route handler that inserts data into the `}<inlineCode parentName="p">{`movies`}</inlineCode>{` collection`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/movies", (schema, request) => {
  let attrs = JSON.parse(request.requestBody)

  return schema.db.movies.insert(attrs)
})
`}</code></pre>
    <p>{`and use your app to create a new movie, the second time your app makes a GET request to `}<inlineCode parentName="p">{`/movies`}</inlineCode>{` it would respond with the new database data:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  { "id": "1", "title": "Interstellar" },
  { "id": "2", "title": "Inception" },
  { "id": "3", "title": "Dunkirk" },
  { "id": "4", "title": "The Dark Knight" }
]
`}</code></pre>
    <p>{`As you'll learn in the next section, most of your route handlers will interact with the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` ORM object instead of the lower-level database object, but it's still good to know that the database is there if you need it.`}</p>
    <p>{`The most common place you'll use the database directly is in your tests, where you can access it via `}<inlineCode parentName="p">{`server.db`}</inlineCode>{`. It can be useful to assert against the state of Mirage's database to verify that your app's network requests are sending over the correct data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`test("I can create a movie", async function (assert) {
  await visit("/movies/new")
  await fillIn(".title", "The Dark Knight")
  await click(".submit")

  assert.dom("h2").includesText("New movie saved!")
  assert.equal(server.db.movies[0].title, "The Dark Knight")
})
`}</code></pre>
    <p>{`You can view the rest of the Database APIs in the API reference.`}</p>
    <hr></hr>
    <p>{`Next, let's learn about Mirage's ORM.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      