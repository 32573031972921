import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "serializers"
    }}>{`Serializers`}</h1>
    <p>{`A `}<em parentName="p">{`serializer`}</em>{` is an object responsible for transforming a Model or Collection that's returned from your route handlers into a JSON payload that's formatted the way your frontend app expects.`}</p>
    <p>{`For example, this route handler returns a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("movies/:id", (schema, request) => {
  return schema.movies.find(request.params.id)
})
`}</code></pre>
    <p>{`A Serializer turns that JavaScript object into this JSON payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`// GET /movies/1

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    }
  }
}
`}</code></pre>
    <p>{`Serializers are the last main part of Mirage's architecture that interacts with the data layer, because producing a well-formatted JSON response often involves traversing the relationship graph of your models.`}</p>
    <p>{`Let's see how they work.`}</p>
    <h2 {...{
      "id": "choosing-which-serializer-to-use"
    }}>{`Choosing which serializer to use`}</h2>
    <p>{`The first step in working with Mirage's serializers is to choose which included serializer to start with, which in turn depends on what JSON format your backend uses to serve data to your JavaScript app.`}</p>
    <p>{`The JSON payload above is an example of an API that follows `}<a parentName="p" {...{
        "href": "https://jsonapi.org/"
      }}>{`the JSON:API spec`}</a>{`. It has a very specific structure that differentiates attributes from relationships, supports named and polymorphic relationships, links, query param includes and more. It also solves a lot of problems that exist in other formats that are less rigorously defined.`}</p>
    <p>{`If your existing backend API does use JSON:API, Mirage ships with a `}<inlineCode parentName="p">{`JSONAPISerializer`}</inlineCode>{` that will do the heavy lifting for you. And if you're starting a new app, consider using JSON:API, since it solves many problems you'll run into while building an API and can help your team avoid bike shedding.`}</p>
    <p>{`Of course, there are plenty of JavaScript apps that don't use JSON:API as their API serialization format.`}</p>
    <p>{`Mirage ships with two other named serializers that match popular backend formats. `}<inlineCode parentName="p">{`ActiveModelSerializer`}</inlineCode>{` is intended to mimic APIs that resemble Rails APIs built with the `}<inlineCode parentName="p">{`active_model_serializer`}</inlineCode>{` gem, and `}<inlineCode parentName="p">{`RestSerializer`}</inlineCode>{` is a good starting point for many other common APIs.`}</p>
    <p>{`Depending on your own backend API's format, you'll need to choose the closest serializer as a starting point and customize it to match your production format. We'll talk more about that later in this guide.`}</p>
    <h2 {...{
      "id": "defining-serializers"
    }}>{`Defining serializers`}</h2>
    <p>{`Once you've selected the appropriate serializer to start with, define it as your default application-wide serializer like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, RestSerializer } from "miragejs"

createServer({
  serializers: {
    application: RestSerializer,
  },
})
`}</code></pre>
    <p>{`The application serializer is the default one used for every Model and Collection in your system.`}</p>
    <p>{`If you need to customize a serializer for a particular model type, you can define model-specific serializers that take precedence over your application serializer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, RestSerializer } from "miragejs"

createServer({
  serializers: {
    application: RestSerializer,
    movie: RestSerializer.extend({
      include: ["castMembers"],
    }),
  },
})
`}</code></pre>
    <p>{`You'll see examples of the kinds of model-specific customizations you might want later in the guide.`}</p>
    <p>{`Typically you might have application-wide customizations that you'd want to make, in addition to model-specific customizations. Because of this, the best practice is to use model-specific serializers that extend from your application serializer. You might achieve that like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, RestSerializer } from "miragejs"

let ApplicationSerializer = RestSerializer.extend({
  root: false,
})

createServer({
  serializers: {
    application: ApplicationSerializer,
    movie: ApplicationSerializer.extend({
      include: ["castMembers"],
    }),
  },
})
`}</code></pre>
    <p>{`Now the application-wide customizations will be maintained as you create model-specific serializers.`}</p>
    <h2 {...{
      "id": "customizing-serializers"
    }}>{`Customizing serializers`}</h2>
    <p>{`When it comes to customizing your application's serializers, you'll mostly be tweaking Mirage's defaults.`}</p>
    <p>{`For example, if your app expects attribute names to be PascalCase`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// GET /movies/1

{
  Id: '1',
  ReleaseDate: 'Interstellar'
}
`}</code></pre>
    <p>{`you might override the Serializer's `}<inlineCode parentName="p">{`keyForAttribute`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { RestSerializer } from "miragejs"
import { camelCase, upperFirst } from "lodash"

let ApplicationSerializer = RestSerializer.extend({
  keyForAttribute(attr) {
    return upperFirst(camelCase(attr))
  },
})
`}</code></pre>
    <p>{`See the API docs for each serializer to learn more about all the customization hooks available.`}</p>
    <h2 {...{
      "id": "relationships"
    }}>{`Relationships`}</h2>
    <p>{`Relationships are another important aspect of Serializers, since backends have many different ways of dealing with relationships.`}</p>
    <p>{`For example, out of the box the `}<inlineCode parentName="p">{`JSONAPISerializer`}</inlineCode>{` will only include relationship information if its requested via query param includes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`/* GET /movies/1?include=cast-members */

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    },
    "relationships": {
      "cast-members": {
        "data": [
          { "type": "people", "id": "1" },
          { "type": "people", "id": "2" },
          { "type": "people", "id": "3" }
        ]
      }
    }
  },
  "included": [
    { "id": "1", "type": "people", "attributes": { "name": "Susan" } },
    { "id": "2", "type": "people", "attributes": { "name": "Bob" } },
    { "id": "3", "type": "people", "attributes": { "name": "Jane" } }
  ]
}
`}</code></pre>
    <p>{`Otherwise, only the primary resource is included:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`/* GET /movies/1 */

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    }
  }
}
`}</code></pre>
    <p>{`But some APIs will include all of a resource's relationship IDs, regardless if query param includes are used in the request or not.`}</p>
    <p>{`There's an option on `}<inlineCode parentName="p">{`JSONAPISerializer`}</inlineCode>{` that enables this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`JSONAPISerializer.extend({
  alwaysIncludeLinkageData: true,
})
`}</code></pre>
    <p>{`Now, a GET request to `}<inlineCode parentName="p">{`/movies/1`}</inlineCode>{` would respond with this payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`/* GET /movies/1 */

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    },
    "relationships": {
      "cast-members": {
        "data": [
          { "type": "people", "id": "1" },
          { "type": "people", "id": "2" },
          { "type": "people", "id": "3" }
        ]
      }
    }
  }
}
`}</code></pre>
    <p>{`And now, your JavaScript app could use these ids to subsequently fetch the related cast members.`}</p>
    <p>{`Other API contracts work by responding with links to fetch related data. The `}<inlineCode parentName="p">{`JSONAPISerializer`}</inlineCode>{` also has a hook for this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`JSONAPISerializer.extend({
  links(movie) {
    return {
      "cast-members": {
        related: \`/api/movies/\${movie.id}/cast-members\`,
      },
    }
  },
})
`}</code></pre>
    <p>{`Now a GET request to `}<inlineCode parentName="p">{`/movies/1`}</inlineCode>{` would respond with this payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`/* GET /movies/1 */

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    },
    "relationships": {
      "cast-members": {
        "links": {
          "related": "/api/movies/1/cast-members"
        }
      }
    }
  }
}
`}</code></pre>
    <p>{`The other serializers also have mechanisms controlling how related data can be loaded. Be sure to check out the API docs for all the details.`}</p>
    <h2 {...{
      "id": "working-with-serialized-json"
    }}>{`Working with serialized JSON`}</h2>
    <p>{`While most route handlers should return a Model or Collection instance and leave the serialization logic up to the Serializer, sometimes it can be convenient to perform some final serialization logic directly in your route handler.`}</p>
    <p>{`You can use the `}<inlineCode parentName="p">{`this.serialize`}</inlineCode>{` helper method in a route handler to do this - make sure to use a `}<inlineCode parentName="p">{`function`}</inlineCode>{` instead of a fat arrow so you have access to the correct `}<inlineCode parentName="p">{`this`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  routes() {
    this.get("/movies", function (schema, request) {
      let movies = schema.movies.all()
      let json = this.serialize(movies)

      json.meta.size = movies.length

      return json
    })
  },
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`serialize`}</inlineCode>{` helper will use the typical lookup logic to first check for a model-specific serializer, and then fall back to the default Application serializer.`}</p>
    <p>{`You can also use a specific serializer if you have a special case by passing in the name of the serializer as a second argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, RestSerializer } from "miragejs"

let ApplicationSerializer = RestSerializer.extend()

createServer({
  serializers: {
    application: ApplicationSerializer,
    movieWithRelationships: ApplicationSerializer.extend({
      include: ["castMembers", "reviews"],
    }),
  },

  routes() {
    this.get("/movies/:id", function (schema, request) {
      let movie = schema.movies.find(request.params.id)
      let json = this.serialize(movie, "movie-with-relationships")

      return json
    })
  },
})
`}</code></pre>
    <p>{`Note that the string name is the kebab-case version (`}<inlineCode parentName="p">{`movie-with-relationships`}</inlineCode>{`) of key you used to define the serializer (`}<inlineCode parentName="p">{`movieWithRelationships`}</inlineCode>{`).`}</p>
    <hr></hr>
    <p>{`In general, you should strive to use the provided hooks to implement an ApplicationSerializer that works for the majority of your models.`}</p>
    <p>{`Consistency is the name of the game when it comes to APIs. The more consistent your production API is, the easier it will be to implement it in Mirage. Use your Mirage server to communicate between your frontend and backend teams about inconsistencies in your API contract. Conventional API contract contracts will help you write less code – not only in Mirage, but also in the rest of your JavaScript app!`}</p>
    <p>{`Be sure to check out the API docs to learn about all the hooks available to customize your serializer layer.`}</p>
    <hr></hr>
    <p>{`Now that we've covered all of Mirage's main concepts, we're ready to see how we can use Mirage to effectively test our JavaScript application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      