import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "the-orm"
    }}>{`The ORM`}</h1>
    <p>{`Mirage originally shipped with just a database as its data layer. While helpful, users still had to write a lot of code to reproduce their modern, complex backends. In particular, dealing with relationships was a big pain point.`}</p>
    <p>{`The solution was to add an Object Relational Mapper, or ORM, to Mirage.`}</p>
    <p>{`Let's see how an ORM allows Mirage to do more of the heavy lifting for you.`}</p>
    <h2 {...{
      "id": "why-an-orm"
    }}>{`Why an ORM?`}</h2>
    <p>{`Consider a database that looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`db.dump()

// Result
{
  movies: [
    { id: "1", title: "Interstellar" },
    { id: "2", title: "Inception" },
    { id: "3", title: "Dunkirk" },
  ]
}
`}</code></pre>
    <p>{`The first problem you'll encounter when writing a route handler is how to transform this raw data into the format your app expects – that is, how to match the format of your production API.`}</p>
    <p>{`Let's say your backend uses `}<a parentName="p" {...{
        "href": "https://jsonapi.org/"
      }}>{`the JSON:API spec`}</a>{`. Your response to a GET request for `}<inlineCode parentName="p">{`/api/movies/1`}</inlineCode>{` should look something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`// GET /api/movies/1
{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    }
  }
}
`}</code></pre>
    <p>{`Not a huge deal – we could just write this formatting logic directly in our route handler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies/:id", (schema, request) => {
  let movie = schema.db.movies.find(request.params.id)

  return {
    data: {
      id: movie.id,
      type: "movies",
      attributes: {
        title: movie.title,
      },
    },
  }
})
`}</code></pre>
    <p>{`This works. But let's say our `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model had a few more attributes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "1",
  "title": "Interstellar",
  "releaseDate": "October 26, 2014",
  "genre": "Sci-Fi"
}
`}</code></pre>
    <p>{`Now our route handler needs to be more clever, and make sure all properties other than `}<inlineCode parentName="p">{`id`}</inlineCode>{` end up in the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` hash:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get('/movies/:id', (schema, request) => {
  let movie = schema.db.movies.find(request.params.id);
  let movieJSON = {
    data: {
      id: movie.id,
      type: 'movies',
      attributes: { }
    }
  };
  Object.keys(movie)
    .filter(key => key !=== 'id')
    .forEach(key => {
      movieJSON.attributes[key] = movie[key];
    });

  return movieJSON;
});
`}</code></pre>
    <p>{`As you can see, things get complicated pretty fast.`}</p>
    <p>{`What if we add relationships to the mix? Let's say a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` has a relationship to a `}<inlineCode parentName="p">{`director`}</inlineCode>{`, and it stores that relationship using a `}<inlineCode parentName="p">{`directorId`}</inlineCode>{` foreign key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`attrs = {
  id: "1",
  title: "Interstellar",
  releaseDate: "October 26, 2014",
  genre: "Sci-Fi",
  directorId: "23",
}
`}</code></pre>
    <p>{`The expected HTTP response for this model now looks like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar"
    },
    "relationships": {
      "directors": {
        "data": { "type": "people", "id": "23" }
      }
    }
  }
}
`}</code></pre>
    <p>{`meaning our route handlers need to get even more complex. In particular, they need a robust way to differentiate between a model's attributes (like `}<inlineCode parentName="p">{`title`}</inlineCode>{`) and its relationship keys (like `}<inlineCode parentName="p">{`directorId`}</inlineCode>{`).`}</p>
    <p>{`These sorts of problems turn out to be common enough that we can solve them generally, provided Mirage is aware of your application's models and their relationships.`}</p>
    <h2 {...{
      "id": "problems-solved-by-the-orm"
    }}>{`Problems solved by the ORM`}</h2>
    <p>{`When Mirage knows about your application's domain, it can shoulder the responsibility for the low-level bookkeeping work needed to properly implement your mock server.`}</p>
    <p>{`Let's take a look at some examples of how it does this.`}</p>
    <h3 {...{
      "id": "separation-of-formatting-logic"
    }}>{`Separation of formatting logic`}</h3>
    <p>{`To start, we can tell Mirage about our application's schema by defining Mirage models. These models get registered with the ORM and tell Mirage about the shape of your data.`}</p>
    <p>{`Let's define a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model } from "miragejs"

createServer({
  models: {
    movie: Model.extend(),
  },
})
`}</code></pre>
    <p>{`Mirage models are `}<em parentName="p">{`schemaless in attributes`}</em>{`, in that they don't require you to define plain attributes like `}<inlineCode parentName="p">{`title`}</inlineCode>{` and `}<inlineCode parentName="p">{`releaseDate`}</inlineCode>{`. So, the above model definition works regardless of what attributes your `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model has.`}</p>
    <p>{`With the `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model defined, we can update our route handler to use the ORM to respond with a Mirage model instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model } from "miragejs"

createServer({
  models: {
    movie: Model.extend(),
  },

  routes() {
    this.get("/movies/:id", (schema, request) => {
      let id = request.params.id

      return schema.movies.find(id)
    })
  },
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`schema`}</inlineCode>{` argument is how you interact with the ORM.`}</p>
    <p>{`By returning an instance of a Mirage model from a route handler instead of a plain JavaScript object, we can now take advantage of Mirage's Serializer layer. Serializers work by turning Models and Collections into formatted JSON responses.`}</p>
    <p>{`Mirage ships with a JSONAPISerializer out of the box, so if we set it as our Application serializer`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, JSONAPISerializer } from "miragejs"

createServer({
  models: {
    movie: Model.extend(),
  },

  serializers: {
    application: JSONAPISerializer,
  },

  routes() {
    this.get("/movies/:id", (schema, request) => {
      let id = request.params.id

      return schema.movies.find(id)
    })
  },
})
`}</code></pre>
    <p>{`this route handler will now respond with the payload we expect:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`/* GET /movies/1 */

{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar",
      "releaseDate": "October 26, 2014",
      "genre": "Sci-Fi"
    }
  }
}
`}</code></pre>
    <p>{`The ORM is already helping us keep our route handlers tidy by delegating the work of transforming our models into JSON to the Serializer layer.`}</p>
    <p>{`But it gets even more powerful when we add relationships to the mix.`}</p>
    <h3 {...{
      "id": "fetching-related-data"
    }}>{`Fetching related data`}</h3>
    <p>{`Let's say our `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` has a belongs-to relationship with a `}<inlineCode parentName="p">{`director`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// mirage/models/movie.js
import { createServer, Model, belongsTo, JSONAPISerializer } from "miragejs"

createServer({
  models: {
    person: Model.extend(),

    movie: Model.extend({
      director: belongsTo("person"),
    }),
  },

  serializers: {
    application: JSONAPISerializer,
  },

  routes() {
    this.get("/movies/:id", (schema, request) => {
      let id = request.params.id

      return schema.movies.find(id)
    })
  },
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`director`}</inlineCode>{` is a named relationship that points to our `}<inlineCode parentName="p">{`Person`}</inlineCode>{` model.`}</p>
    <p>{`Without changing anything about our route handler or serializer, we can now fetch a graph of data by using JSON:API includes.`}</p>
    <p>{`The following request`}</p>
    <pre><code parentName="pre" {...{}}>{`GET /api/movies/1?include=director
`}</code></pre>
    <p>{`now generates this response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "id": "1",
    "type": "movies",
    "attributes": {
      "title": "Interstellar",
      "releaseDate": "October 26, 2014",
      "genre": "Sci-Fi"
    },
    "relationships": {
      "director": {
        "data": { "type": "people", "id": "1" }
      }
    }
  },
  "included": [
    {
      "id": "1",
      "type": "people",
      "attributes": {
        "name": "Christopher Nolan"
      }
    }
  ]
}
`}</code></pre>
    <p>{`The JSONAPISerializer is able to inspect the ORM so that it can put all models, attributes and relationships in the right place. Our route handler doesn't need to change at all.`}</p>
    <p>{`In fact, the route handler we wrote is the same as the default behavior of the Shorthand equivalent, meaning we can switch to using that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`- this.get('/movies/:id', (schema, request) => {
-   let id = request.params.id;

-   return schema.movies.find(id);
- });
+ this.get('/movies/:id');
`}</code></pre>
    <p>{`This is another example of how the ORM helps the various parts of Mirage, like Shorthands and Serializers, work together to simplify your server definition.`}</p>
    <h3 {...{
      "id": "creating-and-editing-related-data"
    }}>{`Creating and editing related data`}</h3>
    <p>{`The ORM also makes creating and editing related data easier than if you only worked with the raw database records.`}</p>
    <p>{`For instance, to create a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` and `}<inlineCode parentName="p">{`Person`}</inlineCode>{` with a relationship using only the database, you'd need to do something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.db.loadData({
  people: [
    {
      id: "1",
      name: "Christopher Nolan",
    },
  ],
  movies: [
    {
      id: "1",
      title: "Interstellar",
      releaseDate: "October 26, 2014",
      genre: "Sci-Fi",
      directorId: "1",
    },
  ],
})
`}</code></pre>
    <p>{`Note the `}<inlineCode parentName="p">{`directorId`}</inlineCode>{` foreign key on the `}<inlineCode parentName="p">{`Movies`}</inlineCode>{` record must match the `}<inlineCode parentName="p">{`id`}</inlineCode>{` on the associated `}<inlineCode parentName="p">{`People`}</inlineCode>{` record.`}</p>
    <p>{`Managing raw database data like this quickly gets unwieldy, especially as relationships change over time.`}</p>
    <p>{`Using the ORM via `}<inlineCode parentName="p">{`server.schema`}</inlineCode>{`, we can create this graph without managing any IDs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let nolan = schema.people.create({ name: "Christopher Nolan" })

schema.movies.create({
  director: nolan,
  title: "Interstellar",
  releaseDate: "October 26, 2014",
  genre: "Sci-Fi",
})
`}</code></pre>
    <p>{`Passing in the model instance `}<inlineCode parentName="p">{`nolan`}</inlineCode>{` as the `}<inlineCode parentName="p">{`director`}</inlineCode>{` attribute when creating the movie is enough for all the keys to be properly set up.`}</p>
    <p>{`The ORM also keeps foreign keys in sync as relationships are edited. Given the database`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  movies: [
    {
      id: '1',
      title: 'Star Wars: The Rise of Skywalker',
      directorId: '2'
    }
  ],
  people: [
    {
      id: '2',
      name: 'Rian Johnson'
    },
    {
      id: '3',
      name: 'J.J. Abrams'
    }
  ]
}
`}</code></pre>
    <p>{`we could update the movie's director like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let episode9 = schema.movies.findBy({
  title: 'Star Wars: The Rise of Skywalker'
});

episode9.update({
  director: schema.people.findBy({ name: 'J.J. Abrams' });
});
`}</code></pre>
    <p>{`The new database would look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  movies: [
    {
      id: '1',
      title: 'Star Wars: The Rise of Skywalker',
      directorId: '3'
    }
  ],
  people: [
    {
      id: '2',
      name: 'Rian Johnson'
    },
    {
      id: '3',
      name: 'J.J. Abrams'
    }
  ]
}
`}</code></pre>
    <p>{`Note how the `}<inlineCode parentName="p">{`directorId`}</inlineCode>{` was changed in the database, even though we only ever worked with model instances.`}</p>
    <p>{`Importantly, this also holds true for more complex relationships, like one-to-many or many-to-many relationships that have an inverse.`}</p>
    <p>{`The ORM allows Mirage to abstract all this bookkeeping away from your code, and even gives Shorthands enough power to respect arbitrary updates to complex relationship graphs.`}</p>
    <hr></hr>
    <p>{`These are some of the main problems addressed by Mirage's ORM. Generally, when Mirage knows about your application's schema, it can take on more of the responsibility of configuring your mock server.`}</p>
    <p>{`Next, we'll take a look at how to actually define your models and their relationships in Mirage.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      