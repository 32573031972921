import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "route-handlers"
    }}>{`Route handlers`}</h1>
    <p>{`Route handlers let you define which URLs your Mirage server can handle.`}</p>
    <p>{`The simplest route handler maps a URL to an object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", { movies: ["Interstellar", "Inception", "Dunkirk"] })
`}</code></pre>
    <p>{`Now when your app makes a GET request to `}<inlineCode parentName="p">{`/movies`}</inlineCode>{`, it will receive this object as JSON data.`}</p>
    <p>{`If your API is on a different host or port than your app, set `}<inlineCode parentName="p">{`urlPrefix`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    routes() {
      this.urlPrefix = 'http://localhost:3000';
`}</code></pre>
    <p>{`You can also write function route handlers by passing in a function as the second argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", (schema, request) => {
  return ["Interstellar", "Inception", "Dunkirk"]
})
`}</code></pre>
    <p>{`Function route handlers are the most flexible way to write route handlers since they give you access to Mirage's data layer and the request object. Most of your route handlers will be functions.`}</p>
    <p>{`You can use any of the HTTP verbs to define your API routes. Each verb method has the same signature. The first argument is the path (URL) and the second is a function that returns the response.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get('/movies', () => { ... });
this.post('/movies', () => { ... });
this.patch('/movies/:id', () => { ... });
this.put('/movies/:id', () => { ... });
this.del('/movies/:id', () => { ... });
this.options('/movies', () => { ... });
`}</code></pre>
    <h2 {...{
      "id": "timing"
    }}>{`Timing`}</h2>
    <p>{`The last argument to a route handler is an options object you can use to adjust the timing. Use this to delay the response of a particular route and see how your app behaves when communicating with a slow network.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get(
  "/movies",
  () => {
    return ["Interstellar", "Inception", "Dunkirk"]
  },
  { timing: 4000 }
)
`}</code></pre>
    <p>{`The default delay is 400ms during development, and 0 during testing (so your tests run fast).`}</p>
    <p>{`You can also set a global timing parameter for all routes. Individual timing parameters override the global setting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  routes() {
    this.namespace = "api"
    this.timing = 2000

    this.get("/movies", () => {
      return ["Interstellar", "Inception", "Dunkirk"]
    })

    this.get(
      "/complex-query",
      () => {
        return [1, 2, 3, 4, 5]
      },
      { timing: 3000 }
    )
  },
})
`}</code></pre>
    <p>{`If you want to add delays to a test, you can override the timing for individual tests by putting the timing parameter in your test`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`test("this route works with a delay", function () {
  server.timing = 10000

  // ...
})
`}</code></pre>
    <p>{`Because the server is reset after each test, this option won't leak into the rest of your suite.`}</p>
    <h2 {...{
      "id": "accessing-the-data-layer"
    }}>{`Accessing the data layer`}</h2>
    <p>{`Route handlers receive `}<inlineCode parentName="p">{`schema`}</inlineCode>{` as their first parameter, which lets them access Mirage's data layer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", (schema) => {
  return schema.movies.all()
})
`}</code></pre>
    <p>{`Most of your route handlers will interact with the data layer in some way.`}</p>
    <p>{`The second parameter is the `}<inlineCode parentName="p">{`request`}</inlineCode>{` object, which contains information about the request your app made. For example, you can access dynamic URL segments from it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies/:id", (schema, request) => {
  let id = request.params.id

  return schema.movies.find(id)
})
`}</code></pre>
    <p>{`You can also access the request body, for example to handle a POST or PATCH request that contains data sent over by the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/movies", (schema, request) => {
  let attrs = JSON.parse(request.requestBody)

  return schema.movies.create({ attrs })
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`normalizedRequestAttrs`}</inlineCode>{` helper (documented below) provides some sugar for working with the request data.`}</p>
    <h2 {...{
      "id": "dynamic-paths-and-query-params"
    }}>{`Dynamic paths and query params`}</h2>
    <p>{`The request object that's injected into your route handlers contains any dynamic route segments and query params.`}</p>
    <p>{`To define a route that has a dynamic segment, use colon syntax (`}<inlineCode parentName="p">{`:segment`}</inlineCode>{`) in your path. The dynamic piece will be available via `}<inlineCode parentName="p">{`request.params.[segment]`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/authors/:id", (schema, request) => {
  let id = request.params.id

  return schema.authors.find(id)
})
`}</code></pre>
    <p>{`Query params from the request can also be accessed via `}<inlineCode parentName="p">{`request.queryParams.[param]`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "status-codes-and-headers"
    }}>{`Status codes and headers`}</h2>
    <p>{`By default, Mirage sets the HTTP status code of a response based on the verb being used for the route:`}</p>
    <ul>
      <li parentName="ul">{`GET is 200`}</li>
      <li parentName="ul">{`PATCH/PUT is 204`}</li>
      <li parentName="ul">{`POST is 201`}</li>
      <li parentName="ul">{`DEL is 204`}</li>
    </ul>
    <p>{`PATCH/PUT and POST change to 200 if there is a response body.`}</p>
    <p>{`Additionally, a header for `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`application/json`}</inlineCode>{`.`}</p>
    <p>{`You can customize both the response code and headers by returning an instance of the `}<inlineCode parentName="p">{`Response`}</inlineCode>{` class in your route handler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Response } from "miragejs"

createServer({
  models: {
    author: Model,
  },
  routes() {
    this.post("/authors", function (schema, request) {
      let attrs = JSON.parse(request.requestBody).author

      if (attrs.name) {
        return schema.authors.create(attrs)
      } else {
        return new Response(
          400,
          { some: "header" },
          { errors: ["name cannot be blank"] }
        )
      }
    })
  },
})
`}</code></pre>
    <h2 {...{
      "id": "external-origins"
    }}>{`External origins`}</h2>
    <p>{`You can use Mirage to simulate other-origin requests. By default, a route like`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get('/contacts', ...)
`}</code></pre>
    <p>{`will intercept request made to the same origin that's serving your app. To handle a different origin, use a fully qualified domain name:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get('http://api.twitter.com/v1', ...)
`}</code></pre>
    <p>{`If your entire app uses an external (other-origin) API, you can globally configure the domain via `}<inlineCode parentName="p">{`urlPrefix`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  routes() {
    this.urlPrefix = 'https://my.api.com';

    // This route will intercept requests to https://my.api.com/contacts
    this.get('/contacts', ...)
  }
})
`}</code></pre>
    <h2 {...{
      "id": "helpers"
    }}>{`Helpers`}</h2>
    <p>{`There are several helpers available when writing function route handlers.`}</p>
    <p>{`If you're new to Mirage, don't worry about understanding these yet. They are helpful when writing more advanced route handlers.`}</p>
    <h3 {...{
      "id": "serialize"
    }}>{`serialize`}</h3>
    <p>{`This helper returns the JSON for the given Model or Collection after passing it through the Serializer layer. It's useful if you want to do some final munging on the serialized JSON before returning it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Note: Be sure to use function() here, rather than () => {}
this.get("/movies", function (schema) {
  let movies = schema.movies.all()
  let json = this.serialize(movies)

  json.meta = { page: 1 }

  return json
})
`}</code></pre>
    <p>{`By default this method uses the named serializer for the given Model or Collection. You can pass in a specific serializer name as the second argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", function (schema) {
  let movies = schema.movies.all()
  let json = this.serialize(movies, "sparse-movie")

  json.meta = { page: 1 }

  return json
})
`}</code></pre>
    <p>{`You'll learn more about Serializers later in these guides.`}</p>
    <h3 {...{
      "id": "normalizedrequestattrs"
    }}>{`normalizedRequestAttrs`}</h3>
    <p>{`This helper returns the body of a request in a normalized form, suitable for working with and creating records. It essentially removes the formatting logic from your API payload, giving you the underlying attributes which you can then use to modify Mirage's database.`}</p>
    <p>{`For example, if your app makes a POST request with this data`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`// POST /users

{
  "data": {
    "type": "users",
    "attributes": {
      "first-name": "Conan",
      "middle-name": "the",
      "last-name": "Barbarian"
    },
    "relationships": {
      "team": {
        "data": {
          "type": "teams",
          "id": 1
        }
      }
    }
  }
}
`}</code></pre>
    <p>{`then `}<inlineCode parentName="p">{`normalizedRequestAttrs()`}</inlineCode>{` could be used like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/users", function (schema, request) {
  let attrs = this.normalizedRequestAttrs()
  /*
    attrs is this object:
      {
        firstName: 'Conan',
        middleName: 'the',
        lastName: 'Barbarian',
        teamId: '1'
      }
  */
  return schema.users.create(attrs)
})
`}</code></pre>
    <p>{`Note that attribute keys were camelCased, and the `}<inlineCode parentName="p">{`team`}</inlineCode>{` foreign key was extracted. This is because a `}<inlineCode parentName="p">{`user`}</inlineCode>{` owns the `}<inlineCode parentName="p">{`team`}</inlineCode>{` foreign key; if another relationship were included in the request but the `}<inlineCode parentName="p">{`user`}</inlineCode>{` did not own its foreign key, it would not have been extracted.`}</p>
    <p>{`This helper method leverages your serializer's `}<inlineCode parentName="p">{`normalize`}</inlineCode>{` method. In the example above, it's assumed that the app was using the `}<inlineCode parentName="p">{`JSONAPISerializer`}</inlineCode>{`, which comes with the `}<inlineCode parentName="p">{`#normalize`}</inlineCode>{` method already written. If you're not using one of the bundled serializers, you'll need to implement `}<inlineCode parentName="p">{`#normalize`}</inlineCode>{` and have it return a JSON:API document to take advantage of this method.`}</p>
    <p>{`Additionally, you'll need to use a full `}<inlineCode parentName="p">{`function`}</inlineCode>{` here, as opposed to an ES6 arrow function (e.g `}<inlineCode parentName="p">{`() => { ... }`}</inlineCode>{`). This is because `}<inlineCode parentName="p">{`normalizedRequestAttrs`}</inlineCode>{` requires the `}<inlineCode parentName="p">{`this`}</inlineCode>{` context from the function handler, and an arrow function would bind `}<inlineCode parentName="p">{`this`}</inlineCode>{` from the outer scope.`}</p>
    <p><inlineCode parentName="p">{`normalizedRequestAttrs()`}</inlineCode>{` relies on a `}<inlineCode parentName="p">{`modelName`}</inlineCode>{` to work and attempts to automatically detect it based on the URL of the request. If you use conventional URLs – for example, PATCH /users/1 – the helper should work. If you are using something custom – for example, PATCH /users/edit/1 – you’ll need to provide the `}<inlineCode parentName="p">{`modelName`}</inlineCode>{` to the helper as the first argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.patch("/users/edit/:id", function (schema, request) {
  let attrs = this.normalizedRequestAttrs("user")
  // ...
})
`}</code></pre>
    <hr></hr>
    <p>{`That's it on writing low-level function route handlers!`}</p>
    <p>{`Function route handlers are flexible, but also cumbersome to write out for every endpoint. If you're working with an API that's conventional enough, hopefully you'll be writing fewer function route handlers and more Shorthands. Let's discuss those next!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      