import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "overview"
    }}>{`Overview`}</h1>
    <p>{`These quickstart guides will help you get Mirage working with your particular combination of JavaScript framework and test runner.`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://github.com/miragejs/examples"
      }}>{`miragejs/examples`}</a>{` repo if you'd like to play with working examples.`}</p>
    <p>{`If you are new to Mirage and want to learn how it works, give `}<Link to='/docs/getting-started/introduction/' mdxType="Link">{`the guides`}</Link>{` a read first.`}</p>
    <p>{`If you don't see your tool listed here, there's a good chance Mirage will still work with your tech. A quickstart guide probably just hasn't been written yet. `}<a parentName="p" {...{
        "href": "https://github.com/miragejs/site/pulls"
      }}>{`Check this site's PRs`}</a>{` to see if there's already one being worked on, and if not, feel free to open a PR to get yours added!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      