import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-9--testing"
    }}>{`Part 9 – Testing`}</h1>
    <p>{`In this final section, we're going to learn how to use Mirage to test our app given a variety of different server states.`}</p>
    <p>{`Our project is already set up with `}<strong parentName="p">{`Jest`}</strong>{` and `}<strong parentName="p">{`Testing Library`}</strong>{`. We've also provided a `}<inlineCode parentName="p">{`visit(url)`}</inlineCode>{` helper that renders our Reminders app at a given URL.`}</p>
    <p>{`Let's open `}<inlineCode parentName="p">{`src/__tests__/app.js`}</inlineCode>{` and write our first test.`}</p>
    <p>{`We want to verify that our app shows "All done!" when there are no reminders. Here's the code for the test – go ahead and copy it into your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// __tests__/app.js
import { visit } from "../lib/test-helpers"
import { screen, waitForElementToBeRemoved } from "@testing-library/react"

test("it shows a message when there are no reminders", async () => {
  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`Now in a new terminal window, run `}<inlineCode parentName="p">{`yarn test`}</inlineCode>{`. Jest should start a watcher that will re-run your tests every time you make a change.`}</p>
    <p>{`After the test finishes its first run, you should see an error:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Unable to find an element with the text: All done!`}</p>
    </blockquote>
    <p>{`You should also see an error that says "Network request failed.`}</p>
    <p>{`And if you look at the debug output, you'll even see the familiar network error UI from Part 1 of this tutorial showing up in the DOM:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #52595D"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/656c31a1739ea55162c2e40b36da6499/2acc9/part-9-network-error-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABW0lEQVQoz42SV3LDMAxEfZNMbJGyKFmNIkU1j8clk8/c/zIbgCqWS2bysQZY/IQFsUlyh7y+Iml/EGcGpTuhai+I8w65HqASC6kqhPH/tJH0I5SGjFmURxqhbpB3FpE1+HQtZEbQ1Nwj6zDJr+/7mzAxWIurUbpGdbZQ5xYfpwGBcQhob2fHyBIlqaC8css5575CD2IgV5g12A3f9LUae7a6VrTKZ5tPd5YKF2DqkHUEsxY7b3GUmOzOke/K6X9rPQJJQhmkdQ/d9QhtN9ohe2vbbJerkU/tCl+BVGFsoPqrh+5pAgQ1PqCqBJ1Jfjx6NDnDuMqpZQ/A+XB5FNPgUDf+tfdVA1m3iCiPqK8qcz76u08wD1y+NG9SFUHzhdRdkBUtzWLvVZgBh7JDYY9Iaf0ncN1chm0JtnU36mVJ9scZFTSjPqpprfQCe7H8CLt5YEiw8E3DX2b2TYW/MzdTuNZKVRYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Network error",
            "title": "Network error",
            "src": "/static/656c31a1739ea55162c2e40b36da6499/37523/part-9-network-error-dark.png",
            "srcSet": ["/static/656c31a1739ea55162c2e40b36da6499/e9ff0/part-9-network-error-dark.png 180w", "/static/656c31a1739ea55162c2e40b36da6499/f21e7/part-9-network-error-dark.png 360w", "/static/656c31a1739ea55162c2e40b36da6499/37523/part-9-network-error-dark.png 720w", "/static/656c31a1739ea55162c2e40b36da6499/302a4/part-9-network-error-dark.png 1080w", "/static/656c31a1739ea55162c2e40b36da6499/07a9c/part-9-network-error-dark.png 1440w", "/static/656c31a1739ea55162c2e40b36da6499/2acc9/part-9-network-error-dark.png 2378w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Just like in Part 1, this is happening because our app is making its initial fetch to `}<inlineCode parentName="p">{`/api/reminders`}</inlineCode>{` but there's no server to respond to it. It's time to bring our Mirage server into the test.`}</p>
    <p>{`Let's import our `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function and run it at the beginning of our test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3,6}"
      }}>{`import { visit } from "../lib/test-helpers"
import { screen, waitForElementToBeRemoved } from "@testing-library/react"
import makeServer from "../server"

test("it shows a message when there are no reminders", async () => {
  makeServer()
  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`Our test is still failing, but if we scroll through the debug output we don't see the message about a failed Network Request anymore.`}</p>
    <p>{`Instead, we'll see that the DOM is rendering the existing reminders we created in the `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` hook from the previous part of the tutorial:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #52595D"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e578c3b664779075b9a525ef0dcb7f9b/9b204/part-9-dev-seeds-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.88888888888889%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABSElEQVQoz41T23aDIBDMnzSNIo23iAoqufbktP//R9MFVqP2kj7MWVgOs7M7sJG5RkKQWYOkski7O0plad8GpIz5epYLd2ntImHzIKTDakB2vUM2PeK6Q6wIDaPm2PZTTlRmIh2xGZll3kJkGvrYQF4str31+aT4A6O6GZaEqUZ9NNAfBtvLySv0F0vDmJGVjIN5rMux5SwQJjSXvT6iJNJosIioPaG4XYZQZrH3rft8wHKGZEbUvyPKqKrbj0pY1bq9tUGLlp062Vjkwxlv7QBJcEOPp+rmO+HKkGTpcng2+9Mn8toiU0TICgXPUNC8XBEfDyH3u8t0IHIy5EoHlwEvPStU3aRyUsvRGfLUZWU1yluPVzLFK0/bH1t93jK7nJOy5q6xu52x64bgpHvM/kHPXB8fPH8CwVi6XHSI6OslBbu8qv4ffAHupnHL1Y/6UAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dev seeds",
            "title": "Dev seeds",
            "src": "/static/e578c3b664779075b9a525ef0dcb7f9b/37523/part-9-dev-seeds-dark.png",
            "srcSet": ["/static/e578c3b664779075b9a525ef0dcb7f9b/e9ff0/part-9-dev-seeds-dark.png 180w", "/static/e578c3b664779075b9a525ef0dcb7f9b/f21e7/part-9-dev-seeds-dark.png 360w", "/static/e578c3b664779075b9a525ef0dcb7f9b/37523/part-9-dev-seeds-dark.png 720w", "/static/e578c3b664779075b9a525ef0dcb7f9b/302a4/part-9-dev-seeds-dark.png 1080w", "/static/e578c3b664779075b9a525ef0dcb7f9b/07a9c/part-9-dev-seeds-dark.png 1440w", "/static/e578c3b664779075b9a525ef0dcb7f9b/9b204/part-9-dev-seeds-dark.png 2520w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This makes sense, since we're creating the exact same Mirage server as we do in development, and we seeded that server with these five reminders to help us develop the app.`}</p>
    <p>{`So, we'd like to have a fresh instance of our Mirage server here, one without any data, so we can verify that the empty state shows the "All done!" message. One way we could accomplish this would be to go back to our server definition and delete all our `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` data.`}</p>
    <p>{`But there's a better way that doesn't involve changing our dev seeds: we can start our Mirage server in "test" mode using the `}<inlineCode parentName="p">{`environment`}</inlineCode>{` option.`}</p>
    <p>{`To see how this works, back in your `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` file, add the `}<inlineCode parentName="p">{`environment: 'test'`}</inlineCode>{` option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{6}"
      }}>{`// server.js
import { createServer } from "miragejs"

export default function () {
  return createServer({
    environment: "test",

    // rest of server
  })
}
`}</code></pre>
    <p>{`Save that change, and once your test re-runs, it should pass!`}</p>
    <p>{`So, what does the `}<inlineCode parentName="p">{`"test"`}</inlineCode>{` environment do to our server? A few things: it sets the `}<inlineCode parentName="p">{`timing`}</inlineCode>{` to 0 so that our tests run fast; it hides Mirage's logging so your test output remains clean; and most importantly, it skips the `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` hook.`}</p>
    <p>{`So, we get to reuse all of our models, serializers, factories and routes, but keep the `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` data set apart for development mode. In testing, we will use each test to set up the data for our server in exactly the state we need for that test.`}</p>
    <p>{`For our current test, we actually want the database to be empty, so we just need to start the server without creating any extra data. That allows Mirage to handle the GET API request correctly, and it responds with an empty dataset. And now our "All done" message assertion is passing, which is exactly what we want for this test.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #52595D"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f107d9ac4511d83933da8c05e0f17599/6b690/part-9-first-passing-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.111111111111114%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABd0lEQVQoz4VS2VLDMBDLl0Djoz4TOwlthwBhGP7/m4S2F6FT4EGJ17a82tU2H+8zfDlAuR7ttsNG4AjfoSU2NsGEATaO2JjMOPPeT8ieJl/7Ho33A3Z5wRQKMWFMxFCxGwbkXGG3BSYn6Bj5JzEkPGpPhBU8k4UjmtZ2SOUFYx1QSkWuxNTD5w6WZFc6+FjgqFI7UUOiFrJfIZyTeDSPDMz4BjvNaIOUShKlb7aJbYhQjBXboF1iefFKvii6RSMfnw7Y9nvo+gI1LkzAmOW6jnuMdZ1hu1O5rY1XNffQaBqg8wTj2Ss2XrE0Q6KmWlkr7mnZtyeDbg25RdMqypfXqfRBubP0eMa6HMY2fp9xLWov//Z81iiaYTqWx75VlmpDD5cKL9zvkZTlUkUsO46SjFM9mmVlzKw8OO8R9gf2jeMyv7KfpwuGF4y4unLwgt8MOZqyLJ84PC/wH29wfFQy/Uf605RUntCPVBmojE0XdbdOrkfl72QeX11dQcfPjhNkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "First test passing",
            "title": "First test passing",
            "src": "/static/f107d9ac4511d83933da8c05e0f17599/37523/part-9-first-passing-dark.png",
            "srcSet": ["/static/f107d9ac4511d83933da8c05e0f17599/e9ff0/part-9-first-passing-dark.png 180w", "/static/f107d9ac4511d83933da8c05e0f17599/f21e7/part-9-first-passing-dark.png 360w", "/static/f107d9ac4511d83933da8c05e0f17599/37523/part-9-first-passing-dark.png 720w", "/static/f107d9ac4511d83933da8c05e0f17599/302a4/part-9-first-passing-dark.png 1080w", "/static/f107d9ac4511d83933da8c05e0f17599/07a9c/part-9-first-passing-dark.png 1440w", "/static/f107d9ac4511d83933da8c05e0f17599/6b690/part-9-first-passing-dark.png 2556w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now, if you switch back to your development server (or run `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{` in another terminal window), you'll notice that the Reminders we created in `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` no longer show up. That's because our dev server is now also running in test mode.`}</p>
    <p>{`Let's fix this by updating the function we're exporting from `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` to take in an environment argument, and we'll use that to set our server's environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default function (environment = "development") {
  return createServer({
    environment,

    // ...rest of server
  })
}
`}</code></pre>
    <p>{`Now our dev server is using our seeds again, and has an artificial delay and console logs to help us develop, but our test should be failing again.`}</p>
    <p>{`Back in our test file we'll update our call to `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` and pass in an environment of "test":`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{6}"
      }}>{`import { visit } from "../lib/test-helpers"
import { screen, waitForElementToBeRemoved } from "@testing-library/react"
import makeServer from "../server"

test("it shows a message when there are no reminders", async () => {
  makeServer("test")
  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`and now our test passes, but our dev environment still has its own isolated seed data to help us during development!`}</p>
    <p>{`This is just one way to set up Mirage. In your own projects, how you set up the arguments and choose the defaults are up to you. But the key point is you should be sharing your Mirage server across both your development and testing environments.`}</p>
    <p>{`Now that we have an easy way to create isolated Mirage servers in our tests, let's keep going and see how we can write a test for our UI when three Reminders already exist on the server.`}</p>
    <p>{`We'll start by copying and pasting our previous test, and updating the description:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{1}"
      }}>{`test("it shows existing reminders", async () => {
  makeServer("test")
  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`As soon as you save, Jest will run both tests and you should see the following warning:`}</p>
    <blockquote>
      <p parentName="blockquote">{`You created a second Pretender instance while there was already one running`}</p>
    </blockquote>
    <p>{`Mirage uses the Pretender library under the hood, and Pretender is telling us we have two servers that are colliding with each other. We need to use the `}<inlineCode parentName="p">{`server.shutdown()`}</inlineCode>{` method to clean up after our previous test, as well as the end of this new one.`}</p>
    <p>{`We can do that by first opening up our `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` file, and making sure our `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function returns the server instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3}"
      }}>{`// server.js
export default function (environment = "development") {
  return createServer({
    // rest of server
  })
}
`}</code></pre>
    <p>{`And then back in our test, we can assign the return value of `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` to a local variable, and use that to call `}<inlineCode parentName="p">{`server.shutdown()`}</inlineCode>{` at the end of each test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{2,8,12,18}"
      }}>{`test("it shows a message when there are no reminders", async () => {
  let server = makeServer("test")

  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
  server.shutdown()
})

test("it shows existing reminders", async () => {
  let server = makeServer("test")

  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
  server.shutdown()
})
`}</code></pre>
    <p>{`Now both tests should be running and passing, with no more Pretender warning.`}</p>
    <p>{`Now let's actually write our second test.`}</p>
    <p>{`Since we want to test how the UI behaves given the server starts out with three Reminders, we need to create that data before we call `}<inlineCode parentName="p">{`visit('/')`}</inlineCode>{`.`}</p>
    <p>{`We can use our new local `}<inlineCode parentName="p">{`server`}</inlineCode>{` variable to seed our server directly in our test, in exactly the same way that we do within our `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` hook. In fact, let's copy those three `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` statements and bring them into our test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3-5}"
      }}>{`test("it shows existing reminders", async () => {
  let server = makeServer("test")
  server.create("reminder", { text: "Walk the dog" })
  server.create("reminder", { text: "Take out the trash" })
  server.create("reminder", { text: "Work out" })

  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`You should see a test failure:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Unable to find an element with the text: All done!`}</p>
    </blockquote>
    <p>{`And if you look at the debug output, you should see our three reminders in the HTML:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #52595D"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c03b0019735706e127423c4c1b7be864/2e788/part-9-three-reminders-debug-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABEElEQVQoz42SV5LEIAxEfZZdY5zHHgOOkz72/kfqFQLHmvTxCqoEXS21ApkriEyj6C9I9ICwNghVh7BpHXQXlUFU6JVcQ2bKnQcCFkwV8n5E3JMgfRZbTp7a0vL5QVCzYNk0OP11+Gl7LuwceazIzCz6VDCigtB3pB21R22yo2rjrHLuZtH3DrlgEJuJP4a2rfP6WaYb3jjbO0wbZGpAMd2WIOzJ8yq1w4fxCS+oENckeJ3wa92RwBKKdezv2xm+glOOaG3iQqF5aIS3EaLcr0j0IqRnBPNMRNkhoYQj07o5VuuarPd24/ywXv7NMkOpJkh9oXbNkjIHcwjli5adYKJGlNc7pBl2wTBn5+6bUP4B4C9SUuXSIbMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Three reminders debug",
            "title": "Three reminders debug",
            "src": "/static/c03b0019735706e127423c4c1b7be864/37523/part-9-three-reminders-debug-dark.png",
            "srcSet": ["/static/c03b0019735706e127423c4c1b7be864/e9ff0/part-9-three-reminders-debug-dark.png 180w", "/static/c03b0019735706e127423c4c1b7be864/f21e7/part-9-three-reminders-debug-dark.png 360w", "/static/c03b0019735706e127423c4c1b7be864/37523/part-9-three-reminders-debug-dark.png 720w", "/static/c03b0019735706e127423c4c1b7be864/302a4/part-9-three-reminders-debug-dark.png 1080w", "/static/c03b0019735706e127423c4c1b7be864/07a9c/part-9-three-reminders-debug-dark.png 1440w", "/static/c03b0019735706e127423c4c1b7be864/2e788/part-9-three-reminders-debug-dark.png 2890w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Which is exactly what we want!`}</p>
    <p>{`Let's update our assertion:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{10-12}"
      }}>{`test("it shows existing reminders", async () => {
  let server = makeServer("test")
  server.create("reminder", { text: "Walk the dog" })
  server.create("reminder", { text: "Take out the trash" })
  server.create("reminder", { text: "Work out" })

  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("Walk the dog")).toBeInTheDocument()
  expect(screen.getByText("Take out the trash")).toBeInTheDocument()
  expect(screen.getByText("Work out")).toBeInTheDocument()
  server.shutdown()
})
`}</code></pre>
    <p>{`And with that, both our tests are now passing!`}</p>
    <p>{`As you can see, each test gives us an isolated place to alter the state of our Mirage server for the sake of whatever scenario we're testing. Since we cleanup after each test, none of these alterations leak from one test into another.`}</p>
    <p>{`Let's make a quick refactor. Since each test starts and stops our base Mirage server, we can use Jest's `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{` hooks to clean this up:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{5-13}"
      }}>{`import { visit } from "../lib/test-helpers"
import { screen, waitForElementToBeRemoved } from "@testing-library/react"
import makeServer from "../server"

let server

beforeEach(() => {
  server = makeServer("test")
})

afterEach(() => {
  server.shutdown()
})

test("it shows a message when there are no reminders", async () => {
  visit("/")

  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("All done!")).toBeInTheDocument()
})

test("it shows existing reminders", async () => {
  server.create("reminder", { text: "Walk the dog" })
  server.create("reminder", { text: "Take out the trash" })
  server.create("reminder", { text: "Work out" })

  visit("/")
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  expect(screen.getByText("Walk the dog")).toBeInTheDocument()
  expect(screen.getByText("Take out the trash")).toBeInTheDocument()
  expect(screen.getByText("Work out")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`Once you save, both tests should pass again.`}</p>
    <p>{`This change ensures our server is always cleaned up, and it also lets us write tests that are focused on higher-level steps relevant to the real-world user stories we're verifying: `}<em parentName="p">{`given`}</em>{` three reminders exist on the server, `}<em parentName="p">{`when`}</em>{` a user visits the app, `}<em parentName="p">{`then`}</em>{` they expect to see them on the page.`}</p>
    <p>{`Let's write one more test. We'll test that we can create a new Reminder for a specific list.`}</p>
    <p>{`We'll start our test off by seeding our Mirage server with a list, and then we'll visit the URL for that list:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`test("it can add a reminder to a list", async () => {
  let list = server.create("list")

  visit(\`/\${list.id}\`)
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))
})
`}</code></pre>
    <p>{`Note how useful it can be to use data from Mirage within our test. If you want to get a sense of the rendered output at this point, you could use the `}<inlineCode parentName="p">{`?open`}</inlineCode>{` query param to make sure the app's sidebar is open, and call `}<inlineCode parentName="p">{`screen.debug()`}</inlineCode>{` to see our List in the output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{4,7}"
      }}>{`test("it can add a reminder to a list", async () => {
  let list = server.create("list")

  visit(\`/\${list.id}?open\`)
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  screen.debug()
})
`}</code></pre>
    <p>{`You should see "All" and "List 0" in the sidebar UI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #52595D"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/adaa31c398b681c8c4b5d73a51809643/720e3/part-9-add-reminders-test-list-dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA00lEQVQoz5VRWw6DIBD0LFVEa6WtgBVMnzFNev8DTXkImsa+PgZ2FzK7s5PQjUReCdBGoRQdCGtB1+IzqhEL9SQQFtsW6saRaYWMHzxMgxg3Y27RzHI5/cmZDIQSdCOwu5yQHTTyWv4ONrsNV2IPOyphHXJ9B61b9/AXaS3jGuKEhSn2Q4PVuUcapH6S/LKWQBontHd5fKDcG8kl/27MgiGT5LUxQ5xB9AAa5Bq33V6Yj8nWw9fbWHPrGTFJNh2KfQd+VUg7hZXWTgYJbvL3mCu0XE/46/Zrp40bQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "New list in Add Reminders test",
            "title": "New list in Add Reminders test",
            "src": "/static/adaa31c398b681c8c4b5d73a51809643/37523/part-9-add-reminders-test-list-dark.png",
            "srcSet": ["/static/adaa31c398b681c8c4b5d73a51809643/e9ff0/part-9-add-reminders-test-list-dark.png 180w", "/static/adaa31c398b681c8c4b5d73a51809643/f21e7/part-9-add-reminders-test-list-dark.png 360w", "/static/adaa31c398b681c8c4b5d73a51809643/37523/part-9-add-reminders-test-list-dark.png 720w", "/static/adaa31c398b681c8c4b5d73a51809643/302a4/part-9-add-reminders-test-list-dark.png 1080w", "/static/adaa31c398b681c8c4b5d73a51809643/07a9c/part-9-add-reminders-test-list-dark.png 1440w", "/static/adaa31c398b681c8c4b5d73a51809643/720e3/part-9-add-reminders-test-list-dark.png 1962w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now if we create a new Reminder from this URL, it should be associated with this list, just like when we did this in development in Part 7.`}</p>
    <p>{`Let's go through the steps. First add the `}<inlineCode parentName="p">{`userEvent`}</inlineCode>{` import to the top of your file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import userEvent from "@testing-library/user-event"
`}</code></pre>
    <p>{`Then use it to click and type the appropriate elements. We're using `}<inlineCode parentName="p">{`data-testid`}</inlineCode>{` attributes to identify them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7-9}"
      }}>{`test("it can add a reminder to a list", async () => {
  let list = server.create("list")

  visit(\`/\${list.id}\`)
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  userEvent.click(screen.getByTestId("add-reminder"))
  await userEvent.type(screen.getByTestId("new-reminder-text"), "Work out")
  userEvent.click(screen.getByTestId("save-new-reminder"))

  // assert something
})
`}</code></pre>
    <p>{`Now, what should we do after clicking the submit button?`}</p>
    <p>{`If you switch back to development and try to make a Reminder, you'll see that the text box fades and then hides once the Reminder is created.`}</p>
    <p>{`So in our test, we can wait for the input to disappear, and then assert that the new Reminder shows up in the list:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{11-13}"
      }}>{`test("it can add a reminder to a list", async () => {
  let list = server.create("list")

  visit(\`/\${list.id}\`)
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  userEvent.click(screen.getByTestId("add-reminder"))
  await userEvent.type(screen.getByTestId("new-reminder-text"), "Work out")
  userEvent.click(screen.getByTestId("save-new-reminder"))

  await waitForElementToBeRemoved(() => screen.getByTestId("new-reminder-text"))

  expect(screen.getByText("Work out")).toBeInTheDocument()
})
`}</code></pre>
    <p>{`And it works!`}</p>
    <p>{`As one final step, it can often make sense to assert against the state of your Mirage server, just to give you a bit more confidence that your frontend code is doing what you think it is.`}</p>
    <p>{`In our case, if everything worked right, we should have a new Reminder in Mirage's database, and it should be associated to the list we created. We can add these assertions easily enough right alongside our UI assertion:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{14-15}"
      }}>{`test("it can add a reminder to a list", async () => {
  let list = server.create("list")

  visit(\`/\${list.id}\`)
  await waitForElementToBeRemoved(() => screen.getByText("Loading..."))

  userEvent.click(screen.getByTestId("add-reminder"))
  await userEvent.type(screen.getByTestId("new-reminder-text"), "Work out")
  userEvent.click(screen.getByTestId("save-new-reminder"))

  await waitForElementToBeRemoved(() => screen.getByTestId("new-reminder-text"))

  expect(screen.getByText("Work out")).toBeInTheDocument()
  expect(server.db.reminders.length).toEqual(1)
  expect(server.db.reminders[0].listId).toEqual(list.id)
})
`}</code></pre>
    <p>{`You can think of this as a simple way to verify that your UI is sending the right JSON payload over the wire, without having to drop to the lower level of asserting against HTTP request and response data.`}</p>
    <hr></hr>
    <p>{`Whew – that was the longest step of the Tutorial by far, but you accomplished a lot! You've got four tests covering some important functionality of the app, and you were able to reuse your Mirage server making only the changes you needed from test to test.`}</p>
    <p>{`Hopefully you can see how much more pleasant it is to write tests when you're able to leverage an existing Mirage server, and only tweak what's necessary to get your server into a specific state for each test.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Mirage makes it easy to share your mock server between development and testing`}</li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`test`}</inlineCode>{` environment for your Mirage server when testing so your tests run fast and the database starts out empty`}</li>
      <li parentName="ul">{`Take advantage of the fact that your Mirage server is easily accessible within your tests to do things like visit dynamic URLs or assert against changes made to your server's database`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      