import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-6--relationships"
    }}>{`Part 6 – Relationships`}</h1>
    <p>{`There's another feature in this app – Lists. If you click the button on the left side of the Reminders panel, a side panel will open up that shows the different Lists in this app.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dabdce043fbed78363674b1d19179b88/0ffd9/part-6-overview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.888888888888886%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABv0lEQVQoz4WSXWsTQRSG93dIFRE1IF54UVsKgv0H+ndEQfFGhRaFivUu0Fv/g3ijV36AkWxiPppNdyYz2e1O9iPNx87u65mJ1cYgHXg5Z86eeTiz8zreQOFIxvCHCXo8gtn3SN7/JEbgTEBwhjAIIIWk/QAhY2CeB6dPDV0/hNvl+Py9hXrbJ3gMHqRgQYIj+m4lF7FHUlxC1euIGg3Ebh3pzyYS18VxownnOM3xtvoOm9v3sLF9H89296F1gUiNkKSZzXOSLgqcTOY4pCnTkFTzEP1wMe22oTlHIQUQhnDUWOPlmwNcuXkHFytbePR0B1mWodXugvEBzq7ZLLfAJFAYdxjiTgd6OEQxlNBCQEtpgAV2X1dxqbKBteu38eDxc3s4y8Y2lqVRafOpAbKIJp9QsUCZ5yuywJ29KtaurePC1XULLOl6ahTTRLM/UDvhfAFMx9NFHavLUZnGq/0DVG7dxeUbW3j45MXv683sZObfGZl8Mp0vA6n2rxxjl1qzjw+fvuH9x6/4UmvRC8f0osrKPxN9qXDIl4ErExpvGaiITqwY2eWv70ZL0VjsXKBpsmamxlNjm1pfnMZlnQf8BTYZ18ZqPPYrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing",
            "title": "Failing",
            "src": "/static/dabdce043fbed78363674b1d19179b88/37523/part-6-overview.png",
            "srcSet": ["/static/dabdce043fbed78363674b1d19179b88/e9ff0/part-6-overview.png 180w", "/static/dabdce043fbed78363674b1d19179b88/f21e7/part-6-overview.png 360w", "/static/dabdce043fbed78363674b1d19179b88/37523/part-6-overview.png 720w", "/static/dabdce043fbed78363674b1d19179b88/302a4/part-6-overview.png 1080w", "/static/dabdce043fbed78363674b1d19179b88/07a9c/part-6-overview.png 1440w", "/static/dabdce043fbed78363674b1d19179b88/0ffd9/part-6-overview.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`But right now, we just see an error in our console because our app is expecting to fetch the lists from `}<inlineCode parentName="p">{`/api/lists`}</inlineCode>{`. Let's mock out this endpoint.`}</p>
    <p>{`To start, we'll define a new `}<inlineCode parentName="p">{`list`}</inlineCode>{` model at the top of our server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{2}"
      }}>{`models: {
  list: Model,
  reminder: Model,
}
`}</code></pre>
    <p>{`We'll also add a new route handler for a GET to `}<inlineCode parentName="p">{`/api/lists`}</inlineCode>{` that returns all the lists:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/api/lists", (schema, request) => {
  return schema.lists.all()
})
`}</code></pre>
    <p>{`Mirage now responds with a 200, but no lists. Let's create some in `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{6-7}"
      }}>{`seeds(server) {
  server.create("reminder", { text: "Walk the dog" });
  server.create("reminder", { text: "Take out the trash" });
  server.create("reminder", { text: "Work out" });

  server.create("list", { name: "Home" });
  server.create("list", { name: "Work" });
}
`}</code></pre>
    <p>{`Now when we open the sidebar, we see our two new lists "Home" and "Work" being rendered.`}</p>
    <p>{`Try clicking on a list. You'll see an error because our app is expecting another API endpoint to exist: `}<inlineCode parentName="p">{`/api/lists/1/reminders`}</inlineCode>{`. This is how it fetches the reminders for a specific list. Let's see how to mock it out.`}</p>
    <p>{`The easiest way to mock out endpoints that deal with `}<em parentName="p">{`relational data`}</em>{` in Mirage is to use `}<strong parentName="p">{`associations`}</strong>{`. We'll start by using Mirage's association helpers to model this data.`}</p>
    <p>{`In this app, there's a one-to-many relationship between lists and reminders (a list can have many reminders), so we'll use `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` and `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` helpers to define this relationship.`}</p>
    <p>{`Import the helpers and update your models to define this relationship:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{1,5-13}"
      }}>{`import { createServer, Model, hasMany, belongsTo } from "miragejs"

export default function () {
  createServer({
    models: {
      list: Model.extend({
        reminders: hasMany(),
      }),

      reminder: Model.extend({
        list: belongsTo(),
      }),
    },

    // rest of server
  })
}
`}</code></pre>
    <p>{`Now Mirage is aware that the `}<inlineCode parentName="p">{`list.reminders`}</inlineCode>{` and `}<inlineCode parentName="p">{`reminder.list`}</inlineCode>{` properties are relationships, so we can start using them within our route handlers.`}</p>
    <p>{`Let's mock out `}<inlineCode parentName="p">{`/api/lists/:id/reminders`}</inlineCode>{` using our new relationships. This endpoint should look up the corresponding List, and respond with all the Reminders belonging to that list.`}</p>
    <p>{`Here's the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/api/lists/:id/reminders", (schema, request) => {
  let listId = request.params.id
  let list = schema.lists.find(listId)

  return list.reminders
})
`}</code></pre>
    <p>{`We use a dynamic segment of `}<inlineCode parentName="p">{`:id`}</inlineCode>{` which corresponds to the List we clicked. We then use that ID to look up the corresponding list in Mirage's data layer. Finally, we return the reminders associated with that list.`}</p>
    <p>{`Copy this route handler into your Mirage server definition and try clicking each list. You shouldn't see an error anymore. But you also won't see any reminders. That's because the reminders we've created so far are not associated with any list – we just created them on their own.`}</p>
    <p>{`To create a reminder for a particular list, we can use our new relationships.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7-11}"
      }}>{`seeds(server) {
  // Unassociated reminders
  server.create("reminder", { text: "Walk the dog" });
  server.create("reminder", { text: "Take out the trash" });
  server.create("reminder", { text: "Work out" });

  let homeList = server.create("list", { name: "Home" });
  server.create("reminder", { list: homeList, text: "Do taxes" });

  let workList = server.create("list", { name: "Work" });
  server.create("reminder", { list: workList, text: "Visit bank" });
}
`}</code></pre>
    <p>{`Now, clicking "Home" should show the "Do taxes" reminder. And if you click All, the app will hit the `}<inlineCode parentName="p">{`/api/reminders`}</inlineCode>{` endpoint which will still return out all the Reminders in our system – both those associated with a List and those that are on their own.`}</p>
    <p>{`Finally, try making a new reminder for a specific list in the UI. Click Work, then add a reminder to "Respond to Jill". If you click around the app, you'll see it's properly associated with the Work list. (It shows up on Work and All, but not Home).`}</p>
    <p>{`This is because our UI already sends a `}<inlineCode parentName="p">{`{ listId }`}</inlineCode>{` when we create a reminder while on a list. To see this, let's add a log to our existing POST route handler to inspect the attrs from the request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3}"
      }}>{`this.post("/api/reminders", (schema, request) => {
  let attrs = JSON.parse(request.requestBody)
  console.log(attrs)

  return schema.reminders.create(attrs)
})
`}</code></pre>
    <p>{`When creating an unassociated todo, the attrs are `}<inlineCode parentName="p">{`{ text: "New todo" }`}</inlineCode>{`, but an associated todo has attrs like `}<inlineCode parentName="p">{`{ text: "Respond to Jill", listId: "2" }`}</inlineCode>{`.`}</p>
    <p>{`When we defined our relationship, Mirage set up special attributes on our models known as foreign keys. These are how it keeps track of which models are associated with each other. Setting a foreign key like `}<inlineCode parentName="p">{`listId`}</inlineCode>{` or `}<inlineCode parentName="p">{`reminderIds: []`}</inlineCode>{` is enough to update a relationship and have it reflected across all your Mirage route handlers.`}</p>
    <p>{`So, we don't have to update our POST handler to accommodate our UI's List feature, since `}<inlineCode parentName="p">{`listId`}</inlineCode>{` is already being passed into `}<inlineCode parentName="p">{`reminders.create()`}</inlineCode>{`.`}</p>
    <p>{`Mirage's association helpers are flexible enough to model nearly any data scenario, making your job easier when it comes time to mock endpoints that deal with relationships.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Mirage lets you define and access relational data`}</li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`belongsTo`}</inlineCode>{` and `}<inlineCode parentName="li">{`hasMany`}</inlineCode>{` helpers to define named relationships on your models`}</li>
      <li parentName="ul">{`Use relationships in route handlers to return a model or collection that's associated with other models`}</li>
      <li parentName="ul">{`Mirage uses simple foreign keys to keep track of relational data`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      