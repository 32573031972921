import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-5--dynamic-segments"
    }}>{`Part 5 – Dynamic segments`}</h1>
    <p>{`If you hover a Reminder, you'll see an "X" icon pop up. If you click it you'll delete the Reminder – except if you look in the console you'll see an error:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Mirage: Your app tried to DELETE '/api/reminders/1, but there was no route defined to handle this request.`}</p>
    </blockquote>
    <p>{`That's because we haven't mocked out this DELETE request. Let's do that now.`}</p>
    <p>{`We'll need to use a dynamic segment in our URL, since the DELETE requests can go to `}<inlineCode parentName="p">{`/api/reminders/1`}</inlineCode>{`, `}<inlineCode parentName="p">{`/api/reminders/2`}</inlineCode>{` and so on.`}</p>
    <p>{`Add this new route handler to your server's `}<inlineCode parentName="p">{`routes()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.delete("/api/reminders/:id", (schema, request) => {
  let id = request.params.id

  return schema.reminders.find(id).destroy()
})
`}</code></pre>
    <p>{`The colon `}<inlineCode parentName="p">{`:`}</inlineCode>{` in `}<inlineCode parentName="p">{`/api/reminders/:id`}</inlineCode>{` is how we denote a dynamic segment in our URL. We can access the runtime value of the segment via `}<inlineCode parentName="p">{`request.params.id`}</inlineCode>{`. We then use `}<inlineCode parentName="p">{`schema`}</inlineCode>{` to find the corresponding reminder, and then call `}<inlineCode parentName="p">{`destroy()`}</inlineCode>{` on it to remove it from Mirage's database.`}</p>
    <p>{`Reload your app and try deleting a reminder. You should see a 204 successful response, and if you navigate to About and back, the data that comes back from the new GET call to `}<inlineCode parentName="p">{`/api/reminders`}</inlineCode>{` won't include your deleted reminder.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`:segmentName`}</inlineCode>{` to define a dynamic segment in the URL for a route handler`}</li>
      <li parentName="ul">{`Access dynamic segments via `}<inlineCode parentName="li">{`request.params.segmentName`}</inlineCode></li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`schema.*`}</inlineCode>{` methods like `}<inlineCode parentName="li">{`destroy()`}</inlineCode>{` to maintain data integrity`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      