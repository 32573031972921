import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-api-requests-in-react-native-with-mirage"
    }}>{`Mock API Requests in React Native with Mirage`}</h1>
    <p>{`Mock your React Native app's API requests so you can develop your app without any backend services.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a quickstart guide for people familiar with Mirage. If you're brand new to Mirage, take a look at the `}<a parentName="p" {...{
          "href": "/docs/getting-started/overview"
        }}>{`Overview`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you have Mirage installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-create-a-server-alongside-your-networking-code"
    }}>{`Step 2: Create a Server alongside your networking code`}</h2>
    <p>{`At the top of `}<inlineCode parentName="p">{`App.js`}</inlineCode>{`, import `}<inlineCode parentName="p">{`Server`}</inlineCode>{` from Mirage, create a server, and start mocking out API endpoints that your code needs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3-21}"
      }}>{`import React from "react"
import { Text, View } from "react-native"
import { createServer } from "miragejs"

if (window.server) {
  server.shutdown()
}

window.server = createServer({
  routes() {
    this.get("/api/movies", () => {
      return {
        movies: [
          { id: 1, name: "Inception", year: 2010 },
          { id: 2, name: "Interstellar", year: 2014 },
          { id: 3, name: "Dunkirk", year: 2017 },
        ],
      }
    })
  },
})

export default function App() {
  let [movies, setMovies] = React.useState([])

  React.useEffect(() => {
    fetch("/api/movies")
      .then((res) => res.json())
      .then((json) => setMovies(json.movies))
  }, [])

  return (
    <View>
      {movies.map((movie) => (
        <Text key={movie.id}>
          {movie.name} ({movie.year})
        </Text>
      ))}
    </View>
  )
}
`}</code></pre>
    <p>{`Note that before we call `}<inlineCode parentName="p">{`createServer()`}</inlineCode>{`, we check to see if one already exists, and if so we shut it down. This is needed so React Native's hot module reloading doesn't cause multiple servers to exist at once.`}</p>
    <p>{`Now, whenever your app makes an API request, Mirage will intercept and respond to it.`}</p>
    <p>{`You're ready to continue developing your React Native app, mocking out your API endpoints with Mirage along the way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      