import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-8--factories"
    }}>{`Part 8 – Factories`}</h1>
    <p>{`Mirage includes a Factory layer to help simplify the process of seeding your Mirage server with realistic, relational data.`}</p>
    <p>{`Currently, if we want to make a Reminder, we need to do something like this in our `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("reminder", { text: "Walk the dog" });
}
`}</code></pre>
    <p>{`Having to always specify every attribute for every model you create can add a lot of boilerplate to your code, especially during testing. It gets even more complicated when relationships are required for your data to be valid.`}</p>
    <p>{`Factories are the perfect place to encode the constraints of your data, making it easier for you to quickly create valid graphs of data. Let's see how they work.`}</p>
    <p>{`For this app, Reminders always have a `}<inlineCode parentName="p">{`text`}</inlineCode>{` property. Let's define a Reminder factory that encodes this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7,22-26}"
      }}>{`import {
  createServer,
  Model,
  hasMany,
  belongsTo,
  RestSerializer,
  Factory,
} from "miragejs"

export default function () {
  createServer({
    models: {
      list: Model.extend({
        reminders: hasMany(),
      }),

      reminder: Model.extend({
        list: belongsTo(),
      }),
    },

    factories: {
      reminder: Factory.extend({
        text: "Reminder text",
      }),
    },

    // ...rest of server
  })
}
`}</code></pre>
    <p>{`First we import `}<inlineCode parentName="p">{`Factory`}</inlineCode>{`, and then define a Reminder factory using the new `}<inlineCode parentName="p">{`factories`}</inlineCode>{` key of our server. We can use `}<inlineCode parentName="p">{`Factory.extend(config)`}</inlineCode>{` to pass in a config object, where the keys of the config object correspond to properties on our models.`}</p>
    <p>{`Now we can update our `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` to just call `}<inlineCode parentName="p">{`server.create('reminder')`}</inlineCode>{`, without passing anything else in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("reminder");
  server.create("reminder");
  server.create("reminder");
}
`}</code></pre>
    <p>{`With this seed data, the app now looks like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a93a97f6ed3e4890cb3c53e15a37d7af/742d3/part-8-static-attrs.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA8UlEQVQoz52T227CMBBE+f9/61sf0haUFEQuOM7FxLe0ZFi7NXJTGigrHe2Dx+OxtV5tyw6B96K99MC2bBFrruljVnPBruqRpAWenjfUS6x3NdJ9gzT/yfzwX4axIGcCR2Wh7Ce0PWHQI/pBE8ZzlAZFLZAtJQyGLk1HmxhjSF7ekCSvqKoD5sV76ZPeNMzyFryTsHbEIBUhYYzFNE2eE+GqJk12n2HjTx9HuqYQvrsKhtNjhgpKKRxY7VPGZg8lbMjw4zuhtRbXit9tSL3iApreTemAuSCV8WtO4977a/8fYxOPTrrni9ycw6Xp/89POQNDWD0K+p+DwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing request",
            "title": "Failing request",
            "src": "/static/a93a97f6ed3e4890cb3c53e15a37d7af/37523/part-8-static-attrs.png",
            "srcSet": ["/static/a93a97f6ed3e4890cb3c53e15a37d7af/e9ff0/part-8-static-attrs.png 180w", "/static/a93a97f6ed3e4890cb3c53e15a37d7af/f21e7/part-8-static-attrs.png 360w", "/static/a93a97f6ed3e4890cb3c53e15a37d7af/37523/part-8-static-attrs.png 720w", "/static/a93a97f6ed3e4890cb3c53e15a37d7af/302a4/part-8-static-attrs.png 1080w", "/static/a93a97f6ed3e4890cb3c53e15a37d7af/07a9c/part-8-static-attrs.png 1440w", "/static/a93a97f6ed3e4890cb3c53e15a37d7af/742d3/part-8-static-attrs.png 2550w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Valid, but not very realistic! We can use function properties to make this a bit more dynamic:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`factories: {
  reminder: Factory.extend({
    text(i) {
      return \`Reminder \${i}\`
    }
  }),
},
`}</code></pre>
    <p>{`Now each reminder has its own unique text:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/045f9/part-8-dynamic-attr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.77777777777778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeElEQVQoz4WTy0rDQBSGfQxdFgUvINhKBe9FXQgFcetziAsRF64E8R0ERVERFBQEF67ciQs3LtSaJs3FNk2Tpk0vSZPfmWmtsZpm4OMcJplv/iGTHuHTAIVXdFYltcR6SrpVu+FfS+nxT6TEPF5SEh6fXxmcpEHJlyATJNVEJmv8olPWFtKFarGG08s7xKaTiM+vYCG5Bl6QYFkVFHQDVqUK1/Pgui7BQ91uMGkXYR3HF7cYHl/CUGwRE4lVpDgeoiTj7f0D2ZyK9iBixwkR5s06Ds+uERmZxMDoDKJTyyShyBJ5RND0eO3eabghQpLw6PyGyQbHEojPJsGlBWgFnR2bitwWtLfDE9o4OLlCbySKvv4Ykc6RhBlyNBsNx0HnaJDkYpAwLRegaBbuH56wsb2H9a1dbO7sgxNklKs29GK5hdWspgXNKAd/5e8HYs5EzqgSaqzSOZqeIxt2QkOE3kN2mVsvU/w7B/FvQr/w759AUILhmehH+gWeXRK7j0gxiAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dyanamic attr",
            "title": "Dyanamic attr",
            "src": "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/37523/part-8-dynamic-attr.png",
            "srcSet": ["/static/e9ed5055f3ae76a9eaa0120f0ebf4518/e9ff0/part-8-dynamic-attr.png 180w", "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/f21e7/part-8-dynamic-attr.png 360w", "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/37523/part-8-dynamic-attr.png 720w", "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/302a4/part-8-dynamic-attr.png 1080w", "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/07a9c/part-8-dynamic-attr.png 1440w", "/static/e9ed5055f3ae76a9eaa0120f0ebf4518/045f9/part-8-dynamic-attr.png 2350w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Libraries like `}<a parentName="p" {...{
        "href": "https://fakerjs.dev/"
      }}>{`faker.js`}</a>{` also work well in function properties to create even higher fidelity data.`}</p>
    <p>{`Combined with `}<inlineCode parentName="p">{`server.createList`}</inlineCode>{`, we can now easily create many Reminders using our Factory definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.createList("reminder", 100);
}
`}</code></pre>
    <p>{`This quickly gives us a big dataset:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d7c9e883759dd5655943110c344d529/97755/part-8-create-list.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA30lEQVQ4y83SbavCIBgG4P3//9a3IEadVh3OjtV8m9t0m+1OBWuMQQ36kHAj8sjlg5qciEDM8Z8/55hRfZrjTD2Zbvg9S6QZwWr9g82eYHe6IvtjyPJR3PowOnwMJ3Pd5ReJsjZojEXTWlS6h1Q6RLj4GqHqgc52GEF/ulAGlFKk6TaEkDOmw8O+25fgIecoRI22bVHVNZSqoLXBMAwht9sQQCabZWDXdSiVCogfEYxrVr4NMlAHaq1RFAx9b2dBvgQsAujvkTuw/wxojAPZ14OUofvaDv0/5ELC2mWvfAfohNpeSUL0twAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Dyanamic list",
            "title": "Dyanamic list",
            "src": "/static/8d7c9e883759dd5655943110c344d529/37523/part-8-create-list.png",
            "srcSet": ["/static/8d7c9e883759dd5655943110c344d529/e9ff0/part-8-create-list.png 180w", "/static/8d7c9e883759dd5655943110c344d529/f21e7/part-8-create-list.png 360w", "/static/8d7c9e883759dd5655943110c344d529/37523/part-8-create-list.png 720w", "/static/8d7c9e883759dd5655943110c344d529/302a4/part-8-create-list.png 1080w", "/static/8d7c9e883759dd5655943110c344d529/07a9c/part-8-create-list.png 1440w", "/static/8d7c9e883759dd5655943110c344d529/97755/part-8-create-list.png 2536w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`But what if we want to override specific properties that we've defined on our Factory? We can always do that by passing attributes into `}<inlineCode parentName="p">{`server.create()`}</inlineCode>{`. These attributes will override anything defined on our base factories.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  // Create a specific reminder
  server.create('reminder', { text: 'Walk the dog' })

  // Create 5 more generic reminders
  server.createList("reminder", 5);
}
`}</code></pre>
    <p>{`Here's the result:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bd11abc6b6ddfa52d916b33a0ea77b50/742d3/part-8-mix-static-dynamic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.44444444444446%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAABYlAAAWJQFJUiTwAAABNUlEQVQ4y62UDW+DIBCG+/9/27KtyZYsy9IvOxVqBUEX49eNO0W7KnazI3lzynkPLwisDqEEq30gKB65Ao8l4F3kXLI1VqvrxMfhBE+vG3jbBPC+59Q/p2voCLj9PBuYD9tjRO8ec8vp0HbufAEs1oCtaRqojZpOtVXdChuPU6q5ZIyAJ5FCln3Bw+MantcvFIOQ9YNgq6qKIn678+MZoInsrKmwKApSnudQlmUPtCJgfAvot0B0kCQKlNIGVo1gfwPGmhxxHoGUiVmvegRb7BDXcsrdIiC602kGWHsfsJsyM1MWBjoFW+RQCAlKp/8ANA7xR0izhqmZ8lz71T4MI0UOcdq4ZfB5SjgoN4O7TwoddknRC+NBgUMm19eE4iewhdqE7GRvFDkr520zJMTNO3DQ+Ar7BpsZCJ59SpS+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Mixing static and dynamic attrs",
            "title": "Mixing static and dynamic attrs",
            "src": "/static/bd11abc6b6ddfa52d916b33a0ea77b50/37523/part-8-mix-static-dynamic.png",
            "srcSet": ["/static/bd11abc6b6ddfa52d916b33a0ea77b50/e9ff0/part-8-mix-static-dynamic.png 180w", "/static/bd11abc6b6ddfa52d916b33a0ea77b50/f21e7/part-8-mix-static-dynamic.png 360w", "/static/bd11abc6b6ddfa52d916b33a0ea77b50/37523/part-8-mix-static-dynamic.png 720w", "/static/bd11abc6b6ddfa52d916b33a0ea77b50/302a4/part-8-mix-static-dynamic.png 1080w", "/static/bd11abc6b6ddfa52d916b33a0ea77b50/07a9c/part-8-mix-static-dynamic.png 1440w", "/static/bd11abc6b6ddfa52d916b33a0ea77b50/742d3/part-8-mix-static-dynamic.png 2550w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let's turn to our List model. We'll start by defining a Factory for it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{2-6}"
      }}>{`factories: {
  list: Factory.extend({
    name(i) {
      return \`List \${i}\`;
    },
  }),

  reminder: Factory.extend({
    text(i) {
      return \`Reminder \${i}\`;
    },
  }),
},
`}</code></pre>
    <p>{`Now if we want to make generic Lists and Reminders, we don't need to specify any attributes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("list", {
    reminders: server.createList("reminder", 5),
  });
}
`}</code></pre>
    <p>{`As you can see, passing Reminders into our List on creation still lets us create valid graphs of relational data:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/871429eaa87b101a3ee4e1de61c3eb06/0f7bd/part-8-lists.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB3UlEQVQ4y6WTu2sUURSH8xeIVRQkqFGiZE0M0UA0aaKCYmUl2Cj2InZaiI1WNoJoMCGChiAGER+FbyxSaStqENFsNsmuyezM7rwfm7mfd+6yYZwsGnXgx5lzzpyPc+be01L4UWW2VKFhZxZ0vs+X+Tanqfck/iel61vSwQXNxvQExbKjoLMy/rWQgA3p68rOFH9VGpZYBczLREl3mXr/gfOXrnLx8jXOXbjCs9dTxIAb1PCjGMePMB1fKlCy3ICiZpHPdpiMppkh9x+9ZGtukI6eIdZv6mZ4dBzPc/n4aZrP018wjArZR6s4qtMmwIAHT9/Q3X+Y3oGjtO3o59bYhCqqVk1s2yEMI4T0hRDEQqjckuHICX/XYec+OvccoHXzbm6M3FFFpmnhBxHLcaz8BCjWCmzP7Se39yCtW3q4OXq3PlZZx3Z9otryfwJH6sCybvwbcPLxK7btGqCr7xAb23vVoTQ6tByPMKr9HfDew+e0dfSxvWuQdRt2cn34tiqyLFv+w5A4Fk1POZ895SSwaHi8ePuO46fOcOL0WY4cO8nE5BNVZMvuHC/AkWOn5cpYqdk9TN/0ucVEJvNLFo3u61q9JdlNWQFm16eRXMsep+tWVi8LSqvQJLbqmxTjJ9Vo3FFDEvfFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Lists",
            "title": "Lists",
            "src": "/static/871429eaa87b101a3ee4e1de61c3eb06/37523/part-8-lists.png",
            "srcSet": ["/static/871429eaa87b101a3ee4e1de61c3eb06/e9ff0/part-8-lists.png 180w", "/static/871429eaa87b101a3ee4e1de61c3eb06/f21e7/part-8-lists.png 360w", "/static/871429eaa87b101a3ee4e1de61c3eb06/37523/part-8-lists.png 720w", "/static/871429eaa87b101a3ee4e1de61c3eb06/302a4/part-8-lists.png 1080w", "/static/871429eaa87b101a3ee4e1de61c3eb06/07a9c/part-8-lists.png 1440w", "/static/871429eaa87b101a3ee4e1de61c3eb06/0f7bd/part-8-lists.png 2540w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`What if we wanted to make it even easier to create a List with many reminders? We can use the `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook on our List Factory, passing in our newly created list into any new Reminders we create:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7-9}"
      }}>{`factories: {
  list: Factory.extend({
    name(i) {
      return \`List \${i}\`;
    },

    afterCreate(list, server) {
      server.createList('reminder', 5, { list })
    }
  }),

  reminder: Factory.extend({
    text(i) {
      return \`Reminder \${i}\`;
    },
  }),
},
`}</code></pre>
    <p>{`Now with just a `}<inlineCode parentName="p">{`server.create('list')`}</inlineCode>{`, we can have a valid List with 5 Reminder models, like we had before:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("list")
}
`}</code></pre>
    <p>{`But what if we wanted to bring back some of our more curated, realistic data, either because we're testing something specific or because we want less generic data in development?`}</p>
    <p>{`Copy this seeding logic in:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("list", {
    name: "Home",
    reminders: [server.create("reminder", { text: "Do taxes" })],
  });

  server.create("list")
}
`}</code></pre>
    <p>{`If we use this logic, we'll see that our `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook is triggered for both lists, and now our Home list has 5 randomly generated reminders along our specific "Do taxes" one:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e1a2edff44543b0aa9db1d5ed321f1a8/742d3/part-8-after-create.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.11111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB7klEQVQ4y5WUzUsbURTFXXbTjYuGgogaS0AtVaqiiSCa/Ae67aJ0pytX6kJwY1dddFMwC4NgFUsMdSFqKyK0dFG6a0Up+BE1zedMksnMJJM4M6f3vTgliYM1Dw537h3ejzPv3TsNl/EsLmIZsBimeBYRuE6vUjj/k+b1/8naz9RQWYymZEgFEzFBIZiIC6qfXJbBZxGRx/NotSyYFTkwTC9iooKv339idv4N5hbeYmr2NXb3v0EHoGrXKBQNKPkSJKVA0rhyqkYmpCooBzI3glTE6sY2HrV0o9nVjweNTrzzLyOvqvh1eISj49/IZLKoXcm0TIZqgeQwmdUQ2jqA2zeOgdExtD8bQWAlyDdJORmKoqJUKsGk3DRNGCZ/QkK0A5LDlKThw+ZnuLqH0dXnhaO1B4tL7zlQlpV/jhjMElt3AItY//gJrR2D6OwdhYM+3QKmBBGGYVTB7gHUOLCt00NAbxVQIKBOwDodWkD3baCYhq7r9QPZGTq7hvC034fHzufwB1bLN5kUcE1AxqjrDNdCO2h60kdQDx46XLxtypci8xu1Wwm7tmGNHU+r2PvyAy8npvFqcgbjLyYQ3NyFThxJziOn1OimFk3aNLaVXCUkRGns2OjFRZXnzD0fOZuxYzXb0assWgpXzGk9P4e/fyjXqju7ztMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "After create",
            "title": "After create",
            "src": "/static/e1a2edff44543b0aa9db1d5ed321f1a8/37523/part-8-after-create.png",
            "srcSet": ["/static/e1a2edff44543b0aa9db1d5ed321f1a8/e9ff0/part-8-after-create.png 180w", "/static/e1a2edff44543b0aa9db1d5ed321f1a8/f21e7/part-8-after-create.png 360w", "/static/e1a2edff44543b0aa9db1d5ed321f1a8/37523/part-8-after-create.png 720w", "/static/e1a2edff44543b0aa9db1d5ed321f1a8/302a4/part-8-after-create.png 1080w", "/static/e1a2edff44543b0aa9db1d5ed321f1a8/07a9c/part-8-after-create.png 1440w", "/static/e1a2edff44543b0aa9db1d5ed321f1a8/742d3/part-8-after-create.png 2550w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can update our `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` logic to first check if our newly created List already has Reminders passed into it, and only create default reminders if it doesn't:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7-11}"
      }}>{`factories: {
  list: Factory.extend({
    name(i) {
      return \`List \${i}\`;
    },

    afterCreate(list, server) {
      if (!list.reminders.length) {
        server.createList('reminder', 5, { list })
      }
    }
  }),

  reminder: Factory.extend({
    text(i) {
      return \`Reminder \${i}\`;
    },
  }),
},
`}</code></pre>
    <p>{`Now our same seeds logic lets us easily create a curated "Home" list with only the "Do taxes" reminder we passed in, while our plain call to `}<inlineCode parentName="p">{`server.create('list')`}</inlineCode>{` quickly scaffolds out a generic list with five reminders for us:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf6d7ec61395f191a87dcdf1b8e27003/9d53e/part-8-better-after-create.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.22222222222223%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB2klEQVQ4y62Tu2sUURSH8wdYWC2IBjUaU6yJS3ygxhSJNtra2ohVEDsfIDZa2VgJro8Q8IlRJGIRMbGJlWAlqEEw2Zg1m5iZnZ3H3dnZ2ex8zj1xl81GCQYv/DjnnuF8nHPnnJbZBZvv8wVqNjOXZ/qHyVTWEF/H11JjfktjcM7wsP2q2KmsGcdsvs1qsMV0DNd2JleIZYkysRph2gpQf5zPF5l4/5HzV65z+eoNzl24xuj4OyLADyoEYRVVCnFVUJdXDMgZLjPNFerWDKfM05Extid72ZXqY+OWLtKDj/CLRT59nuTL5Fcsq0DzMQpKKv0DMOD5q7ekDh2nu+cErR0HuTP0WJJs28XzFOUwlIqjKKIaicei5ckz/KXCN7R39ZLc209i6x5u3b2/DHRcSkEovobVpM+awLbkYZL7jpLYliJ978FyW2Y+fq8SlaWlFdB1A828hRJg9f8BvfUAh1+OsbPzCJ0HjrGprZvbgw/rLbvKJ6z8Q8tmDHzy4jWb2/ezY3cPGxId3EwPSZL84XJYhzSeRUvJHK8A6sCC5TM+8YHTAxc5c/YSJ08N8GxkNB4PcDwfT5Wkyrok5seD7awe7Nol+9ORtcuZXrw5Su6Z3ysnyq1W8+r9AldajFx6tXTYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Improved after create",
            "title": "Improved after create",
            "src": "/static/cf6d7ec61395f191a87dcdf1b8e27003/37523/part-8-better-after-create.png",
            "srcSet": ["/static/cf6d7ec61395f191a87dcdf1b8e27003/e9ff0/part-8-better-after-create.png 180w", "/static/cf6d7ec61395f191a87dcdf1b8e27003/f21e7/part-8-better-after-create.png 360w", "/static/cf6d7ec61395f191a87dcdf1b8e27003/37523/part-8-better-after-create.png 720w", "/static/cf6d7ec61395f191a87dcdf1b8e27003/302a4/part-8-better-after-create.png 1080w", "/static/cf6d7ec61395f191a87dcdf1b8e27003/07a9c/part-8-better-after-create.png 1440w", "/static/cf6d7ec61395f191a87dcdf1b8e27003/9d53e/part-8-better-after-create.png 2542w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As one last refactoring step, there's an even better approach to only creating generic Reminders associated with our List when we want to: Factory Traits.`}</p>
    <p>{`Traits let us group attributes and `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` logic together under a name, so they're only invoked when we use that name. Let's add a `}<inlineCode parentName="p">{`withReminders`}</inlineCode>{` trait to our List factory.`}</p>
    <p>{`First we'll import `}<inlineCode parentName="p">{`trait`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{8}"
      }}>{`import {
  createServer,
  Model,
  hasMany,
  belongsTo,
  RestSerializer,
  Factory,
  trait,
} from "miragejs"
`}</code></pre>
    <p>{`and then we'll move our `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` logic to a `}<inlineCode parentName="p">{`withReminders`}</inlineCode>{` trait on our List Factory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7-11}"
      }}>{`factories: {
  list: Factory.extend({
    name(i) {
      return \`List \${i}\`;
    },

    withReminders: trait({
      afterCreate(list, server) {
        server.createList('reminder', 5, { list })
      }
    })
  }),

  reminder: Factory.extend({
    text(i) {
      return \`Reminder \${i}\`;
    },
  }),
},
`}</code></pre>
    <p>{`Note that we removed the `}<inlineCode parentName="p">{`list.reminders.length`}</inlineCode>{` check since this logic will now only be invoked if we explicitly call the trait.`}</p>
    <p>{`If you save and check your app, you'll see that our second generic List no longer has any associated reminders. Let's update our `}<inlineCode parentName="p">{`seeds`}</inlineCode>{` hook to invoke our new trait for that list:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{7}"
      }}>{`seeds(server) {
  server.create("list", {
    name: "Home",
    reminders: [server.create("reminder", { text: "Do taxes" })],
  });

  server.create("list", "withReminders")
}
`}</code></pre>
    <p>{`And now our UI is right back to where it was! But we've improved our List Factory by making it less surprising – the base case only creates a single List model.`}</p>
    <p>{`As we'll see in the next section, good Factory definitions are important for writing good tests.`}</p>
    <p>{`Factories have `}<a parentName="p" {...{
        "href": "/docs/main-concepts/factories"
      }}>{`even more features`}</a>{` that give you flexible ways to create data scenarios, so you can do things like quickly switch your development environment from an anonymous user to an authenticated user, or set up just the data you need within a test.`}</p>
    <p>{`Let's revert our seeds logic to our curated data set for now:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("reminder", { text: "Walk the dog" });
  server.create("reminder", { text: "Take out the trash" });
  server.create("reminder", { text: "Work out" });

  server.create("list", {
    name: "Home",
    reminders: [server.create("reminder", { text: "Do taxes" })],
  });

  server.create("list", {
    name: "Work",
    reminders: [server.create("reminder", { text: "Visit bank" })],
  });
}
`}</code></pre>
    <p>{`Using Factories well is one of the best ways to take advantage of all the capabilities of Mirage's data layer.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Factories are like blueprints that help you easily create graphs of relational data`}</li>
      <li parentName="ul">{`You can use static values or functions as attributes`}</li>
      <li parentName="ul">{`You can override Factory defaults wherever you call `}<inlineCode parentName="li">{`server.create()`}</inlineCode></li>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`afterCreate`}</inlineCode>{` hook to perform additional logic, like automatically creating related data for a model`}</li>
      <li parentName="ul">{`Use Traits to group related attribute and `}<inlineCode parentName="li">{`afterCreate`}</inlineCode>{` logic, and to keep your factories composable`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      