import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "simulating-cookie-responses"
    }}>{`Simulating cookie responses`}</h1>
    <p>{`Although Mirage allows setting headers in a response, the
XMLHttpRequest spec explicitly
`}<a parentName="p" {...{
        "href": "http://www.w3.org/TR/XMLHttpRequest/#the-getresponseheader()-method"
      }}>{`forbids access`}</a>{`
to the `}<inlineCode parentName="p">{`Set-Cookie`}</inlineCode>{` and `}<inlineCode parentName="p">{`Set-Cookie2`}</inlineCode>{` headers. As a result, Mirage
responses cannot set cookies via headers.`}</p>
    <p>{`However, you can simulate receiving cookies from an ajax
call at the browser level by setting them in a route
function handler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/users/login", (schema) => {
  // log in for 24 hours
  let now = new Date()
  let cookieExpiration = new Date(now.getTime() + 24 * 3600 * 1000)
  document.cookie = \`remember_me=cookie-content-here; domain=.dev-domain; path=/; expires=\${cookieExpiration.toUTCString()};\`

  return schema.users.find(1)
})
`}</code></pre>
    <p>{`Your JavaScript client code will now have access to any cookies set
using `}<inlineCode parentName="p">{`document.cookie`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      