import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "relationships"
    }}>{`Relationships`}</h1>
    <p>{`Once you've defined your models, you can define relationships between them using the `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` and `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` helpers. Each helper adds some dynamic methods to your model.`}</p>
    <h2 {...{
      "id": "belongsto"
    }}>{`belongsTo`}</h2>
    <p>{`To define a to-one relationship, import the `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` helper and define a new property on a model that points to another model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo } from "miragejs"

createServer({
  models: {
    blogPost: Model.extend({
      author: belongsTo(),
    }),

    author: Model,
  },
})
`}</code></pre>
    <p>{`This defines a `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` relationship to an `}<inlineCode parentName="p">{`Author`}</inlineCode>{` model.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` helper adds several new properties and methods to your models.`}</p>
    <p>{`In this case, our `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` model now gains an `}<inlineCode parentName="p">{`authorId`}</inlineCode>{` property, as well as some methods for working with the associated `}<inlineCode parentName="p">{`author`}</inlineCode>{` model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`blogPost.authorId // 1
blogPost.authorId = 2 // updates the relationship
blogPost.author // Author instance
blogPost.author = anotherAuthor
blogPost.newAuthor(attrs) // new unsaved author
blogPost.createAuthor(attrs) // new saved author (updates blogPost.authorId in memory only)
`}</code></pre>
    <p>{`Note that that the `}<inlineCode parentName="p">{`createAuthor`}</inlineCode>{` method will create a new author and immediately save it to the `}<inlineCode parentName="p">{`db`}</inlineCode>{`, but the blog post's foreign key is updated `}<em parentName="p">{`on this instance only`}</em>{`, and is not immediately persisted to the database. So `}<inlineCode parentName="p">{`blogPost.authorId`}</inlineCode>{` will be updated in memory, but if you were to fetch the `}<inlineCode parentName="p">{`blogPost`}</inlineCode>{` from the `}<inlineCode parentName="p">{`db`}</inlineCode>{` again, the relationship would not be persisted.`}</p>
    <p>{`To persist the new foreign key, you would need to call `}<inlineCode parentName="p">{`blogPost.save()`}</inlineCode>{` after creating the new author.`}</p>
    <h2 {...{
      "id": "hasmany"
    }}>{`hasMany`}</h2>
    <p>{`To define a to-many relationship, use the `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` helper:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, hasMany } from "miragejs"

createServer({
  models: {
    blogPost: Model.extend({
      comments: hasMany(),
    }),

    comment: Model,
  },
})
`}</code></pre>
    <p>{`This helper adds a `}<inlineCode parentName="p">{`commentIds`}</inlineCode>{` property to the `}<inlineCode parentName="p">{`blogPost`}</inlineCode>{` model, as well as some methods for working with the associated `}<inlineCode parentName="p">{`comments`}</inlineCode>{` collection:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`blogPost.commentIds // [1, 2, 3]
blogPost.commentIds = [2, 3] // updates the relationship
blogPost.comments // array of related comments
blogPost.comments = [comment1, comment2] // updates the relationship
blogPost.newComment(attrs) // new unsaved comment
blogPost.createComment(attrs) // new saved comment (comment.blogPostId is set)
`}</code></pre>
    <h2 {...{
      "id": "one-to-one"
    }}>{`One to one`}</h2>
    <p>{`A one-to-one relationship can be defined using the `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` helper on two models:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo } from "miragejs"

createServer({
  models: {
    supplier: Model.extend({
      account: belongsTo(),
    }),

    account: Model.extend({
      supplier: belongsTo(),
    }),
  },
})
`}</code></pre>
    <p>{`By default, Mirage will mark these two relationships as inverses of each other and therefore be able to keep them in sync as they change. For example, if `}<inlineCode parentName="p">{`supplierA.account`}</inlineCode>{` was `}<inlineCode parentName="p">{`accountB`}</inlineCode>{`, `}<inlineCode parentName="p">{`accountB.supplier`}</inlineCode>{` would point back to `}<inlineCode parentName="p">{`supplierA`}</inlineCode>{`. And if `}<inlineCode parentName="p">{`supplierA.account`}</inlineCode>{` was set to `}<inlineCode parentName="p">{`null`}</inlineCode>{`, the `}<inlineCode parentName="p">{`accountB.supplier`}</inlineCode>{` side of the relationship would also be set to `}<inlineCode parentName="p">{`null`}</inlineCode>{` to keep the relationships in sync.`}</p>
    <h2 {...{
      "id": "one-to-many"
    }}>{`One to many`}</h2>
    <p>{`A one-to-many relationship can be defined using the `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` helper on one model and the `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` helper on an inverse:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    user: Model.extend({
      comments: hasMany(),
    }),

    comment: Model.extend({
      user: belongsTo(),
    }),
  },
})
`}</code></pre>
    <p>{`Mirage will mark these as inverses of each other, and keep them in sync as they change.`}</p>
    <h2 {...{
      "id": "many-to-many"
    }}>{`Many to many`}</h2>
    <p>{`A many-to-many relationship can be defined using the `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` helper on two models:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, hasMany } from "miragejs"

createServer({
  models: {
    blogPost: Model.extend({
      tags: hasMany(),
    }),

    tag: Model.extend({
      blogPosts: hasMany(),
    }),
  },
})
`}</code></pre>
    <p>{`Mirage will mark these as inverses of each other, and keep them in sync as they change.`}</p>
    <h2 {...{
      "id": "association-options"
    }}>{`Association options`}</h2>
    <p>{`The following options are available to customize your `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` and `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` relationship definitions.`}</p>
    <h3 {...{
      "id": "modelname"
    }}>{`modelName`}</h3>
    <p>{`If your associations model has a different name than the association itself, you can specify the `}<inlineCode parentName="p">{`modelName`}</inlineCode>{` on the association.`}</p>
    <p>{`For example,`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    user: Model,

    annotation: Model,

    blogPost: Model.extend({
      author: belongsTo("user"),
      comments: hasMany("annotation"),
    }),
  },
})
`}</code></pre>
    <p>{`would add all the named `}<inlineCode parentName="p">{`author`}</inlineCode>{` and `}<inlineCode parentName="p">{`comment`}</inlineCode>{` methods as listed above, but use the `}<inlineCode parentName="p">{`User`}</inlineCode>{` and `}<inlineCode parentName="p">{`Annotation`}</inlineCode>{` models for the actual relationships.`}</p>
    <h3 {...{
      "id": "inverse"
    }}>{`inverse`}</h3>
    <p>{`Often, relationships can be inverses of each other.`}</p>
    <p>{`For example, say we had the following two models:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    blogPost: Model.extend({
      comments: hasMany(),
    }),

    comment: Model.extend({
      blogPost: belongsTo(),
    }),
  },
})
`}</code></pre>
    <p>{`In this case, `}<inlineCode parentName="p">{`blogPost.comments`}</inlineCode>{` would point to a collection of Comment models, and each one of those Comment models would have a `}<inlineCode parentName="p">{`comment.blogPost`}</inlineCode>{` relationship that pointed back to the original post.`}</p>
    <p>{`Mirage can often infer that two relationships on two different models are inverses of each other, but sometimes you'll need to be explicit. This typically happens if a model has two relationships that point to the same model type.`}</p>
    <p>{`For example, suppose we had the following schema:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    user: Model.extend({
      blogPosts: hasMany(),
    }),

    blogPost: Model.extend({
      author: belongsTo("user"),
      reviewer: belongsTo("user"),
    }),
  },
})
`}</code></pre>
    <p>{`In this case, Mirage doesn't know which relationship (`}<inlineCode parentName="p">{`blogPost.author`}</inlineCode>{` or `}<inlineCode parentName="p">{`blogPost.reviewer`}</inlineCode>{`) should by synchronized with the `}<inlineCode parentName="p">{`user.blogPosts`}</inlineCode>{` collection. So, you can specify which one is the inverse by using the `}<inlineCode parentName="p">{`inverse`}</inlineCode>{` option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    user: Model.extend({
      blogPosts: hasMany(),
    }),

    blogPost: Model.extend({
      author: belongsTo("user", { inverse: "blogPosts" }),
      reviewer: belongsTo("user", { inverse: null }),
    }),
  },
})
`}</code></pre>
    <p>{`Now, if a blog post is added to `}<inlineCode parentName="p">{`user.blogPosts`}</inlineCode>{`, that post's `}<inlineCode parentName="p">{`author`}</inlineCode>{` will be correctly updated.`}</p>
    <h3 {...{
      "id": "polymorphic"
    }}>{`polymorphic`}</h3>
    <p>{`You can specify whether an association is a polymorphic association by passing `}<inlineCode parentName="p">{`{ polymorphic: true }`}</inlineCode>{` as an option.`}</p>
    <p>{`For example, say you have `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` and `}<inlineCode parentName="p">{`Picture`}</inlineCode>{` models that can both have a `}<inlineCode parentName="p">{`Comment`}</inlineCode>{`. Here's how the model definitions could look:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    blogPost: Model.extend({
      comments: hasMany(),
    }),

    picture: Model.extend({
      comments: hasMany(),
    }),

    comment: Model.extend({
      commentable: belongsTo({ polymorphic: true }),
    }),
  },
})
`}</code></pre>
    <p>{`We give `}<inlineCode parentName="p">{`Comment`}</inlineCode>{` models a `}<inlineCode parentName="p">{`commentable`}</inlineCode>{` polymorphic relationships, since the model being commented on could either be a `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` or a `}<inlineCode parentName="p">{`Picture`}</inlineCode>{`. Note that `}<inlineCode parentName="p">{`commentable`}</inlineCode>{` doesn't have a type, as there's no validation done on which types of models can exist on that relationship.`}</p>
    <p>{`Polymorphic associations have slightly different method signatures for their foreign keys and build/create methods.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let comment = server.schema.comments.create({ text: "foo" })

comment.buildCommentable("blog-post", { title: "Lorem Ipsum" })
comment.createCommentable("blog-post", { title: "Lorem Ipsum" })

// getter
comment.commentableId // { id: 1, type: 'blog-post' }

// setter
comment.commentableId = { id: 2, type: "picture" }
`}</code></pre>
    <p>{`Has-many relationships can also be polymorphic:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, hasMany } from "miragejs"

createServer({
  models: {
    car: Model,

    watch: Model

    user: Model.extend({
      things: hasMany({ polymorphic: true })
    }),
  },
})
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let user = server.schema.users.create({ name: "Sam" });

user.buildThing('car', { attrs });
user.createThing('watch', { attrs });

// getter
user.thingIds; // [ { id: 1, type: 'car' }, { id: 3, type: 'watch' }, ... ]

// setter
user.thingIds = [ { id: 2, type: 'watch' }, ... ];
`}</code></pre>
    <hr></hr>
    <p>{`Be sure to check out the Schema, Model and Collection API docs to learn about all the available ORM methods.`}</p>
    <p>{`We'll also cover Serializers in these guides, where you'll learn how to customize the serialized forms of your models and collections to match your production API.`}</p>
    <p>{`Next, let's take a look at Factories, which leverage your Model definitions to make it easy to create graphs of relational data.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      