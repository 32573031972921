import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "shorthands"
    }}>{`Shorthands`}</h1>
    <p>{`APIs have become more standardized, so Mirage has the concept of `}<em parentName="p">{`Shorthands`}</em>{` to make it easy for you to write conventional endpoints. Shorthands can replace many of your custom route handlers, dramatically simplifying your server definition.`}</p>
    <p>{`For example, this function route handler`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies", (schema, request) => {
  return schema.movies.all()
})
`}</code></pre>
    <p>{`is pretty standard: it responds to a URL path with a collection of the same name.`}</p>
    <p>{`The Shorthand form of this is`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies")
`}</code></pre>
    <p>{`This is a complete route handler. It infers the model name from the last part of the URL, and returns the corresponding collection.`}</p>
    <p>{`Returning a single movie by ID is just as easy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/movies/:id")
`}</code></pre>
    <p>{`There are also Shorthands for creating and editing data. For example, this function route handler creates a new movie:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/movies", (schema, request) => {
  let attrs = JSON.parse(request.requestBody).movie

  return schema.movies.create(attrs)
})
`}</code></pre>
    <p>{`It's also pretty standard: it creates a new model using the attributes from the request payload. The equivalent Shorthand is`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/movies")
`}</code></pre>
    <p>{`You can see the full list of available Shorthands below. Shorthands use default status codes based on the HTTP verb:`}</p>
    <ul>
      <li parentName="ul">{`GET, PATCH/PUT and DEL are 200`}</li>
      <li parentName="ul">{`POST is 201`}</li>
    </ul>
    <h2 {...{
      "id": "get-shorthands"
    }}>{`GET Shorthands`}</h2>
    <p>{`Fetching a collection:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.get("/contacts") // finds type by singularizing url
this.get("/contacts", "users") // optionally specify the collection as second param

// equivalent
this.get("/contacts", (schema) => {
  return schema.contacts.all() // users in the second case
})
`}</code></pre>
    <p>{`Fetching a model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.get("/contacts/:id") // finds type by singularizing url
this.get("/contacts/:id", "user") // optionally specify the type as second param

// equivalent
this.get("/contacts/:id", (schema, request) => {
  let id = request.params.id

  return schema.contacts.find(id) // users in the second case
})
`}</code></pre>
    <p>{`Fetching multiple models by ID (for example, `}<inlineCode parentName="p">{`GET /contacts?ids=1,3`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.get("/contacts", { coalesce: true })
this.get("/contacts", "users", { coalesce: true })

// equivalent
this.get("/contacts", ({ contacts }, request) => {
  let ids = request.queryParams.ids

  return contacts.find(ids) // users in the second case
})
`}</code></pre>
    <h2 {...{
      "id": "post-shorthands"
    }}>{`POST Shorthands`}</h2>
    <p>{`Creating a resource:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.post("/contacts") // finds type by singularizing url
this.post("/contacts", "user") // optionally specify the type as second param

// equivalent
this.post("/contacts", function (schema, request) {
  let attrs = this.normalizedRequestAttrs()

  return schema.contacts.create(attrs)
})
`}</code></pre>
    <p>{`For this POST shorthand to work, Mirage needs to know the format of the JSON payload your app sends along with the request, so that it can insert the appropriate data into the database. See `}<a parentName="p" {...{
        "href": "/api/classes/serializer/#normalize"
      }}>{`the note on normalize`}</a>{` in the Serializer docs for more information.`}</p>
    <h2 {...{
      "id": "patchput-shorthands"
    }}>{`PATCH/PUT Shorthands`}</h2>
    <p>{`Updating a resource:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand (these also work with this.put)
this.patch("/contacts/:id") // finds type by singularizing url
this.patch("/contacts/:id", "user") // optionally specify the type as second param

// equivalent
this.patch("/contacts/:id", function (schema, request) {
  let id = request.params.id
  let attrs = this.normalizedRequestAttrs()

  return schema.contacts.find(id).update(attrs)
})
`}</code></pre>
    <p>{`For this PATCH shorthand to work, Mirage needs to know the format of the JSON payload your app sends along with the request, so that it can insert the appropriate data into the database. See `}<a parentName="p" {...{
        "href": "/api/classes/serializer/#normalize"
      }}>{`the note on normalize`}</a>{` in the Serializer docs for more information.`}</p>
    <h2 {...{
      "id": "delete-shorthands"
    }}>{`DELETE Shorthands`}</h2>
    <p>{`Destroying a resource:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.del("/contacts/:id") // finds type by singularizing url
this.del("/contacts/:id", "user") // optionally specify the type as second param

// equivalent
this.del("/contacts/:id", (schema, request) => {
  let id = request.params.id

  schema.contacts.find(id).destroy()
})
`}</code></pre>
    <p>{`Destroying a resource and related models:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Shorthand
this.del("/contacts/:id", ["contact", "addresses"])

// equivalent
this.del("/contacts/:id", ({ contacts }, request) => {
  let id = request.params.id
  let contact = contacts.find(id)

  contact.addresses.destroy()
  contact.destroy()
})
`}</code></pre>
    <p>{`To use this Shorthand, you must have the appropriate `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{`/`}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` relationships defined in your data layer.`}</p>
    <h2 {...{
      "id": "resource-helper"
    }}>{`Resource helper`}</h2>
    <p>{`The `}<em parentName="p">{`resource`}</em>{` helper lets you define multiple Shorthands for a given resource:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Resource helper usage
this.resource("contacts")

// Shorthands defined
this.get("/contacts")
this.get("/contacts/:id")
this.post("/contacts")
this.patch("/contacts/:id") // and this.put('/contacts/:id')
this.del("/contacts/:id")
`}</code></pre>
    <p>{`You can also whitelist which Shorthands will be defined using the `}<em parentName="p">{`only`}</em>{` option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource("contacts", { only: ["index", "show"] })

// Shorthands defined
this.get("/contacts")
this.get("/contacts/:id")
`}</code></pre>
    <p>{`or which route handlers shouldn't be defined using `}<em parentName="p">{`except`}</em>{` option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource("contacts", { except: ["update"] })

// Shorthands defined
this.get("/contacts")
this.get("/contacts/:id")
this.post("/contacts")
this.del("/contacts/:id")
`}</code></pre>
    <p>{`If your route path and collection names do not match, you can define a relative or absolute path using the `}<em parentName="p">{`path`}</em>{` option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.resource("blog-posts", { path: "/posts" })

// Shorthands defined
this.get("/posts", "blog-posts")
this.get("/posts/:id", "blog-posts")
this.post("/posts", "blog-posts")
this.put("/posts/:id", "blog-posts")
this.patch("/posts/:id", "blog-posts")
this.del("/posts/:id", "blog-posts")
`}</code></pre>
    <p>{`Here is the full reference of the actions' names you can pass to the `}<em parentName="p">{`only`}</em>{` / `}<em parentName="p">{`except`}</em>{` options and the Shorthands they stand for:`}</p>
    <pre><code parentName="pre" {...{}}>{`Action   |  Shorthand
------------------------------
index    | this.get('/contacts')
show     | this.get('/contacts/:id')
create   | this.post('/contacts')
update   | this.patch('contacts/:id') (or this.put)
delete   | this.del('/contacts/:id')
`}</code></pre>
    <hr></hr>
    <p>{`Shorthands are a key part of staying productive in your frontend codebase, but they only work so well because Mirage has a data layer that's aware of your application's domain model.`}</p>
    <p>{`We'll cover the essential part of the data layer — the database — in the next section.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      