import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "develop-a-nextjs-app-against-a-shared-mirage-server"
    }}>{`Develop a Next.js App against a Shared Mirage Server`}</h1>
    <p>{`Once you're ready to `}<a parentName="p" {...{
        "href": "/docs/workflow-tips/#sharing-your-server-between-development-and-testing"
      }}>{`centralize your Mirage server and share it between development and testing`}</a>{`, follow these steps.`}</p>
    <p><em parentName="p">{`Note: At this time, Mirage only runs in the browser, meaning it will not mock out any server-side network calls your Next.js app makes via hooks like getServerSideProps.`}</em></p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-shared-server"
    }}>{`Step 2: Define your shared server`}</h2>
    <p>{`A common place to define your shared server is `}<inlineCode parentName="p">{`mirage.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// mirage.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    models: {
      movie: Model,
    },

    seeds(server) {
      server.create("movie", { name: "Inception", year: 2010 })
      server.create("movie", { name: "Interstellar", year: 2014 })
      server.create("movie", { name: "Dunkirk", year: 2017 })
    },

    routes() {
      this.namespace = "api"

      this.get("/movies", (schema) => {
        return schema.movies.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <h2 {...{
      "id": "step-3-start-your-server-in-development"
    }}>{`Step 3: Start your server in development`}</h2>
    <p>{`Open your Next.js app's initialization file (`}<inlineCode parentName="p">{`pages/_app.js`}</inlineCode>{`), import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, and call it in the development environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{2-6}"
      }}>{`// pages/_app.js
import { makeServer } from "../mirage"

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" })
}

function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />
}

export default MyApp
`}</code></pre>
    <p>{`Now, whenever any page in your application makes a network request in development, Mirage will intercept that request and respond with the data from your server definition.`}</p>
    <p>{`You now have a central place to define and update your Mirage server as you continue local development on your Next.js app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      