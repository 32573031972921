import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-network-requests-in-cypress-with-mirage"
    }}>{`Mock Network Requests in Cypress with Mirage`}</h1>
    <p>{`Use your Mirage server to test your application under different server scenarios using `}<a parentName="p" {...{
        "href": "https://www.cypress.io/"
      }}>{`Cypress`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a quickstart guide for people already using Cypress in their apps.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you have Mirage installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-server"
    }}>{`Step 2: Define your server`}</h2>
    <p>{`Create a new `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{` file and define your mock server.`}</p>
    <p>{`Here's a basic example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "development" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    routes() {
      this.namespace = "api"

      this.get("/users", (schema) => {
        return schema.users.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <h2 {...{
      "id": "step-3-have-cypress-define-a-proxy-function-for-your-apps-api-requests"
    }}>{`Step 3: Have Cypress define a proxy function for your app's API requests`}</h2>
    <p>{`Add the following code to your `}<inlineCode parentName="p">{`cypress/support/index.js`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// cypress/support/index.js
Cypress.on("window:before:load", (win) => {
  win.handleFromCypress = function (request) {
    return fetch(request.url, {
      method: request.method,
      headers: request.requestHeaders,
      body: request.requestBody,
    }).then((res) => {
      let content = res.headers.get("content-type").includes("application/json")
        ? res.json()
        : res.text()
      return new Promise((resolve) => {
        content.then((body) => resolve([res.status, res.headers, body]))
      })
    })
  }
})
`}</code></pre>
    <p>{`This code defines a `}<inlineCode parentName="p">{`handleFromCypress`}</inlineCode>{` function on your application's `}<inlineCode parentName="p">{`window`}</inlineCode>{` object. In the next step, we'll configure your app to call this function whenever it makes a network request while Cypress is running.`}</p>
    <h2 {...{
      "id": "step-4-proxy-your-apps-network-requests"
    }}>{`Step 4: Proxy your app's network requests`}</h2>
    <p>{`In your app's bootstrapping file, use Mirage to proxy your app's API requests to the `}<inlineCode parentName="p">{`handleFromCypress`}</inlineCode>{` function when Cypress is running.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Create React App users, this code goes in `}<inlineCode parentName="p">{`src/index.js`}</inlineCode></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Vue CLI users, this code goes in `}<inlineCode parentName="p">{`src/main.js`}</inlineCode>{`.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Response } from "miragejs"

if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  let otherDomains = []
  let methods = ["get", "put", "patch", "post", "delete"]

  createServer({
    environment: "test",
    routes() {
      for (const domain of ["/", ...otherDomains]) {
        for (const method of methods) {
          this[method](\`\${domain}*\`, async (schema, request) => {
            let [status, headers, body] = await window.handleFromCypress(
              request
            )
            return new Response(status, headers, body)
          })
        }
      }

      // If your central server has any calls to passthrough(), you'll need to duplicate them here
      // this.passthrough('https://analytics.google.com')
    },
  })
}
`}</code></pre>
    <p>{`Now, whenever Cypress boots up your app, this code will delegate your app's network requests to the `}<inlineCode parentName="p">{`handleFromCypress`}</inlineCode>{` function that we defined in the previous step.`}</p>
    <p>{`Once we start our real configured Mirage server alongside our Cypress code, it will start intercepting the requests from that function.`}</p>
    <h2 {...{
      "id": "step-5-write-tests-using-your-mirage-server"
    }}>{`Step 5: Write tests using your Mirage server`}</h2>
    <p>{`Create a new `}<inlineCode parentName="p">{`cypress/integration/app.spec.js`}</inlineCode>{` file, import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, and start and shutdown Mirage before and after each test. You can then seed Mirage with a different data scenario in each test, and use the test to assert against the state of your UI.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { makeServer } from "../../src/server"

describe("user list", () => {
  let server

  beforeEach(() => {
    server = makeServer({ environment: "test" })
  })

  afterEach(() => {
    server.shutdown()
  })

  it("shows the users from our server", () => {
    server.create("user", { id: 1, name: "Luke" })
    server.create("user", { id: 2, name: "Leia" })

    cy.visit("/")

    cy.get('[data-testid="user-1"]').contains("Luke")
    cy.get('[data-testid="user-2"]').contains("Leia")
  })

  it("shows a message if there are no users", () => {
    // Don't create any users

    cy.visit("/")

    cy.get('[data-testid="no-users"]').should("be.visible")
  })
})
`}</code></pre>
    <p>{`Note that we pass in `}<inlineCode parentName="p">{`environment: test`}</inlineCode>{` option into our `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, so that Mirage doesn't load its database seeds. That way, the server starts out empty for each test run, and in the beginning of our tests we can use `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` to set up our data scenario. The test environment also disables logging and latency, so that by default your CI test logs will be clean and your tests will be fast.`}</p>
    <p>{`Also note our usage of Cypress's `}<inlineCode parentName="p">{`describe`}</inlineCode>{` blocks, as they will keep our Mirage server scoped to each spec as we add more files, preventing any state leakage between test files.`}</p>
    <h2 {...{
      "id": "step-6-alter-your-mirage-server-to-test-different-server-states"
    }}>{`Step 6: Alter your Mirage server to test different server states`}</h2>
    <p>{`In addition to different data scenarios, you can use your tests to reconfigure your Mirage server to test new situations.`}</p>
    <p>{`For example, you can test an error state like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Response } from "miragejs"

it("handles error responses from the server", () => {
  // Override Mirage's route handler for /users, just for this test
  server.get("/users", () => {
    return new Response(500, {}, { error: "The database is on vacation." })
  })

  cy.visit("/")

  cy.get('[data-testid="server-error"]').contains(
    "The database is on vacation."
  )
})
`}</code></pre>
    <p>{`Because of the way Mirage integrates with Cypress each test will get a fresh Mirage server based on your main server definition. Any overrides you make within a test will be isolated to that test.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      