import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "factories"
    }}>{`Factories`}</h1>
    <p>{`One of the main benefits of using Mirage is the ability to quickly put your server into different states.`}</p>
    <p>{`For example, you might be developing a feature and want to see how the UI renders for both a logged-in user and an anonymous user. This is the kind of thing that's a pain when using a real backend server, but with Mirage it's as simple as flipping a JavaScript variable and watching your app live-reload.`}</p>
    <p>{`Factories are classes that help you organize your data-creation logic, making it easier to define different server states during development or within tests.`}</p>
    <p>{`Let's see how they work.`}</p>
    <h2 {...{
      "id": "defining-factories"
    }}>{`Defining factories`}</h2>
    <h3 {...{
      "id": "your-first-factory"
    }}>{`Your first factory`}</h3>
    <p>{`Say we have a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model defined in Mirage.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model } from "miragejs"

createServer({
  models: {
    movie: Model,
  },
})
`}</code></pre>
    <p>{`To seed Mirage's database with some movies so you can start developing your app, use the `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` method in your server's `}<inlineCode parentName="p">{`seeds`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model } from "miragejs"

createServer({
  models: {
    movie: Model,
  },

  seeds(server) {
    server.create("movie")
  },
})
`}</code></pre>
    <p><inlineCode parentName="p">{`server.create`}</inlineCode>{` takes the singular hyphenated form of your model's class name as its first argument.`}</p>
    <p>{`Because we have no Factory defined for a `}<inlineCode parentName="p">{`Movie`}</inlineCode>{`, `}<inlineCode parentName="p">{`server.create('movie')`}</inlineCode>{` will just create an empty record and insert it into the database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// server.db.dump();
{
  movies: [{ id: "1" }]
}
`}</code></pre>
    <p>{`Not a very interesting record.`}</p>
    <p>{`However, we can pass attributes of our own as the second argument to `}<inlineCode parentName="p">{`server.create`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("movie", {
  title: "Interstellar",
  releaseDate: "10/26/2014",
  genre: "Sci-Fi",
})
`}</code></pre>
    <p>{`Now our database looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`// server.db.dump()

{
  "movies": [
    {
      "id": "1",
      "title": "Interstellar",
      "releaseDate": "10/26/2014",
      "genre": "Sci-Fi"
    }
  ]
}
`}</code></pre>
    <p>{`and we can actually start developing our UI against realistic data.`}</p>
    <p>{`This is a great way to start, but it can be cumbersome to manually define every attribute (and relationship) when working on data-driven applications. It would be nice if we had a way to dynamically generate some of these attributes.`}</p>
    <p>{`Fortunately, Factories let us do just that!`}</p>
    <p>{`Let's define a Factory for our `}<inlineCode parentName="p">{`Movie`}</inlineCode>{` model using the `}<inlineCode parentName="p">{`factories`}</inlineCode>{` key of our server options and the `}<inlineCode parentName="p">{`Factory`}</inlineCode>{` import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Factory } from "miragejs"

createServer({
  models: {
    movie: Model,
  },

  factories: {
    movie: Factory.extend({
      // factory properties go here
    }),
  },

  seeds(server) {
    server.create("movie")
  },
})
`}</code></pre>
    <p>{`Right now the Factory is empty. Let's define a property on it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Factory } from "miragejs"

createServer({
  models: {
    movie: Model,
  },

  factories: {
    movie: Factory.extend({
      title: "Movie title",
    }),
  },

  seeds(server) {
    server.create("movie")
  },
})
`}</code></pre>
    <p>{`Now `}<inlineCode parentName="p">{`server.create('movie')`}</inlineCode>{` will use the properties from this factory. The inserted record will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "movies": [{ "id": "1", "title": "Movie title" }]
}
`}</code></pre>
    <p>{`We can also make this property a function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Factory.extend({
  title(i) {
    return \`Movie \${i}\`
  },
})
`}</code></pre>
    <p><inlineCode parentName="p">{`i`}</inlineCode>{` is an incrementing index that lets us make dynamic factory attributes.`}</p>
    <p>{`If we use the `}<inlineCode parentName="p">{`server.createList`}</inlineCode>{` method, we can quickly generate five movies`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.createList("movie", 5)
`}</code></pre>
    <p>{`and with the above factory definition, our database will now look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "movies": [
    { "id": "1", "title": "Movie 1" },
    { "id": "2", "title": "Movie 2" },
    { "id": "3", "title": "Movie 3" },
    { "id": "4", "title": "Movie 4" },
    { "id": "5", "title": "Movie 5" }
  ]
}
`}</code></pre>
    <p>{`Let's add some more properties to our factory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Factory } from "miragejs"
import faker from "faker"

createServer({
  models: {
    movie: Model,
  },

  factories: {
    movie: Factory.extend({
      title(i) {
        return \`Movie \${i}\`
      },

      releaseDate() {
        return faker.date.past().toLocaleDateString()
      },

      genre(i) {
        let genres = ["Sci-Fi", "Drama", "Comedy"]

        return genres[i % genres.length]
      },
    }),
  },

  seeds(server) {
    // Use factories here
  },
})
`}</code></pre>
    <p>{`Here we've installed the `}<a parentName="p" {...{
        "href": "https://fakerjs.dev/"
      }}>{`Faker.js`}</a>{` library to help us generate random dates.`}</p>
    <p>{`Now if we create 5 movies in our development seeds`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.createList('movie', 5)
}
`}</code></pre>
    <p>{`we'll have this data in our database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "movies": [
    {
      "id": "1",
      "title": "Movie 1",
      "releaseDate": "5/14/2018",
      "genre": "Sci-Fi"
    },
    {
      "id": "2",
      "title": "Movie 2",
      "releaseDate": "2/22/2019",
      "genre": "Drama"
    },
    {
      "id": "3",
      "title": "Movie 3",
      "releaseDate": "6/2/2018",
      "genre": "Comedy"
    },
    {
      "id": "4",
      "title": "Movie 4",
      "releaseDate": "7/29/2018",
      "genre": "Sci-Fi"
    },
    {
      "id": "5",
      "title": "Movie 5",
      "releaseDate": "6/30/2018",
      "genre": "Drama"
    }
  ]
}
`}</code></pre>
    <p>{`As you can see, Factories let us rapidly generate different scenarios for our dynamic server data.`}</p>
    <h3 {...{
      "id": "attribute-overrides"
    }}>{`Attribute overrides`}</h3>
    <p>{`Factories are great for defining the "base case" of your models, but there's plenty of times where you'll want to override attributes from your factory with specific values.`}</p>
    <p>{`The last argument to `}<inlineCode parentName="p">{`create`}</inlineCode>{` and `}<inlineCode parentName="p">{`createList`}</inlineCode>{` accepts a POJO of attributes that will override anything from your factory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Using only the base factory
server.create('movie');
// gives us this object:
{ id: '1', title: 'Movie 1', releaseDate: '01/01/2000' }

// Passing in specific values to override certain attributes
server.create('movie', { title: 'Interstellar' });
// gives us this object:
{ id: '2', title: 'Interstellar', releaseDate: '01/01/2000' }
`}</code></pre>
    <p>{`Think of your factory attributes as a reasonable "base case" for your models, and then override them in development and testing scenarios as you have need for specific values.`}</p>
    <h3 {...{
      "id": "dependent-attributes"
    }}>{`Dependent attributes`}</h3>
    <p>{`Attributes can depend on other attributes via `}<inlineCode parentName="p">{`this`}</inlineCode>{` from within a function. This can be useful for quickly generating things like usernames from names:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`factories: {
  user: Factory.extend({
    name() {
      return faker.name.findName()
    },

    username() {
      return this.name.replace(" ", "").toLowerCase()
    },
  })
}
`}</code></pre>
    <p>{`Calling `}<inlineCode parentName="p">{`server.createList('user', 3)`}</inlineCode>{` with this factory would generate this data:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
  { "id": "1", "name": "Retha Donnelly", "username": "rethadonnelly" },
  { "id": "2", "name": "Crystal Schaefer", "username": "crystalschaefer" },
  { "id": "3", "name": "Jerome Schoen", "username": "jeromeschoen" }
]
`}</code></pre>
    <h3 {...{
      "id": "relationships"
    }}>{`Relationships`}</h3>
    <p>{`In the same way that you use the ORM to create relational data with the underlying `}<inlineCode parentName="p">{`schema`}</inlineCode>{` object`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let nolan = schema.people.create({ name: "Christopher Nolan" })

schema.movies.create({
  director: nolan,
  title: "Interstellar",
})
`}</code></pre>
    <p>{`you can also create relational data with your factories:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let nolan = server.create("director", { name: "Christopher Nolan" })

server.create("movie", {
  director: nolan,
  title: "Interstellar",
})
`}</code></pre>
    <p><inlineCode parentName="p">{`nolan`}</inlineCode>{` is a model instance, which is why we can just pass it in as an attribute override when creating the Interstellar movie.`}</p>
    <p>{`This also works when using `}<inlineCode parentName="p">{`createList`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("actor", {
  movies: server.createList("movie", 3),
})
`}</code></pre>
    <p>{`In this way you use factories to help you quickly create graphs of relational data:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.createList("user", 5).forEach((user) => {
  server.createList("post", 10, { user }).forEach((post) => {
    server.createList("comment", 5, { post })
  })
})
`}</code></pre>
    <p>{`This code generates 5 users, each of which has 10 posts with each post having 5 comments. Assuming these relationships are defined in your models, all the foreign keys would be set correctly in Mirage's database.`}</p>
    <h3 {...{
      "id": "the-aftercreate-hook"
    }}>{`The afterCreate hook`}</h3>
    <p>{`In many cases, setting up relationships manually (as shown in the previous section) is perfectly fine. However there are times where it makes more sense to have base case relationships set up for you automatically.`}</p>
    <p>{`This is where `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook comes in handy. It's a hook that's called after a model has been created using the factory's base attributes. This hook lets you perform additional logic on your newly-created models before they're returned from `}<inlineCode parentName="p">{`create`}</inlineCode>{` and `}<inlineCode parentName="p">{`createList`}</inlineCode>{`.`}</p>
    <p>{`Let's see how it works.`}</p>
    <p>{`Say you have these two models in your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo } from "miragejs"

createServer({
  models: {
    user: Model,

    post: Model.extend({
      user: belongsTo(),
    }),
  },
})
`}</code></pre>
    <p>{`Let's further suppose that in your app, it is never valid to create a post without an associated user.`}</p>
    <p>{`You can use `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` to enforce this behavior:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, belongsTo, Factory } from "miragejs"

createServer({
  models: {
    user: Model,

    post: Model.extend({
      user: belongsTo(),
    }),
  },

  factories: {
    post: Factory.extend({
      afterCreate(post, server) {
        post.update({
          user: server.create("user"),
        })
      },
    }),
  },
})
`}</code></pre>
    <p>{`The first argument to `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` is the object that was just created (in this case the `}<inlineCode parentName="p">{`post`}</inlineCode>{`), and the second is a reference to the Mirage server instance, so that you can invoke other factories or inspect any other server state needed to customize your newly-created object.`}</p>
    <p>{`In this example our factory will immediately create a user for this post. That means elsewhere the your app (say, a test) you could just create a post`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("post")
`}</code></pre>
    <p>{`and you'd be working with a valid record, since that post would automatically have an associated user created and associated with it.`}</p>
    <p>{`Now, there's a one problem with the way we've implemented this so far. Our `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook updates the post's user `}<em parentName="p">{`regardless if that post already had a user associated with it`}</em>{`.`}</p>
    <p>{`That means that this code`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let jane = server.create("user", { name: "Jane" })
server.createList("post", 10, { user: jane })
`}</code></pre>
    <p>{`would not work as we expect, since the attribute overrides are used while the object is being created, but the logic in `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` runs `}<em parentName="p">{`after`}</em>{` the post has been created. Thus, this post would be associated with the newly created user from the hook, rather than Jane.`}</p>
    <p>{`To fix this, we can update our `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook to first check if the newly created post already has a user associated with it, and only if it doesn't will we create a new one and update the relationship:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Factory.extend({
  afterCreate(post, server) {
    if (!post.user) {
      post.update({
        user: server.create("user"),
      })
    }
  },
})
`}</code></pre>
    <p>{`Now callers can pass in specific users`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.createList("post", 10, { user: jane })
`}</code></pre>
    <p>{`or omit specifying a user if the details of that user aren't important`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("post")
`}</code></pre>
    <p>{`and in both cases they'll end up with a valid `}<inlineCode parentName="p">{`Post`}</inlineCode>{` record.`}</p>
    <p><inlineCode parentName="p">{`afterCreate`}</inlineCode>{` can also be used to create `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` associations, as well as apply any other relevant creation logic.`}</p>
    <h3 {...{
      "id": "traits"
    }}>{`Traits`}</h3>
    <p>{`Traits are an important feature of factories that make it easy to group related attributes. Define them by importing `}<inlineCode parentName="p">{`trait`}</inlineCode>{` and adding a new key to your factory.`}</p>
    <p>{`For example, here we define a trait named `}<inlineCode parentName="p">{`published`}</inlineCode>{` on our post factory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Factory, trait } from "miragejs"

createServer({
  models: {
    post: Model,
  },

  factories: {
    post: Factory.extend({
      title: "Lorem ipsum",

      published: trait({
        isPublished: true,
        publishedAt: "2010-01-01 10:00:00",
      }),
    }),
  },
})
`}</code></pre>
    <p>{`You can pass anything into `}<inlineCode parentName="p">{`trait`}</inlineCode>{` that you can into the base factory.`}</p>
    <p>{`We can use our new trait by passing in the name of the trait as a string argument to `}<inlineCode parentName="p">{`create`}</inlineCode>{` or `}<inlineCode parentName="p">{`createList`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("post", "published")
server.createList("post", 3, "published")
`}</code></pre>
    <p>{`The created posts will have all the base attributes, as well as everything under the `}<inlineCode parentName="p">{`published`}</inlineCode>{` trait.`}</p>
    <p>{`You can also compose multiple traits together. Given the following factory that has two traits defined`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`post: Factory.extend({
  title: "Lorem ipsum",

  published: trait({
    isPublished: true,
    publishedAt: "2010-01-01 10:00:00",
  }),

  official: trait({
    isOfficial: true,
  }),
})
`}</code></pre>
    <p>{`we can pass our new traits into `}<inlineCode parentName="p">{`create`}</inlineCode>{` or `}<inlineCode parentName="p">{`createList`}</inlineCode>{` in any order:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let officialPost = server.create("post", "official")
let officialPublishedPost = server.create("post", "official", "published")
`}</code></pre>
    <p>{`If multiple traits set the same attribute, the last trait wins.`}</p>
    <p>{`As always, you can pass in an object of attribute overrides as the last argument, even if you're using a trait:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.create("post", "published", { title: "My first post" })
`}</code></pre>
    <p>{`When combined with the `}<inlineCode parentName="p">{`afterCreate()`}</inlineCode>{` hook, traits simplify the process of setting up related object graphs.`}</p>
    <p>{`Here we define a `}<inlineCode parentName="p">{`withComments`}</inlineCode>{` trait that creates 3 comments for a newly created post:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`post: Factory.extend({
  title: "Lorem ipsum",

  withComments: trait({
    afterCreate(post, server) {
      server.createList("comment", 3, { post })
    },
  }),
})
`}</code></pre>
    <p>{`We can use this trait to quickly make 10 posts with 3 comments each:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.createList("post", 10, "withComments")
`}</code></pre>
    <p>{`Combining traits with the `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook is one of the most powerful features of Mirage factories. Effective use of this technique will dramatically simplify the process of creating different graphs of relational data for your app.`}</p>
    <p>{`When creating an object with one or more traits, the factory will run `}<em parentName="p">{`every`}</em>{` applicable `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook. The base factory's `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook will run first (if it exists), and any trait hooks will run in the order the traits were specified in the call to `}<inlineCode parentName="p">{`create`}</inlineCode>{` or `}<inlineCode parentName="p">{`createList`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "the-association-helper"
    }}>{`The association helper`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`association()`}</inlineCode>{` helper provides some sugar for creating `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` relationships.`}</p>
    <p>{`As we saw earlier, the `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook lets us pre-wire relationships:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{14-20}"
      }}>{`import { createServer, Model, Factory } from "miragejs"

createServer({
  models: {
    user: Model,

    post: Model.extend({
      user: belongsTo(),
    }),
  },

  factories: {
    post: Factory.extend({
      afterCreate(post, server) {
        if (!post.user) {
          post.update({
            user: server("user"),
          })
        }
      },
    }),
  },
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`association()`}</inlineCode>{` helper effectively replaces this code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{14}"
      }}>{`import { createServer, Model, Factory, association } from "miragejs"

createServer({
  models: {
    user: Model,

    post: Model.extend({
      user: belongsTo(),
    }),
  },

  factories: {
    post: Factory.extend({
      user: association(),
    }),
  },
})
`}</code></pre>
    <p>{`This should help reduce some of the boilerplate in your factory definitions.`}</p>
    <p>{`You can also use `}<inlineCode parentName="p">{`association()`}</inlineCode>{` within traits. This definition`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`post: Factory.extend({
  withUser: trait({
    user: association(),
  }),
})
`}</code></pre>
    <p>{`would let you write `}<inlineCode parentName="p">{`server.create('post', 'withUser')`}</inlineCode>{` to create a post with an associated user.`}</p>
    <p>{`You can also pass additional traits and overrides to `}<inlineCode parentName="p">{`association()`}</inlineCode>{` for the related model's factory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`post: Factory.extend({
  withUser: trait({
    user: association("admin", { role: "editor" }),
  }),
})
`}</code></pre>
    <p>{`Note that the `}<inlineCode parentName="p">{`association()`}</inlineCode>{` helper cannot be used if your `}<inlineCode parentName="p">{`belongsTo`}</inlineCode>{` relationship is polymorphic. Also, `}<inlineCode parentName="p">{`association()`}</inlineCode>{` doesn't work for `}<inlineCode parentName="p">{`hasMany`}</inlineCode>{` relationships. In both of these cases, you should continue to use the `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` hook to seed your data.`}</p>
    <h2 {...{
      "id": "using-factories"
    }}>{`Using factories`}</h2>
    <h3 {...{
      "id": "in-development"
    }}>{`In development`}</h3>
    <p>{`To use your factories to seed your development database, call `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` and `}<inlineCode parentName="p">{`server.createList`}</inlineCode>{` in your server's seeds function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model, Factory } from "miragejs"

createServer({
  models: {
    movie: Model,
  },

  factories: {
    movie: Factory.extend({
      title(i) {
        return \`Movie \${i}\`
      },
    }),
  },

  seeds(server) {
    server.createList("movie", 10)
  },
})
`}</code></pre>
    <p>{`There's no explicit API for switching scenarios in development, but you can just use JavaScript modules to split things up.`}</p>
    <p>{`For example, you could create a new file for each scenario that contains some seeding logic`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// mirage/scenarios/admin.js
export default function (server) {
  server.create("user", { isAdmin: true })
}
`}</code></pre>
    <p>{`...export all scenarios as an object from an `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// mirage/scenarios/index.js
import anonymous from "./anonymous"
import subscriber from "./subscriber"
import admin from "./admin"

export default scenarios = {
  anonymous,
  subscriber,
  admin,
}
`}</code></pre>
    <p>{`...and then import that object into `}<inlineCode parentName="p">{`default.js`}</inlineCode>{`.`}</p>
    <p>{`Now you can quickly switch your development state by changing a single variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// mirage/server.js
import scenarios from "./scenarios"

// Choose one
const state =
  // 'anonymous'
  // 'subscriber'
  "admin"

createServer({
  // other config,

  seeds: scenarios[state],
})
`}</code></pre>
    <p>{`This can be handy while developing your app or sharing the different states of a new feature with your team.`}</p>
    <h3 {...{
      "id": "in-testing"
    }}>{`In testing`}</h3>
    <p>{`When you run your server in a `}<inlineCode parentName="p">{`test`}</inlineCode>{` environment, the behavior of your server changes slightly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  environment: "test", // default is development

  seeds(server) {
    // This function is ignored when environment is "test"
    server.createList("movie", 10)
  },
})
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`test`}</inlineCode>{`, Mirage will load all your server config, but it will ignore your seeds. (It also sets the `}<inlineCode parentName="p">{`timing`}</inlineCode>{` to 0 for route handlers and hides logs from the console.)`}</p>
    <p>{`That means each test starts out with a clean database, giving you the opportunity to set up only the state needed for that test. It also keeps your development environment isolated from your tests, so that you don't inadvertently break your test suite while tweaking your seeds.`}</p>
    <p>{`To seed Mirage's database within a test, use the `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` and `}<inlineCode parentName="p">{`server.createList`}</inlineCode>{` methods.`}</p>
    <p>{`For example, if you're using `}<inlineCode parentName="p">{`@testing-library/react`}</inlineCode>{`, your test might look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let server

beforeEach(() => {
  server = startMirage()
})

afterEach(() => {
  server.shutdown()
})

test("I see a message if there are no movies", () => {
  const { getByTestId } = render(<App />)
  expect(getByTestId("no-movies")).toBeInTheDocument()
})

test("I see a list of the movies from the server", async () => {
  server.createList("movie", 5)

  const { getByTestId } = render(<App />)
  await waitForElement(() => getByTestId("movie-list"))

  expect(getByTestId("movie")).toHaveLength(5)
})
`}</code></pre>
    <p>{`In the first test, we boot up our Mirage server, but don't seed it with any movies. When we boot up the React app, we assert that there's an element in the document with a message that there were no movies found.`}</p>
    <p>{`In the second test, we also boot up our Mirage server, but we seed it with 5 movies. This time when we render our React app, we wait for a `}<inlineCode parentName="p">{`movie-list`}</inlineCode>{` element to be present. We use `}<inlineCode parentName="p">{`await`}</inlineCode>{` because our React app is making a network request, which is asynchronous. Once Mirage responds to that request, we assert that those movies show up in our UI.`}</p>
    <p>{`Each test starts out with a fresh Mirage server, so none of Mirage's state leaks across tests.`}</p>
    <p>{`You can read more about testing with Mirage in the Testing section of these guides.`}</p>
    <h2 {...{
      "id": "factory-best-practices"
    }}>{`Factory best practices`}</h2>
    <p>{`In general, it's best to define a model's base factory using only the attributes and relationships that comprise the minimal valid state for that model. You can then use `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` and traits to define other common states that contain valid, related changes on top of the base case.`}</p>
    <p>{`This advice goes a long way towards keeping your test suite maintainable.`}</p>
    <p>{`If you don't use traits and `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{`, your tests will become bogged down in irrelevant details related to setting up the data needed for that test.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`test("I can see the title of a post", async function (assert) {
  let session = server.create("session")
  let user = server.create("user", { session })
  server.create("post", {
    user,
    title: "My first post",
    slug: "my-first-post",
  })

  await visit("/post/my-first-post")

  assert.dom("h1").hasText("My first post")
})
`}</code></pre>
    <p>{`This test is only concerned with asserting the title of a post gets rendered to the screen, but it has lots of boilerplate code that's only there to get the post in a valid state.`}</p>
    <p>{`If we used `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` instead, the developer writing this test could simply create a post with a specified `}<inlineCode parentName="p">{`title`}</inlineCode>{` and `}<inlineCode parentName="p">{`slug`}</inlineCode>{`, since those are the only details relevant to the test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`test("I can see the title of a post", async function (assert) {
  server.create("post", {
    title: "My first post",
    slug: "my-first-post",
  })

  await visit("/post/my-first-post")

  assert.dom("h1").hasText("My first post")
})
`}</code></pre>
    <p><inlineCode parentName="p">{`afterCreate`}</inlineCode>{` could take care of setting up the session and user in valid states, and associating the user with the post, so that the test can stay concise and focused on what it's actually testing.`}</p>
    <p>{`Effective use of traits and `}<inlineCode parentName="p">{`afterCreate`}</inlineCode>{` keeps your test suite less brittle and more robust to changes in your data layer, since tests only declare the bare minimum setup logic needed to verify their assertions.`}</p>
    <hr></hr>
    <p>{`Up next, we'll take a look at how to use Fixtures as an alternative way to seed your database.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      