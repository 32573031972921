import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-network-requests-in-vue-test-utils-with-mirage"
    }}>{`Mock Network Requests in Vue Test Utils with Mirage`}</h1>
    <p>{`Use your Mirage server to test your Vue application under different server scenarios using `}<a parentName="p" {...{
        "href": "https://vue-test-utils.vuejs.org/"
      }}>{`Vue Test Utils`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a quickstart guide for people already using Vue Test Utils in their Vue apps.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you have Mirage installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-server"
    }}>{`Step 2: Define your server`}</h2>
    <p>{`Create a new `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` file and define your mock server.`}</p>
    <p>{`Here's a basic example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "development" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    routes() {
      this.namespace = "api"

      this.get("/users", (schema) => {
        return schema.users.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`In a Vue CLI, put this file in `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{` so that changes to it trigger rebuilds.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-3-create-a-test-file-that-uses-mirage"
    }}>{`Step 3: Create a test file that uses Mirage`}</h2>
    <p>{`Here's the Vue component we'll be testing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- src/App.vue -->
<template>
  <div v-if="serverError" data-testid="server-error">{{ serverError }}</div>

  <div v-else-if="users.length === 0" data-testid="no-users">No users!</div>

  <div v-else>
    <ul id="users">
      <li
        v-for="user in users"
        v-bind:key="user.id"
        :data-testid="'user-' + user.id"
      >
        {{ user.name }}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "app",

    data() {
      return {
        users: [],
        serverError: null,
      }
    },

    created() {
      fetch("/api/users")
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            this.serverError = json.error
          } else {
            this.users = json.users
          }
        })
    },
  }
</script>
`}</code></pre>
    <p>{`Create a new `}<inlineCode parentName="p">{`src/App.spec.js`}</inlineCode>{` file, import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, and start and shutdown Mirage using `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{`, making sure to pass the `}<inlineCode parentName="p">{`test`}</inlineCode>{` environment to Mirage:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.spec.js
import { mount } from "@vue/test-utils"
import { makeServer } from "./server"
import App from "./App.vue"

let server

beforeEach(() => {
  server = makeServer({ environment: "test" })
})

afterEach(() => {
  server.shutdown()
})
`}</code></pre>
    <h2 {...{
      "id": "step-4-write-tests-using-your-mirage-server"
    }}>{`Step 4: Write tests using your Mirage server`}</h2>
    <p>{`Use your tests to seed Mirage with different data scenarios, then assert against the state of your UI.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In the `}<inlineCode parentName="p">{`test`}</inlineCode>{` environment, Mirage doesn't load its database `}<inlineCode parentName="p">{`seeds`}</inlineCode>{`, so that the server starts out empty for each test run.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`it("shows the users from our server", async () => {
  server.create("user", { id: 1, name: "Luke" })
  server.create("user", { id: 2, name: "Leia" })

  const wrapper = mount(App)

  // let's wait for our vue component to finish loading data
  // we know it's done when the data-testid enters the dom.
  await waitFor(wrapper, '[data-testid="user-1"]')
  await waitFor(wrapper, '[data-testid="user-2"]')

  expect(wrapper.find('[data-testid="user-1"]').text()).toBe("Luke")
  expect(wrapper.find('[data-testid="user-2"]').text()).toBe("Leia")
})

it("shows a message if there are no users", async () => {
  // Don't create any users

  const wrapper = mount(App)
  await waitFor(wrapper, '[data-testid="no-users"]')

  expect(wrapper.find('[data-testid="no-users"]').text()).toBe("No users!")
})

// This helper method returns a promise that resolves
// once the selector enters the wrapper's dom.
const waitFor = function (wrapper, selector) {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      const userEl = wrapper.findAll(selector)
      if (userEl.length > 0) {
        clearInterval(timer)
        resolve()
      }
    }, 100)
  })
}
`}</code></pre>
    <h2 {...{
      "id": "step-5-alter-your-mirage-server-to-test-different-server-states"
    }}>{`Step 5: Alter your Mirage server to test different server states`}</h2>
    <p>{`In addition to different data scenarios, you can use your tests to reconfigure your Mirage server to test new situations.`}</p>
    <p>{`For example, you can test an error state like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.spec.js
import { Response } from "miragejs"

it("handles error responses from the server", async () => {
  // Override Mirage's route handler for /users, just for this test
  server.get("/users", () => {
    return new Response(
      500,
      {},
      {
        error: "The database is on vacation.",
      }
    )
  })

  const wrapper = mount(App)

  await waitFor(wrapper, '[data-testid="server-error"]')

  expect(wrapper.find('[data-testid="server-error"]').text()).toBe(
    "The database is on vacation."
  )
})
`}</code></pre>
    <p>{`Because of the way we setup Mirage using `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{`, each test will get a fresh Mirage server based on your main server definition. Any overrides you make within a test will be isolated to that test.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      