import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-1--static-get-handler"
    }}>{`Part 1 – Static GET Handler`}</h1>
    <p>{`As you can see from the interface, our Reminders app is trying to make an API request for its data, but the request is failing.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1adb7803c6aabfceee5b09d00547c4d6/0ffd9/part-1-failing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGklEQVQoz42S6W6DMBCEef93679KpQ1HAuE2YGwSTme6hiAIKm2RPtaGYTysbXgxx5ZLVE7VTyrs3x1pt2NjL9BGphPh7d2B6cawfAY3KOCGrywG+2rsV9A1SDm4bFA3PW7tAHnvp/lCVbeIMvF7wuWBTsNlC8ZyWLaDr5OFLGNYr8d0Z/yGMyX901CL8uqOrusgZA0hJJqmXe1mP7CyJm35H8MSaSGhlIKirzUjjfthxEDoOo5q0mwNF14Mt308B/lKuOIGM/pPzlq38DQ3to2dKQ+PiU8ECUeYCqJCSBuzxU+eCZd0R3hTCo4040At0eU5eiGmVowjtYJQD4WCev9DwoNDTFxDhti+4PJxmjEteISu/qcNz0/wDb4u7e+lxxIbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing request",
            "title": "Failing request",
            "src": "/static/1adb7803c6aabfceee5b09d00547c4d6/37523/part-1-failing.png",
            "srcSet": ["/static/1adb7803c6aabfceee5b09d00547c4d6/e9ff0/part-1-failing.png 180w", "/static/1adb7803c6aabfceee5b09d00547c4d6/f21e7/part-1-failing.png 360w", "/static/1adb7803c6aabfceee5b09d00547c4d6/37523/part-1-failing.png 720w", "/static/1adb7803c6aabfceee5b09d00547c4d6/302a4/part-1-failing.png 1080w", "/static/1adb7803c6aabfceee5b09d00547c4d6/07a9c/part-1-failing.png 1440w", "/static/1adb7803c6aabfceee5b09d00547c4d6/0ffd9/part-1-failing.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`That's because there's no API server for our app to talk to. Let's use Mirage to mock out the failing API request.`}</p>
    <p>{`Open the `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{` file in your editor, import `}<inlineCode parentName="p">{`createServer`}</inlineCode>{` from `}<inlineCode parentName="p">{`miragejs`}</inlineCode>{`, and export a default function that creates a new server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer } from "miragejs"

export default function () {
  createServer()
}
`}</code></pre>
    <p>{`We import and run this function for you in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` but in your own apps you can wire this up however you like.`}</p>
    <p>{`Back in the app, if you open your JavaScript console, you should see an error from Mirage:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Mirage: Your app tried to GET '/api/reminders', but there was no route defined to handle this request.`}</p>
    </blockquote>
    <p>{`This means Mirage is running, and that the Reminders app is making a GET request that hasn't been mocked out.`}</p>
    <p>{`We can mock it out using the `}<inlineCode parentName="p">{`routes()`}</inlineCode>{` hook. Update the server in your app by copying in the highlighted code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{4-10}"
      }}>{`import { createServer } from "miragejs"

export default function () {
  createServer({
    routes() {
      this.get("/api/reminders", () => ({
        reminders: [],
      }))
    },
  })
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`routes()`}</inlineCode>{` hook is how we define our route handlers, and the `}<inlineCode parentName="p">{`this.get()`}</inlineCode>{` method lets us mock out GET requests. The first argument is the URL we're handling (`}<inlineCode parentName="p">{`/api/reminders`}</inlineCode>{`) and the second argument is a function that responds to our app with some data.`}</p>
    <p>{`You should see the app reload and then render a message saying "All done!":`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/38cda4298ef4d2bfd8e8433425fdb3bb/0ffd9/part-1-all-done.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.888888888888886%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA7klEQVQ4y62TfQ/BMBDGff/vhpAIEfZKUax7ia1B92grJ8M6+8Mlv7Tp03tyl14H0T4FEe6EXTc8R3zIEDc0F5RDDD4Fb3PCeOZhvmZYhhwBE1ZzQbm0/zIMWIKFx7CKuN0/z4WuOP2is0IS2TGHqmt8xu2u3jB3kqy0HTgNjbg/F6gqidF4ahmOJvD98M1cKQVow7SoGobCXWGtLxvTy6W0SClNvj0nTKR52c/QVFDJK+66NYqmGRmKX4aBNtxpw76R9Wk51gI/Zy8ODoy25Y1Xdo2NWf1t0guqrnUO22Yq+sdP6foVXT+ldQ7/wQPAitHpvbTe8gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "All done",
            "title": "All done",
            "src": "/static/38cda4298ef4d2bfd8e8433425fdb3bb/37523/part-1-all-done.png",
            "srcSet": ["/static/38cda4298ef4d2bfd8e8433425fdb3bb/e9ff0/part-1-all-done.png 180w", "/static/38cda4298ef4d2bfd8e8433425fdb3bb/f21e7/part-1-all-done.png 360w", "/static/38cda4298ef4d2bfd8e8433425fdb3bb/37523/part-1-all-done.png 720w", "/static/38cda4298ef4d2bfd8e8433425fdb3bb/302a4/part-1-all-done.png 1080w", "/static/38cda4298ef4d2bfd8e8433425fdb3bb/07a9c/part-1-all-done.png 1440w", "/static/38cda4298ef4d2bfd8e8433425fdb3bb/0ffd9/part-1-all-done.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the console and you'll see Mirage handled the request with a 200 status code and the object from our handler.`}</p>
    <p>{`Let's add some data to our response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{4-8}"
      }}>{`createServer({
  routes() {
    this.get("/api/reminders", () => ({
      reminders: [
        { id: 1, text: "Walk the dog" },
        { id: 2, text: "Take out the trash" },
        { id: 3, text: "Work out" },
      ],
    }))
  },
})
`}</code></pre>
    <p>{`The app now renders this data.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/19e5aa05d30ddf68021003d60c5bbc75/c2d13/part-1-reminders.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.11111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABC0lEQVQ4y61TDW/CIBTs//9vmzNmZstMdW52UC2gbf0qcINnVFLXD81ILvcoj3v3KERzpvD5Iwk+nsxTDMYx3qcMHy4+rzWhvj+qf5guMrzFCeLvlZtLfHHViPPekKNwMkskuMjhhzG2EyzLMWsXFFjKEnlRYPAywnD0iqfnIRhPqYi1llhrQzH3gkmXQ5fkkw+HI2G326OqqotgCN7t8CSotcYmL5zTklq7il1duqinoDtD70gIhUxIYl/A1hziHodHJ7jKBMrtlsSNMTfterC+Dk8HX1FbbaPzp3hepApyXUBuSmKhHNZ/w+c23sNwIXaXuw9aX0rI96D1pYTV+qIuHD3qrMnpvwv+AojFaxCvPDJwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "All done",
            "title": "All done",
            "src": "/static/19e5aa05d30ddf68021003d60c5bbc75/37523/part-1-reminders.png",
            "srcSet": ["/static/19e5aa05d30ddf68021003d60c5bbc75/e9ff0/part-1-reminders.png 180w", "/static/19e5aa05d30ddf68021003d60c5bbc75/f21e7/part-1-reminders.png 360w", "/static/19e5aa05d30ddf68021003d60c5bbc75/37523/part-1-reminders.png 720w", "/static/19e5aa05d30ddf68021003d60c5bbc75/302a4/part-1-reminders.png 1080w", "/static/19e5aa05d30ddf68021003d60c5bbc75/07a9c/part-1-reminders.png 1440w", "/static/19e5aa05d30ddf68021003d60c5bbc75/c2d13/part-1-reminders.png 2560w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Congrats – you just wrote your first Mirage route handler!`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Mirage runs in the browser, so there's no new terminal processes to manage`}</li>
      <li parentName="ul">{`Returning data from Mirage is as fast and easy as writing frontend code`}</li>
      <li parentName="ul">{`You never alter your application code to consume data from Mirage – your app thinks it's talking to a real network`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      