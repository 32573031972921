import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "setup-a-vue-app-with-mirage-for-development"
    }}>{`Setup a Vue app with Mirage for Development`}</h1>
    <p>{`Mock your Vue app's networking code so you can develop your Vue app without any backend services.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This is a quickstart guide for people familiar with Mirage. If you're brand new to Mirage, take a look at the `}<a parentName="p" {...{
          "href": "/docs/getting-started/overview"
        }}>{`Overview`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you have Mirage installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-server"
    }}>{`Step 2: Define your server`}</h2>
    <p>{`Create a new `}<inlineCode parentName="p">{`server.js`}</inlineCode>{` file and define your mock server.`}</p>
    <p>{`Here's a basic example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "development" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    routes() {
      this.namespace = "api"

      this.get("/users", (schema) => {
        return schema.users.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`In a Vue CLI, put this file in `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{` so that changes to it trigger rebuilds.`}</p>
    </blockquote>
    <h2 {...{
      "id": "step-3-use-your-server-in-development"
    }}>{`Step 3: Use your server in development`}</h2>
    <p>{`Open your Vue app's bootstrap file (`}<inlineCode parentName="p">{`src/main.js`}</inlineCode>{` in Vue CLI), import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, and call it in the development environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{4,8-10}"
      }}>{`// src/main.js
import Vue from "vue"
import App from "./App.vue"
import { makeServer } from "./server"

Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
  makeServer()
}

new Vue({
  render: (h) => h(App),
}).$mount("#app")
`}</code></pre>
    <p>{`Now, whenever your application makes a network request in development, Mirage will intercept that request and respond with the data from your server definition.`}</p>
    <p>{`For example, given the server definition above, the following component would fetch the users `}<inlineCode parentName="p">{`Bob`}</inlineCode>{` and `}<inlineCode parentName="p">{`Alice`}</inlineCode>{` that we defined in `}<inlineCode parentName="p">{`seeds`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- src/App.vue -->
<template>
  <ul id="users">
    <li v-for="user in users" v-bind:key="user.id">{{ user.name }}</li>
  </ul>
</template>

<script>
  export default {
    name: "app",

    data() {
      return {
        users: [],
      }
    },

    created() {
      fetch("/api/users")
        .then((res) => res.json())
        .then((json) => {
          this.users = json.users
        })
    },
  }
</script>
`}</code></pre>
    <p>{`You can now continue to develop your Vue app, mocking out your backend API endpoints with Mirage as you go.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      