import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "models"
    }}>{`Models`}</h1>
    <p>{`To take advantage of the ORM, Mirage needs to know about your application's models and their relationships. This section will teach you how to define and work with your models, and the next will discuss relationships.`}</p>
    <p>{`As a clarifying point, Mirage model instances only exist within Mirage's server, and are never shared directly with your app or rendered directly in components. They exist solely to help you manage the data and relationships in your fake backend, but are serialized as a JSON string before they are sent over to your app.`}</p>
    <h2 {...{
      "id": "defining-models"
    }}>{`Defining models`}</h2>
    <p>{`To define a model, import the `}<inlineCode parentName="p">{`Model`}</inlineCode>{` class from `}<inlineCode parentName="p">{`miragejs`}</inlineCode>{` and use it as a key on the `}<inlineCode parentName="p">{`models`}</inlineCode>{` config option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createServer, Model } from "miragejs"

createServer({
  models: {
    blogPost: Model,
  },
})
`}</code></pre>
    <p>{`The above config defines a `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` model within Mirage.`}</p>
    <h2 {...{
      "id": "creating-models"
    }}>{`Creating models`}</h2>
    <p>{`To create models, access the model's collection via the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` object. You can access the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` as the first argument in your route handlers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/blog-posts", function (schema) {
  let attrs = this.normalizedRequestAttrs()

  schema.blogPosts.create(attrs)
})
`}</code></pre>
    <p>{`You can also access it as `}<inlineCode parentName="p">{`server.schema`}</inlineCode>{` directly off of your Mirage server instance, for example in your default scenario:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  models: {
    blogPost: Model,
  },

  seeds(server) {
    server.schema.blogPosts.create({ title: "Interstellar" })
  },
})
`}</code></pre>
    <p>{`Note that the collection is the pluralized form of the model's model name (the `}<inlineCode parentName="p">{`blogPost`}</inlineCode>{` model definition creates a `}<inlineCode parentName="p">{`schema.blogPosts`}</inlineCode>{` collection).`}</p>
    <p>{`Outside of route handlers, you'll typically create models using Factories via `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` rather than via the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` directly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`seeds(server) {
  server.create("blog-post")
}
`}</code></pre>
    <p>{`Calls to `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` delegate to the `}<inlineCode parentName="p">{`schema`}</inlineCode>{` collection's `}<inlineCode parentName="p">{`create`}</inlineCode>{` method under the hood. We'll talk more about creating data using Factories later in these guides.`}</p>
    <h2 {...{
      "id": "accessing-models"
    }}>{`Accessing models`}</h2>
    <p>{`To access your models, use the various query methods from the model's collection.`}</p>
    <p>{`For example, use `}<inlineCode parentName="p">{`all()`}</inlineCode>{` to return all known models:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/blog-posts", (schema, request) => {
  return schema.blogPosts.all()
})
`}</code></pre>
    <p>{`Here are some other common query methods:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`schema.blogPosts.find(1)
schema.blogPosts.first()
schema.blogPosts.where({ isPublished: true })
schema.blogPosts.findBy({ title: "Introduction" })
`}</code></pre>
    <p>{`Check out the Schema API docs to see all available query methods.`}</p>
    <h2 {...{
      "id": "updating-and-deleting-models"
    }}>{`Updating and deleting models`}</h2>
    <p>{`Once you're working with an instance of a model, there are other properties and methods you'll have access to.`}</p>
    <p>{`For example, you can update a model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let post = schema.blogPosts.find(1)

post.update({ author: "Obi-Wan" })
`}</code></pre>
    <p>{`or delete one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let post = schema.blogPosts.find(2)

post.destroy()
`}</code></pre>
    <p>{`View the Model API docs to see all the available fields and methods for model instances.`}</p>
    <hr></hr>
    <p>{`Once your Models have been defined, the next step is to define the relationships between them, so you can really start to leverage the power of Mirage's ORM.`}</p>
    <p>{`We'll talk about how to do that next.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      