import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "develop-a-nextjs-page-with-mirage"
    }}>{`Develop a Next.js page with Mirage`}</h1>
    <p>{`Mock out client-side API requests directly within a page of your Next.js app so you can continue local development without any backend services.`}</p>
    <p><em parentName="p">{`Note: At this time, Mirage only runs in the browser, meaning it will not mock out any server-side network calls your Next.js app makes via hooks like getServerSideProps.`}</em></p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-create-a-server-in-a-page"
    }}>{`Step 2: Create a Server in a page`}</h2>
    <p>{`Within a page file, import `}<inlineCode parentName="p">{`Server`}</inlineCode>{` from Mirage, create a server, and start mocking out API endpoints that your code needs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3-15}"
      }}>{`// pages/index.js
import { useState, useEffect } from "react"
import { createServer } from "miragejs"

createServer({
  routes() {
    this.get("/api/movies", () => ({
      movies: [
        { id: 1, name: "Inception", year: 2010 },
        { id: 2, name: "Interstellar", year: 2014 },
        { id: 3, name: "Dunkirk", year: 2017 },
      ],
    }))
  },
})

export default function Index() {
  let [movies, setMovies] = useState([])

  useEffect(() => {
    fetch("/api/movies")
      .then((res) => res.json())
      .then((json) => {
        setMovies(json.movies)
      })
  }, [])

  return (
    <ul>
      {movies.map((movie) => (
        <li key={movie.id}>
          {movie.name} ({movie.year})
        </li>
      ))}
    </ul>
  )
}
`}</code></pre>
    <p>{`You can now continue to develop your page, mocking out your backend API endpoints with Mirage as you go.`}</p>
    <p><em parentName="p">{`Note: You can only have one Mirage Server instantiated at a time, so if you want to share your mocking code across multiple pages, check out the guide on `}<a parentName="em" {...{
          "href": "/docs/workflow-tips/#sharing-your-server-between-development-and-testing"
        }}>{`centralizing and sharing your Mirage server`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      