import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-api-requests-in-react-native-testing-library-with-mirage"
    }}>{`Mock API Requests in React Native Testing Library with Mirage`}</h1>
    <p>{`Use your Mirage server to test your React Native app under different server scenarios using `}<a parentName="p" {...{
        "href": "https://github.com/testing-library/native-testing-library"
      }}>{`@testing-library/react-native`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/testing-library/jest-native"
      }}>{`Jest Native`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://docs.expo.io/versions/latest/guides/testing-with-jest/"
      }}>{`Jest Expo`}</a>{`.`}</p>
    <p><em parentName="p">{`This guide assumes your app is already set up with React Native Testing Library, Jest Native, and Expo. It should also work with both `}<a parentName="em" {...{
          "href": "https://reactjs.org/docs/test-renderer.html"
        }}>{`react-test-renderer`}</a>{` and `}<a parentName="em" {...{
          "href": "https://github.com/callstack/react-native-testing-library"
        }}>{`react-native-testing-library`}</a>{`.`}</em></p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you have Mirage installed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-setup-your-testing-environment"
    }}>{`Step 2: Setup your testing environment`}</h2>
    <p>{`We'll need to update our Jest environment to work with Mirage.`}</p>
    <p>{`First, install the polyfill for XMLHttpRequest:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev xmlhttprequest

# Using Yarn
yarn add --dev xmlhttprequest
`}</code></pre>
    <p>{`Then create a new `}<inlineCode parentName="p">{`jest.setup.js`}</inlineCode>{` file in the root of your project. We'll use this file to patch the `}<inlineCode parentName="p">{`global`}</inlineCode>{` variable and add support for XMLHttpRequest, self, and window.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// jest.setup.js
global.self = global
global.window = {}
global.XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest
`}</code></pre>
    <p>{`Finally, have Jest to load the environment that we just created using `}<inlineCode parentName="p">{`jest.config.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// jest.config.js
const jestPreset = require("@testing-library/react-native/jest-preset")

module.exports = {
  preset: "jest-expo",
  setupFiles: [...jestPreset.setupFiles],
  setupFilesAfterEnv: ["./jest.setup.js"],
}
`}</code></pre>
    <p>{`Here we're telling Jest to execute our `}<inlineCode parentName="p">{`jest.setup.js`}</inlineCode>{` after it sets up the test environment.`}</p>
    <h2 {...{
      "id": "step-3-create-a-mirage-server-within-a-test"
    }}>{`Step 3: Create a Mirage Server within a test`}</h2>
    <p>{`Within a test file, import `}<inlineCode parentName="p">{`Server`}</inlineCode>{` from Mirage, create a server, and mock out the API endpoints your code depends on:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{5-15,18-21}"
      }}>{`// App.test.js
import React from "react"
import { render, waitForElement } from "@testing-library/react-native"
import App from "../App"
import { createServer } from "miragejs"

let server

beforeEach(() => {
  server = createServer()
})

afterEach(() => {
  server.shutdown()
})

it("shows the users from our server", async () => {
  server.get("/users", () => [
    { id: 1, name: "Luke" },
    { id: 2, name: "Leia" },
  ])

  const { getByTestId } = render(<App />)
  await waitForElement(() => getByTestId("user-1"))
  await waitForElement(() => getByTestId("user-2"))

  expect(getByTestId("user-1")).toHaveTextContent("Luke")
  expect(getByTestId("user-2")).toHaveTextContent("Leia")
})
`}</code></pre>
    <p>{`When the `}<inlineCode parentName="p">{`<App />`}</inlineCode>{` component renders and makes a network request to `}<inlineCode parentName="p">{`/users`}</inlineCode>{`, Mirage will intercept and respond with the data above.`}</p>
    <p>{`Note that we've called `}<inlineCode parentName="p">{`server.shutdown()`}</inlineCode>{` after each test, so that Mirage has a chance to clean itself up and not leak into other tests.`}</p>
    <hr></hr>
    <p><em parentName="p">{`For a more complete example, check out the `}<a parentName="em" {...{
          "href": "https://github.com/miragejs/react-native-demo"
        }}>{`Mirage React Native demo`}</a>{` on GitHub.`}</em></p>
    <p><em parentName="p">{`To learn about Mirage's powerful testing features, read the `}<a parentName="em" {...{
          "href": "/docs/testing/application-tests/"
        }}>{`Application Testing guide`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      