import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-an-apps-network-requests-with-react-testing-library-and-mirage"
    }}>{`Mock an app's network requests with React Testing Library and Mirage`}</h1>
    <p>{`Once you're ready to `}<a parentName="p" {...{
        "href": "/docs/workflow-tips/#sharing-your-server-between-development-and-testing"
      }}>{`centralize your Mirage server in order to share it between development and testing`}</a>{`, follow these steps to use your shared server in your tests written with `}<a parentName="p" {...{
        "href": "https://github.com/testing-library/react-testing-library"
      }}>{`React Testing Library`}</a>{`.`}</p>
    <p>{`This guide is for people already using React Testing Library in their React apps.`}</p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-server"
    }}>{`Step 2: Define your server`}</h2>
    <p>{`A common place to define your shared server is `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    routes() {
      this.namespace = "api"

      this.get("/users", (schema) => {
        return schema.users.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`In an app build with Create React App, make sure this file is under the `}<inlineCode parentName="p">{`src/`}</inlineCode>{` directory so that changes to it trigger rebuilds.`}</p>
    </blockquote>
    <h3 {...{
      "id": "step-3-use-your-shared-server-in-your-tests"
    }}>{`Step 3: Use your shared server in your tests`}</h3>
    <p>{`Import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function and start and shutdown your server using the `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` and `}<inlineCode parentName="p">{`afterEach`}</inlineCode>{` functions. Then, use your tests to seed Mirage with data, or make other changes to put Mirage into the state you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{5-15,18-19}"
      }}>{`// src/__tests__/App.test.js
import React from "react"
import { render, waitForElement } from "@testing-library/react"
import App from "../App"
import { makeServer } from "../server"

let server

beforeEach(() => {
  server = makeServer()
})

afterEach(() => {
  server.shutdown()
})

it("shows the users from our server", async () => {
  server.create("user", { name: "Luke" })
  server.create("user", { name: "Leia" })

  const { getByTestId } = render(<App />)
  await waitForElement(() => getByTestId("user-1"))
  await waitForElement(() => getByTestId("user-2"))

  expect(getByTestId("user-1")).toHaveTextContent("Luke")
  expect(getByTestId("user-2")).toHaveTextContent("Leia")
})
`}</code></pre>
    <p>{`Each test will get its own isolated Mirage server instance, and no state or server modifications you make will leak across tests.`}</p>
    <p>{`Make sure your server gets instantiated with the `}<inlineCode parentName="p">{`test`}</inlineCode>{` environment. (In the example `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function above, we default `}<inlineCode parentName="p">{`environment`}</inlineCode>{` to `}<inlineCode parentName="p">{`test`}</inlineCode>{`.)`}</p>
    <p>{`You can now continue to write tests that have their own isolated instance of your Mirage server, allowing you to alter it exactly as needed on a per-test basis.`}</p>
    <hr></hr>
    <p><em parentName="p">{`To learn more about testing, `}<a parentName="em" {...{
          "href": "/docs/testing/application-tests/"
        }}>{`read the Application Testing guide`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      