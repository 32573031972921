import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "intro"
    }}>{`Intro`}</h1>
    <p>{`This tutorial will teach you the fundamentals of mocking out any HTTP API with Mirage.
While Mirage can be used to mock out GraphQL APIs, this tutorial will cover a basic REST API.`}</p>
    <p>{`You'll learn Mirage by `}<strong parentName="p">{`mocking out the API for a Reminders app`}</strong>{`. The app is written in React, but you don't need to know React to follow along since you'll only be writing Mirage code.`}</p>
    <p>{`There is no real API server running, so the app doesn't work to start. As you implement each API endpoint, you'll be restoring the functionality of the app.`}</p>
    <p>{`Here's an example of the final result:`}</p>
    <p><a parentName="p" {...{
        "href": "https://mirage-tutorial.vercel.app"
      }}><strong parentName="a">{`mirage-tutorial.vercel.app`}</strong></a>{` (`}<a parentName="p" {...{
        "href": "https://github.com/miragejs/tutorial"
      }}>{`source`}</a>{`)`}</p>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`First let’s get your development environment ready. You'll need `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}><strong parentName="a">{`Node.js`}</strong></a>{` installed, and you'll be using your own text editor and terminal app.`}</p>
    <p>{`Next, download the tutorial and install its dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx degit github:miragejs/tutorial mirage-tutorial
cd mirage-tutorial
yarn install

# You can also use npm:
npm install
`}</code></pre>
    <p>{`Then open the project in your editor and start your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn start

# or npm run start
`}</code></pre>
    <p>{`This should run a development server on `}<strong parentName="p">{`port 3000`}</strong>{`.`}</p>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:3000"
      }}>{`http://localhost:3000`}</a>{` in your browser and you should see a page that looks like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1adb7803c6aabfceee5b09d00547c4d6/0ffd9/intro-overview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABGklEQVQoz42S6W6DMBCEef93679KpQ1HAuE2YGwSTme6hiAIKm2RPtaGYTysbXgxx5ZLVE7VTyrs3x1pt2NjL9BGphPh7d2B6cawfAY3KOCGrywG+2rsV9A1SDm4bFA3PW7tAHnvp/lCVbeIMvF7wuWBTsNlC8ZyWLaDr5OFLGNYr8d0Z/yGMyX901CL8uqOrusgZA0hJJqmXe1mP7CyJm35H8MSaSGhlIKirzUjjfthxEDoOo5q0mwNF14Mt308B/lKuOIGM/pPzlq38DQ3to2dKQ+PiU8ECUeYCqJCSBuzxU+eCZd0R3hTCo4040At0eU5eiGmVowjtYJQD4WCev9DwoNDTFxDhti+4PJxmjEteISu/qcNz0/wDb4u7e+lxxIbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Reminders app",
            "title": "Reminders app",
            "src": "/static/1adb7803c6aabfceee5b09d00547c4d6/37523/intro-overview.png",
            "srcSet": ["/static/1adb7803c6aabfceee5b09d00547c4d6/e9ff0/intro-overview.png 180w", "/static/1adb7803c6aabfceee5b09d00547c4d6/f21e7/intro-overview.png 360w", "/static/1adb7803c6aabfceee5b09d00547c4d6/37523/intro-overview.png 720w", "/static/1adb7803c6aabfceee5b09d00547c4d6/302a4/intro-overview.png 1080w", "/static/1adb7803c6aabfceee5b09d00547c4d6/07a9c/intro-overview.png 1440w", "/static/1adb7803c6aabfceee5b09d00547c4d6/0ffd9/intro-overview.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you do, you're ready to start mocking!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      