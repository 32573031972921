import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-2--static-post-handler"
    }}>{`Part 2 – Static POST Handler`}</h1>
    <p>{`Try adding a Reminder to "Shop for groceries" by pressing "+", filling out the input field and hitting enter. You should see another error:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa04346a396391c3864d2208e5a75bfa/69d6b/part-2-failing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.88888888888889%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABeElEQVQ4y5WT2W7CMBBF+f+P6xNqhdgxhSxOvGVfuB07mIYgqtTS0ZA4nFw748UpkPAcrwIsVDhHGmx0/x32+em9hZ/wsuWa4WO5w+oQYss4Dpf0jnjCy6bSxfDDT0qcQ4FYZBCmgsxqV7nMHyQqd/N/JvTsv1PkZQOjNa5BiCCMIJXCdHRdP1+YFTWSJMVuf3RY6e12e9D3PZq2mye0e6XzCl3boihKJ7BjLLSjdUIxIyEJTV6TrACnlGkqUFX1k/RXKOcKK5RlCaUNOlpe39+XeuffCTUlVKZAxCW40NBZibYH6vaGpgMquijrzvXp7D20qaq6JRr3AeiS0sJVS0NS9qbRJ30ocIkk4lQjInwNE4WIsDXgCpdY0WqE6wqPDfNyUlxKqnbpL9CfGL2YkzAVBpKaXGZD8yvaJi6LccL3Z/OxHXSK4lgCCUfGGAxRKInSGBRaIdPZIJyeSZ92zMlWKz7HOG2O2K92A18bYov95xrHLXPP/ADMWR/geizD3AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing request",
            "title": "Failing request",
            "src": "/static/fa04346a396391c3864d2208e5a75bfa/37523/part-2-failing.png",
            "srcSet": ["/static/fa04346a396391c3864d2208e5a75bfa/e9ff0/part-2-failing.png 180w", "/static/fa04346a396391c3864d2208e5a75bfa/f21e7/part-2-failing.png 360w", "/static/fa04346a396391c3864d2208e5a75bfa/37523/part-2-failing.png 720w", "/static/fa04346a396391c3864d2208e5a75bfa/302a4/part-2-failing.png 1080w", "/static/fa04346a396391c3864d2208e5a75bfa/07a9c/part-2-failing.png 1440w", "/static/fa04346a396391c3864d2208e5a75bfa/69d6b/part-2-failing.png 1912w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you check your console you'll see this message:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Mirage: Your app tried to POST '/api/reminders', but there was no route defined to handle this request.`}</p>
    </blockquote>
    <p>{`Let's mock out this POST request. Start by copying the highlighted lines into your server's `}<inlineCode parentName="p">{`routes()`}</inlineCode>{` hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{11-15}"
      }}>{`createServer({
  routes() {
    this.get("/api/reminders", () => ({
      reminders: [
        { id: 1, text: "Walk the dog" },
        { id: 2, text: "Take out the trash" },
        { id: 3, text: "Work out" },
      ],
    }))

    this.post("/api/reminders", (schema, request) => {
      let attrs = JSON.parse(request.requestBody)
      console.log(attrs)
      debugger
    })
  },
})
`}</code></pre>
    <p>{`We're using `}<inlineCode parentName="p">{`this.post`}</inlineCode>{` to handle this request. We're also using the `}<inlineCode parentName="p">{`request`}</inlineCode>{` argument to access the data sent over from our application. The `}<inlineCode parentName="p">{`request.requestBody`}</inlineCode>{` property contains the request body as a JSON string, so after parsing it we log it to the console.`}</p>
    <p>{`After saving this new code, make sure your JavaScript console is open and try adding a Reminder to "Shop for groceries" again. The DevTools should hit the `}<inlineCode parentName="p">{`debugger`}</inlineCode>{` statement in your route handler, and you should see the object `}<inlineCode parentName="p">{`{ text: "Shop for groceries" }`}</inlineCode>{` logged to your console.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1a9a0b6348e58b324bf71529a65b0ee3/0ffd9/part-2-debugger.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.111111111111114%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABpElEQVQoz4WS6U6DQBSF+/7/TexG21SgDktpjD6LMUZjLUsHytZW2lo43gFpwESFnJyBYT7Onbmd/kjGYKxA+PVgip50A2Mxw90Dw+JepzkZ9TdibnzD8PryCu75cOw1VisPtu0hSRLEcYxOE3jVHZXeH6noDhWSjCHNDSfKBThRNPjrALEdga98HLd7nD4y5EUBcXVqmFjENB2yrJQyDAOWZWE+n9PYhDRRK6DMEEYRjtkHpfSQeS4+4w2Qn38CVcxmtxgMJUjSCLqul0DLIqDZBvIgRBId4bsRIr5BuNnDD7bYJXEDSK7rBhhjrXTCTXNeArsEnFLJS9o728ngOFvE6QnpPke6O2O3//xRMtPKcoULWC2zlVBDRCX/drWAulEl1Agoki0WleqEl1N+e0cQbCilA8f1sF5zhGFUnvQFKFxWb6GQhKszdpF4J35Y7+Hj0zNcxwV3XSyX7+B+AI8OiHPebpumekLStxp9KIA+pctPJ5yzDGfygu6MxofDoQLW0P9UA5N0W+5X8dcelouE/6YGME7SCpjnKKihi++mzun5C4cryQIzrBQKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing request",
            "title": "Failing request",
            "src": "/static/1a9a0b6348e58b324bf71529a65b0ee3/37523/part-2-debugger.png",
            "srcSet": ["/static/1a9a0b6348e58b324bf71529a65b0ee3/e9ff0/part-2-debugger.png 180w", "/static/1a9a0b6348e58b324bf71529a65b0ee3/f21e7/part-2-debugger.png 360w", "/static/1a9a0b6348e58b324bf71529a65b0ee3/37523/part-2-debugger.png 720w", "/static/1a9a0b6348e58b324bf71529a65b0ee3/302a4/part-2-debugger.png 1080w", "/static/1a9a0b6348e58b324bf71529a65b0ee3/07a9c/part-2-debugger.png 1440w", "/static/1a9a0b6348e58b324bf71529a65b0ee3/0ffd9/part-2-debugger.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Being able to use the DevTools to help you write and debug route handlers right alongside your frontend code is a key part of what makes Mirage so productive.`}</p>
    <p>{`Press the Play button to allow JavaScript to continue execution. Mirage will respond successfully with a 201 status code, but the app will crash with an error:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Cannot read property 'id' of undefined`}</p>
    </blockquote>
    <p>{`If you scroll up your console to the first error, here's a screenshot of what you should see:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/590eed6d199018cdd86ec87af443ce84/0ffd9/part-2-first-error.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABR0lEQVQ4y52T2W7CMBRE+f8Pa5FaiUUsJUhQQmLHTmI7McFZmd6A1D5VAlsaXfnlaOaOPQkTg+ekceKPGTMBFpHiBJxLqDRHoRSMMZg8DzQE1DiLArasIEIBJQz6yqFvatzwOK8Dabq6weViIYXElTO0KsOt7/yBV1ejtg4m1bBZjlJXUKpE17Y+kQ2K0uJKsa2xaOoWfT+g63oMw+AHHCP/dyY+LStNLqnRNE1JGaRMkWXZqy3r3x2GZ4YoYvRkOI7fJ7pHd+ColyNH9Gy4zBDHDDIR4EmCpuvIpbzreSD/c3ghR3q5gNnt0MQxUBTQjMEK4RGZSmnImXt/QzmdothukBBY7vdofIBjZEMO2/kMHcGq1Qr51xbhZoMyinwcFvQ7EgyfH3foeblEMJvhSNPRPr1adgR0o6PFHPl6DR4ESA8HVNT6D80wFi+1C9oDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "First error",
            "title": "First error",
            "src": "/static/590eed6d199018cdd86ec87af443ce84/37523/part-2-first-error.png",
            "srcSet": ["/static/590eed6d199018cdd86ec87af443ce84/e9ff0/part-2-first-error.png 180w", "/static/590eed6d199018cdd86ec87af443ce84/f21e7/part-2-first-error.png 360w", "/static/590eed6d199018cdd86ec87af443ce84/37523/part-2-first-error.png 720w", "/static/590eed6d199018cdd86ec87af443ce84/302a4/part-2-first-error.png 1080w", "/static/590eed6d199018cdd86ec87af443ce84/07a9c/part-2-first-error.png 1440w", "/static/590eed6d199018cdd86ec87af443ce84/0ffd9/part-2-first-error.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our app is expecting the API server to respond with an object that looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "reminder": {
    "id": 4,
    "text": "Shop for groceries"
  }
}
`}</code></pre>
    <p>{`but right now we're not returning anything from our route handler.`}</p>
    <p>{`Let's return an object that looks like this. We'll use the `}<inlineCode parentName="p">{`attrs`}</inlineCode>{` from our request, and also add in an `}<inlineCode parentName="p">{`id`}</inlineCode>{` key. We'll use the number 4 as the ID since our existing reminders use IDs 1 through 3.`}</p>
    <p>{`Here's our new route handler – go ahead and copy this into your server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.post("/api/reminders", (schema, request) => {
  let attrs = JSON.parse(request.requestBody)
  attrs.id = 4

  return { reminder: attrs }
})
`}</code></pre>
    <p>{`Now add a Reminder to "Shop for groceries" – there's no more errors, and the app updates correctly!`}</p>
    <p>{`...unless you try to create a second todo! Try adding a second Reminder to "Clean the bathroom". React will throw an error:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Encountered two children with the same key, '4'.`}</p>
    </blockquote>
    <p>{`Instead of hard-coding the ID as 4, let's use an incrementing number:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{14-20}"
      }}>{`import { createServer } from "miragejs"

export default function () {
  return createServer({
    routes() {
      this.get("/api/reminders", () => ({
        reminders: [
          { id: 1, text: "Walk the dog" },
          { id: 2, text: "Take out the trash" },
          { id: 3, text: "Work out" },
        ],
      }))

      let newId = 4
      this.post("/api/reminders", (schema, request) => {
        let attrs = JSON.parse(request.requestBody)
        attrs.id = newId++

        return { reminder: attrs }
      })
    },
  })
}
`}</code></pre>
    <p>{`Now our app works no matter how many reminders we create. If you inspect the Mirage response, you should see the new incrementing IDs being returned from our route handler.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2eaed1980378ac373bf109c3cc57fbc9/0ffd9/part-2-working.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.111111111111114%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAABQElEQVQoz42S63KDIBCFff8n7I82pjGilQbwEi8gnu6SYE3atNmZMyvr4duFIXkvNA6lAee3TCLNT9gLHdY/ZcirIYoSZVmiqiSyo0AlT5BS0rpCEoF7ofCyE7RJQcgWx6pG9mFIlxybhnpe4HWXEizHITvCLwvlDEUhkKxG2jiME7SpobTBZG0wer/AujmA2Mfwpu1gFOWmw9QPWMirtUZL9WTbuevO1ClHThNYMl1iwTz78H9/baxNg6qU6NozvHNYSFopatB+A7nzZB36vocjQ0DRhBwRGH3DaKk2Yxu8h2u3Rx5GmLrGOI4B9gjo3Lw23Po4NhNe7tDUTRjde7+a3R3Q/gKMugHy2HxkFt9dDDbmD4D3eQVylrrFyXRBn/QdJVWLbHPXfwK3Dzult5jmV4lbxcfN4Kcn/E/PAL8AkklJNREA3NoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "First error",
            "title": "First error",
            "src": "/static/2eaed1980378ac373bf109c3cc57fbc9/37523/part-2-working.png",
            "srcSet": ["/static/2eaed1980378ac373bf109c3cc57fbc9/e9ff0/part-2-working.png 180w", "/static/2eaed1980378ac373bf109c3cc57fbc9/f21e7/part-2-working.png 360w", "/static/2eaed1980378ac373bf109c3cc57fbc9/37523/part-2-working.png 720w", "/static/2eaed1980378ac373bf109c3cc57fbc9/302a4/part-2-working.png 1080w", "/static/2eaed1980378ac373bf109c3cc57fbc9/07a9c/part-2-working.png 1440w", "/static/2eaed1980378ac373bf109c3cc57fbc9/0ffd9/part-2-working.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Mirage can handle POST requests`}</li>
      <li parentName="ul">{`Route handlers can use data from the request when constructing the response`}</li>
      <li parentName="ul">{`Route handlers should faithfully recreate important details of production server endpoints, like unique IDs`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      