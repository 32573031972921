import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "develop-a-react-app-against-a-shared-mirage-server"
    }}>{`Develop a React App against a Shared Mirage Server`}</h1>
    <p>{`Once you're ready to `}<a parentName="p" {...{
        "href": "/docs/workflow-tips/#sharing-your-server-between-development-and-testing"
      }}>{`centralize your Mirage server and share it between development and testing`}</a>{`, follow these steps.`}</p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-define-your-shared-server"
    }}>{`Step 2: Define your shared server`}</h2>
    <p>{`A common place to define your shared server is `}<inlineCode parentName="p">{`src/server.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/server.js
import { createServer, Model } from "miragejs"

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    routes() {
      this.namespace = "api"

      this.get("/users", (schema) => {
        return schema.users.all()
      })
    },
  })

  return server
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`In an app build with Create React App, make sure this file is under the `}<inlineCode parentName="p">{`src/`}</inlineCode>{` directory so that changes to it trigger rebuilds.`}</p>
    </blockquote>
    <p>{`The `}<inlineCode parentName="p">{`environment`}</inlineCode>{` argument we're adding to the function signature is just a convention. We're defaulting it to `}<inlineCode parentName="p">{`test`}</inlineCode>{` since in most apps, you'll call `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` once in development mode but many times in `}<inlineCode parentName="p">{`test`}</inlineCode>{` (across your various test files).`}</p>
    <p>{`You are free to change the signature to whatever you like.`}</p>
    <h2 {...{
      "id": "step-3-start-your-server-in-development"
    }}>{`Step 3: Start your server in development`}</h2>
    <p>{`Open your React app's bootstrap file (`}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{` in a Create React App), import your `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` function, and call it in the development environment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{5-9}"
      }}>{`// src/index.js
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { makeServer } from "./server"

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" })
}

ReactDOM.render(<App />, document.getElementById("root"))
`}</code></pre>
    <p>{`We're using the `}<inlineCode parentName="p">{`process.env.NODE_ENV`}</inlineCode>{` environment variable here, which is a common global variable available in many React app environments. The conditional allows Mirage to be tree-shaken in production, so it won't affect your production bundle.`}</p>
    <p>{`Also, note that we're passing in `}<inlineCode parentName="p">{`{ environment: "development" }`}</inlineCode>{` here, so that Mirage's `}<inlineCode parentName="p">{`seeds()`}</inlineCode>{` load and there is some simulated latency to help you during development.`}</p>
    <p>{`And that's it! With this code in place, whenever any component in your application makes a network request in development, Mirage will intercept that request and respond with the data from your server definition.`}</p>
    <p>{`You now have a central place to define and update your Mirage server as you continue local development on your React app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      