import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mock-a-components-network-requests-with-react-testing-library-and-mirage"
    }}>{`Mock a component's network requests with React Testing Library and Mirage`}</h1>
    <p>{`Use Mirage to mock out individual API endpoints directly inside your tests written with `}<a parentName="p" {...{
        "href": "https://github.com/testing-library/react-testing-library"
      }}>{`React Testing Library`}</a>{`.`}</p>
    <p>{`This guide is for people already using React Testing Library in their React apps.`}</p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-create-a-server-within-a-test"
    }}>{`Step 2: Create a Server within a test`}</h2>
    <p>{`Within a test file, import `}<inlineCode parentName="p">{`Server`}</inlineCode>{` from Mirage, create a server, and mock out the API endpoints that your code needs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{5-15,18-21}"
      }}>{`// src/__tests__/App.test.js
import React from "react"
import { render, waitForElement } from "@testing-library/react"
import App from "../App"
import { createServer } from "miragejs"

let server

beforeEach(() => {
  server = createServer()
//  If your API is at a different port or host than your app, you'll need something like:
//  server = createServer({
//    environment: "test",
//    urlPrefix: "http://api.acme.com:3000",
//  })
})

afterEach(() => {
  server.shutdown()
})

it("shows the users from our server", async () => {
  server.get("/users", () => [
    { id: 1, name: "Luke" },
    { id: 2, name: "Leia" },
  ])

  const { getByTestId } = render(<App />)
  await waitForElement(() => getByTestId("user-1"))
  await waitForElement(() => getByTestId("user-2"))

  expect(getByTestId("user-1")).toHaveTextContent("Luke")
  expect(getByTestId("user-2")).toHaveTextContent("Leia")
})
`}</code></pre>
    <p>{`When the `}<inlineCode parentName="p">{`<App />`}</inlineCode>{` component is rendered and React makes a network request to `}<inlineCode parentName="p">{`/users`}</inlineCode>{`, Mirage will intercept and respond with the data above.`}</p>
    <p>{`Note that we've called `}<inlineCode parentName="p">{`server.shutdown()`}</inlineCode>{` after each test, so that Mirage has a chance to clean itself up and not leak into other tests.`}</p>
    <hr></hr>
    <p><em parentName="p">{`To learn more about testing, `}<a parentName="em" {...{
          "href": "/docs/testing/application-tests/"
        }}>{`read the Application Testing guide`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      