import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "exclude-mirage-from-vue-cli-production-builds"
    }}>{`Exclude Mirage from Vue CLI production builds`}</h1>
    <p>{`Since Mirage is a development tool, you should configure Vue CLI to exclude it when building your app for production. This keeps the `}<inlineCode parentName="p">{`miragejs`}</inlineCode>{` library out of your app and ensures users don't download any unnecessary code.`}</p>
    <p>{`You can use your `}<inlineCode parentName="p">{`vue.config.js`}</inlineCode>{` file to achieve this.`}</p>
    <h2 {...{
      "id": "step-1-install-null-loader"
    }}>{`Step 1: Install null-loader`}</h2>
    <p>{`Add the `}<a parentName="p" {...{
        "href": "https://github.com/webpack-contrib/null-loader"
      }}>{`null-loader`}</a>{` library to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev null-loader

# Using yarn
yarn add -D null-loader
`}</code></pre>
    <h2 {...{
      "id": "step-2-replace-mirage-with-an-empty-package"
    }}>{`Step 2: Replace Mirage with an empty package`}</h2>
    <p>{`Next, add the following to your `}<inlineCode parentName="p">{`vue.config.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// vue.config.js
module.exports = {
  chainWebpack: (config) => {
    if (
      process.env.NODE_ENV === "production" &&
      process.env.MIRAGE_ENABLED !== "true"
    ) {
      config.module
        .rule("exclude-mirage")
        .test(/node_modules\\/miragejs\\//)
        .use("null-loader")
        .loader("null-loader")
    }
  },
}
`}</code></pre>
    <p>{`This replaces the `}<inlineCode parentName="p">{`miragejs`}</inlineCode>{` module in your app with an empty module when you're building for production.`}</p>
    <h2 {...{
      "id": "step-3-ensure-there-are-no-production-runtime-calls-to-server"
    }}>{`Step 3: Ensure there are no production runtime calls to Server`}</h2>
    <p>{`Since we've replaced `}<inlineCode parentName="p">{`miragejs`}</inlineCode>{` with an empty module, we need to ensure that we're not invoking `}<inlineCode parentName="p">{`makeServer`}</inlineCode>{` (or using any of Mirage's APIs) in our production runtime code, because those APIs no longer exist.`}</p>
    <p>{`We can do that by ensuring any code that starts Mirage is wrapped in a `}<inlineCode parentName="p">{`process.env.NODE_ENV`}</inlineCode>{` check:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{8-10}"
      }}>{`// src/main.js
import Vue from "vue"
import App from "./App.vue"
import { makeServer } from "./server"

Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
  makeServer()
}

new Vue({
  render: (h) => h(App),
}).$mount("#app")
`}</code></pre>
    <p>{`With this configuration Mirage will neither be included nor accessed in your production environment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      