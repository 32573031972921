import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "integration-and-unit-tests"
    }}>{`Integration and unit tests`}</h1>
    <p>{`While Mirage was originally designed for acceptance testing, it also works great when writing integration and unit tests.`}</p>
    <h2 {...{
      "id": "reusing-your-global-server"
    }}>{`Reusing your global server`}</h2>
    <p>{`Let's say you have a data-fetching component, and you want to write a test to verify its behavior.`}</p>
    <p>{`You could use the `}<inlineCode parentName="p">{`startMirage`}</inlineCode>{` convention covered in the previous guide and test your component against your global server definition, just like you would in an application test:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// UserTable.test.js
import UserTable from "./UserTable"
import { startMirage } from "./mirage"

let server

beforeEach(() => {
  server = startMirage()
})

afterEach(() => {
  server.shutdown()
})

it("fetches the list of countries", async () => {
  server.createList("user", 5)

  const { getByTestId } = render(<UserTable />)

  await waitForElement(() => getByTestId("users"))

  expect(getByTestId("user-row")).toHaveLength(5)
})
`}</code></pre>
    <h2 {...{
      "id": "creating-one-off-servers"
    }}>{`Creating one-off servers`}</h2>
    <p>{`You could also create new one-off Mirage servers for the sole purpose of testing your component. This works well for component libraries or reusable data-fetching components, or even for testing data-fetching hooks:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// useQuery.test.js
import { createServer, Model } from "miragejs"
import React from "react"
import { useQuery } from "./useQuery"

function Movies() {
  const { data } = useQuery("get", "/api/movies")

  return (
    <>
      <h1>Movies</h1>
      {data.movies.map((movie) => (
        <p key={movie.id} data-testid="movie">
          {movie.title}
        </p>
      ))}
    </>
  )
}

let server

beforeEach(() => {
  server = createServer({
    environment: "test",
    models: {
      movie: Model,
    },
    routes() {
      this.namespace = "api"

      this.resource("movie")
    },
  })
})

afterEach(() => {
  server.shutdown()
})

it("can fetch data", async () => {
  server.createList("movie", 5)

  const { getByTestId } = render(<Movies />)

  await findByText("Movies")

  expect(getAllByTestId("movie")).toHaveLength(5)
})
`}</code></pre>
    <hr></hr>
    <p>{`Now you know how to test anything that depends on the network, from a single component to your entire application!`}</p>
    <p>{`Next we'll discuss some common ways to assert against your Mirage mock server.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      