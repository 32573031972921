import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "installation"
    }}>{`Installation`}</h1>
    <p>{`To add Mirage to your project, run`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <p>{`If you just want to start coding, head to the `}<a parentName="p" {...{
        "href": "/quickstarts/overview"
      }}>{`Quickstarts`}</a>{` section and find the guide that matches your stack.`}</p>
    <p>{`To learn more about Mirage, read the overview next!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      