import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "mocking-guids"
    }}>{`Mocking GUIDs`}</h1>
    <p>{`Some applications use GUIDs (or UUIDs) instead of auto-incrementing integers as identifiers for their models.`}</p>
    <p>{`Mirage supports the ability to overwrite how its database assigns IDs to new records via the `}<inlineCode parentName="p">{`IdentityManager`}</inlineCode>{` class. You can generate model-specific managers or an application-wide manager to customize how your database behaves.`}</p>
    <p>{`A custom identity manager must implement these methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fetch`}</inlineCode>{`, which must return an identifier not used yet.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`set`}</inlineCode>{`, which is called with an `}<inlineCode parentName="li">{`id`}</inlineCode>{` of a record being insert in mirage's database.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`reset`}</inlineCode>{`, which should reset database to initial state.`}</li>
    </ul>
    <p>{`Here's an example implementation for an identity manager that mocks GUIDs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import uuidv4 from "uuid/v4"

export default class {
  constructor() {
    this.ids = new Set()
  }

  // Returns a new unused unique identifier.
  fetch() {
    let uuid = uuidv4()
    while (this.ids.has(uuid)) {
      uuid = uuidv4()
    }

    this.ids.add(uuid)

    return uuid
  }

  // Registers an identifier as used. Must throw if identifier is already used.
  set(id) {
    if (this.ids.has(id)) {
      throw new Error(\`ID \${id} has already been used.\`)
    }

    this.ids.add(id)
  }

  // Resets all used identifiers to unused.
  reset() {
    this.ids.clear()
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      