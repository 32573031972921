import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-3--dynamic-handlers"
    }}>{`Part 3 – Dynamic Handlers`}</h1>
    <p>{`We've mocked out two API routes so far, but we have a problem.`}</p>
    <p>{`Try saving a new Reminder to "Buy groceries", and then clicking the About link in the header, and then clicking Reminders to return home again.`}</p>
    <p>{`The original three Reminders will reload, but the new Reminder you created isn't in the list.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px",
          "margin": "50px 0",
          "border": "8px solid #F7FAFC"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0624d9e63b06472d47bc620ab97992f9/0ffd9/part-3-failing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.222222222222214%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABTUlEQVQoz32SC3OCMBCE/f8/sa2j8hBCyAOQSHht72KwjG29mZ1TWL7bHBwuhUFSWpyvGsdUhs7XXpVwJ192FRCiRFVVQUleQBUkuiYqicMG/EoqfJwKpKLBVXbUbVSDlO4/fA3KSuPzeESa5ThfEoJKTH5ElmWQkoBbuto69M5Ba4um7bAsK+ZlwbKu6PohJrVQpkVZChjyaWPhpxlcWZ7Ts/oBPBFQNQ6GDJckDdH31d99APJgho/0XxYKtZAY6xpj2wI0mCsmNJCmh/c+pORaybBE081tQIOmcxjcHbZuYZTG1NFppgnzPNOplv2ReziCNTTNj+MTuk/I0vYW9tbRWhiyRp9SCh3BnwkrfcMw+ADj/U1knuLUbYfs499hWFwHw/Yi4GPZ3DN+q9RflZTbp7UDRsC+AnCDcf/57uwv/Qd8m5Af+lvvE+77Nz+P+tHrACjhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Failing",
            "title": "Failing",
            "src": "/static/0624d9e63b06472d47bc620ab97992f9/37523/part-3-failing.png",
            "srcSet": ["/static/0624d9e63b06472d47bc620ab97992f9/e9ff0/part-3-failing.png 180w", "/static/0624d9e63b06472d47bc620ab97992f9/f21e7/part-3-failing.png 360w", "/static/0624d9e63b06472d47bc620ab97992f9/37523/part-3-failing.png 720w", "/static/0624d9e63b06472d47bc620ab97992f9/302a4/part-3-failing.png 1080w", "/static/0624d9e63b06472d47bc620ab97992f9/07a9c/part-3-failing.png 1440w", "/static/0624d9e63b06472d47bc620ab97992f9/0ffd9/part-3-failing.png 2558w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`That's because our GET handler is currently `}<em parentName="p">{`static`}</em>{`, and returns the same three hard-coded Reminders every time it runs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`this.get("/api/reminders", () => ({
  reminders: [
    { id: 1, text: "Walk the dog" },
    { id: 2, text: "Take out the trash" },
    { id: 3, text: "Work out" },
  ],
}))
`}</code></pre>
    <p>{`We've broken the `}<em parentName="p">{`referential integrity`}</em>{` of our application data, and it no longer behaves the same way our production API will.`}</p>
    <p>{`Let's fix this by using Mirage's data layer.`}</p>
    <p>{`We'll start by defining a Reminder model, which will tell Mirage to create a `}<inlineCode parentName="p">{`reminders`}</inlineCode>{` collection for us in its in-memory database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{1,5-7}"
      }}>{`import { createServer, Model } from "miragejs"

export default function () {
  createServer({
    models: {
      reminder: Model,
    },

    routes() {
      this.get("/api/reminders", () => ({
        reminders: [
          { id: 1, text: "Walk the dog" },
          { id: 2, text: "Take out the trash" },
          { id: 3, text: "Work out" },
        ],
      }))

      let newId = 4
      this.post("/api/reminders", (schema, request) => {
        let attrs = JSON.parse(request.requestBody)
        attrs.id = newId++

        return { reminder: attrs }
      })
    },
  })
}
`}</code></pre>
    <p>{`Now we can update our GET route handler to return all the Reminders in the database at the time of the request – just like a real server does.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{10-12}"
      }}>{`import { createServer, Model } from "miragejs"

export default function () {
  createServer({
    models: {
      reminder: Model,
    },

    routes() {
      this.get("/api/reminders", (schema) => {
        return schema.reminders.all()
      })

      let newId = 4
      this.post("/api/reminders", (schema, request) => {
        let attrs = JSON.parse(request.requestBody)
        attrs.id = newId++

        return { reminder: attrs }
      })
    },
  })
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`schema`}</inlineCode>{` argument, which is the first argument passed into all route handlers, is the primary way you interact with Mirage's data layer.`}</p>
    <p>{`Once you save and reload the app, you should see the "All done!" message again. That's because Mirage's database starts out empty.`}</p>
    <p>{`Next, let's update our POST handler to create new Reminder models in Mirage's data layer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{14-18}"
      }}>{`import { createServer, Model } from "miragejs"

export default function () {
  createServer({
    models: {
      reminder: Model,
    },

    routes() {
      this.get("/api/reminders", (schema) => {
        return schema.reminders.all()
      })

      this.post("/api/reminders", (schema, request) => {
        let attrs = JSON.parse(request.requestBody)

        return schema.reminders.create(attrs)
      })
    },
  })
}
`}</code></pre>
    <p>{`Here we're getting the `}<inlineCode parentName="p">{`attrs`}</inlineCode>{` from the request just like before, but now we're using the `}<inlineCode parentName="p">{`create`}</inlineCode>{` method from our `}<inlineCode parentName="p">{`schema`}</inlineCode>{` to create new Reminder models.`}</p>
    <p>{`Now if we save our changes and try to create some Reminders, we'll see our POST API route responds successfully. If you inspect the response in the console, you'll even see that Mirage's data layer has automatically assigned auto-incrementing IDs to each new Reminder for us.`}</p>
    <p>{`After you create some reminders, click About then click Reminders again. You should see all the reminders you've created re-appear! We've fixed the bug.`}</p>
    <p>{`Because all of our route handlers are reading from and writing to Mirage's data layer, we've restored the referential integrity of our API, and our app behaves just like it would in a production-like situation.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Mirage has a database that acts as a single source of truth for your mock server data`}</li>
      <li parentName="ul">{`Route handlers should read from and modify the database via the `}<inlineCode parentName="li">{`schema`}</inlineCode>{` argument in order to preserve the referential integrity of your mock data across route handlers`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      