import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "develop-a-react-component-with-mirage"
    }}>{`Develop a React Component with Mirage`}</h1>
    <p>{`Mock out API endpoints directly inside your React component so you can continue local development without any backend services.`}</p>
    <h2 {...{
      "id": "step-1-install-mirage"
    }}>{`Step 1: Install Mirage`}</h2>
    <p>{`First, make sure you've added Mirage to your project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Using npm
npm install --save-dev miragejs

# Using Yarn
yarn add --dev miragejs
`}</code></pre>
    <h2 {...{
      "id": "step-2-create-a-server-alongside-your-networking-code"
    }}>{`Step 2: Create a Server alongside your networking code`}</h2>
    <p>{`Within a component file, import `}<inlineCode parentName="p">{`Server`}</inlineCode>{` from Mirage, create a server, and start mocking out API endpoints that your code needs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{3-6}"
      }}>{`// src/App.js
import React, { useState, useEffect } from "react"
import { createServer } from "miragejs"

let server = createServer()
server.get("/api/users", { users: [{ id: 1, name: "Bob" }] })

export default function App() {
  let [users, setUsers] = useState([])

  useEffect(() => {
    fetch("/api/users")
      .then((res) => res.json())
      .then((json) => {
        setUsers(json.users)
      })
  }, [])

  return (
    <ul>
      {users.map((user) => (
        <li key={user.id}>{user.name}</li>
      ))}
    </ul>
  )
}
`}</code></pre>
    <p>{`You can now continue to develop your React component, mocking out your backend API endpoints with Mirage as you go.`}</p>
    <p>{`Read the `}<a parentName="p" {...{
        "href": "/docs/main-concepts/route-handlers/"
      }}>{`route handlers guide`}</a>{` to learn more about writing route handlers.`}</p>
    <hr></hr>
    <p><em parentName="p">{`Ready to share your mocking code across multiple components? Check out the guide on `}<a parentName="em" {...{
          "href": "/docs/workflow-tips/#sharing-your-server-between-development-and-testing"
        }}>{`Sharing your server between development and testing`}</a>{`.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      