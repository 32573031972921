import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "part-4--seeds"
    }}>{`Part 4 – Seeds`}</h1>
    <p>{`We've fixed our GET and POST route handlers, but our app starts out empty. We can use the `}<inlineCode parentName="p">{`seeds`}</inlineCode>{` hook to seed Mirage with some initial data.`}</p>
    <p>{`Copy these lines into your server definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js{9-13}"
      }}>{`import { createServer, Model } from "miragejs"

export default function () {
  createServer({
    models: {
      reminder: Model,
    },

    seeds(server) {
      server.create("reminder", { text: "Walk the dog" })
      server.create("reminder", { text: "Take out the trash" })
      server.create("reminder", { text: "Work out" })
    },

    routes() {
      this.get("/api/reminders", (schema) => {
        return schema.reminders.all()
      })

      this.post("/api/reminders", (schema, request) => {
        let attrs = JSON.parse(request.requestBody)

        return schema.reminders.create(attrs)
      })
    },
  })
}
`}</code></pre>
    <p>{`Once you save, you should see the app reload with these three Reminders as its initial data.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`server.create`}</inlineCode>{` function lets us create new reminders in Mirage's data layer. IDs are automatically assigned, just like when we call `}<inlineCode parentName="p">{`reminders.create()`}</inlineCode>{` in route handlers.`}</p>
    <p>{`Try using the app to create a fourth reminder to "Buy groceries". You should see the new Reminder get an ID of 4 if you inspect the response. And, if you navigate to the About page and back, you'll see all four Reminders show up just as you'd expect.`}</p>
    <p>{`Mirage's seeds are loaded on initialization and work right alongside any data that's created or modified from route handlers as you interact with your app in the browser, so all your API data remains consistent.`}</p>
    <h2 {...{
      "id": "takeaways"
    }}>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Use the `}<inlineCode parentName="li">{`seeds`}</inlineCode>{` hook and `}<inlineCode parentName="li">{`server.create`}</inlineCode>{` to create initial data for your server`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      