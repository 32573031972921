import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "customizing-inflections"
    }}>{`Customizing inflections`}</h1>
    <p>{`Mirage relies on an inflector for some of its conventions. An inflector is an object responsible for singularizing and pluralizing words.`}</p>
    <p>{`For example, suppose you define a `}<inlineCode parentName="p">{`User`}</inlineCode>{` model along with a GET shorthand:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  models: {
    user: Model,
  },

  routes() {
    this.get("/users/:id")
  },
})
`}</code></pre>
    <p>{`Mirage uses its inflector to know that "users" is the plural of "user". So, it can automatically take your `}<inlineCode parentName="p">{`user`}</inlineCode>{` model definition and create the `}<inlineCode parentName="p">{`schema.users.all()`}</inlineCode>{` collection, as well as look at your `}<inlineCode parentName="p">{`/users/:id`}</inlineCode>{` shorthand and know it needs to return a single `}<inlineCode parentName="p">{`user`}</inlineCode>{` model instance.`}</p>
    <p>{`Having consistent naming conventions allows Mirage to drastically reduce the boilerplate needed to wire up your mock server, but sometimes you'll find yourself needing to customize the inflection rules used in your domain.`}</p>
    <p>{`For example, suppose you had a `}<inlineCode parentName="p">{`HeadOfState`}</inlineCode>{` model in your application, and everywhere else in your system you treat the plural form of "head-of-state" as "heads-of-state".`}</p>
    <p>{`If you just set up your server like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  models: {
    headOfState: Model,
  },

  routes() {
    this.get("/heads-of-state/:id")
  },
})
`}</code></pre>
    <p>{`then Mirage wouldn't work as expected. That's because Mirage's inflector doesn't know about this special case. By default, it pluralizes "head-of-state" to "head-of-states".`}</p>
    <p>{`You can see this by calling the `}<inlineCode parentName="p">{`server.inflector.pluralize`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`let server = createServer({
  models: {
    headOfState: Model,
  },
})

server.inflector.pluralize("head-of-state") // head-of-states
`}</code></pre>
    <p>{`To fix this, you need to add your own custom inflection rules. Mirage uses `}<a parentName="p" {...{
        "href": "https://github.com/martinandert/inflected"
      }}>{`the `}<inlineCode parentName="a">{`inflected`}</inlineCode>{` package`}</a>{` for its inflections, which you can customize like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { inflections } from "inflected"
import { createServer, Model } from "miragejs"

inflections("en", function (inflect) {
  inflect.irregular("head-of-state", "heads-of-state")
})

createServer({
  headOfState: Model,

  routes() {
    this.get("/heads-of-state/:id")
  },
})
`}</code></pre>
    <p>{`Make sure you run your inflections customization code before you `}<inlineCode parentName="p">{`new`}</inlineCode>{` up your Mirage server.`}</p>
    <p>{`Verify your pluralization rules took effect:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`server.inflector.pluralize("head-of-state") // heads-of-state
`}</code></pre>
    <p>{`and now, all of Mirage's shorthands and ORM conventions should work as you expect.`}</p>
    <p>{`The Inflected package lets you customize plural, singular, irregular and uncountable words:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`inflections('en', function(inflect) {
  inflect.plural(/^(ox)$/i, '$1$2en');
  inflect.singular /^(ox)en/i, '$1');

  inflect.irregular('octopus', 'octopi');

  inflect.uncountable('equipment', 'snow');
});
`}</code></pre>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://github.com/martinandert/inflected#inflectorinflections"
      }}>{`the docs on customizing inflections`}</a>{` if you need more information.`}</p>
    <p>{`You can also use a different library entirely or use your own implementation of an inflector by passing a `}<inlineCode parentName="p">{`pluralize`}</inlineCode>{` and `}<inlineCode parentName="p">{`singularize`}</inlineCode>{` method into your Mirage server as an `}<inlineCode parentName="p">{`inflector`}</inlineCode>{` config option:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`createServer({
  inflector: {
    pluralize,
    singularize,
  },
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      